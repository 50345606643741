import DoNotDisturbOnIcon from "@mui/icons-material/DoNotDisturbOn";
import HistoryIcon from "@mui/icons-material/History";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import UnpublishedIcon from "@mui/icons-material/Unpublished";
import {
  Box,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme
} from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Dialog, { DialogButtons } from "../../../components/dialog";
import Header from "../../../components/header";
import Status from "../../../components/status";
import Table from "../../../components/table";
import UrlPaths from "../../../constants/UrlPaths";
import { get, post } from "../../../services/HttpClient";
import { branchInfo } from "../../../signals";
import { tokens } from "../../../theme";
import { DEFAULT_MONTH_FORMAT, formatDate, getMonthOptions } from "../../../utils/TimeUtil";
import MakeupClassDialog from "./MakeupClassDialog";

const DEFAULT_SEARCH_CRITERIA = {
  monthYear: moment().month() + "|" + moment().isoWeekYear(),
  from: moment().startOf("month"),
  to: moment().endOf("month"),
  historyStatus: ""
};

const MakeupClass = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();

  const [selectedMadeUpRow, setSelectedMadeUpRow] = useState(null);
  const [makeUpUncompletedRow, setMakeUpUncompletedRow] = useState(null);
  const [makeUpUncompletedNote, setMakeUpUncompletedNote] = useState("");
  const [revertRow, setRevertRow] = useState(null);
  const [notes, setNotes] = useState([]);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [searchCriteria, setSearchCriteria] = useState(DEFAULT_SEARCH_CRITERIA);
  const [pagination, setPagination] = useState({
    pageNumber: 0,
    pageSize: 30,
    order: "desc",
    orderBy: "absenceDate"
  });

  const columns = [
    { field: "id", label: "makeupClass.table.field.id.label" },
    {
      field: "studentName",
      label: "makeupClass.table.field.studentName.label"
    },
    {
      field: "className",
      label: "makeupClass.table.field.className.label"
    },
    {
      field: "absenceDate",
      label: "makeupClass.table.field.leaveDay.label",
      sortable: false,
      valueGetter: ({ absenceDate }) => formatDate(absenceDate)
    },
    {
      field: "makeupDate",
      label: "makeupClass.table.field.makeupDate.label",
      sortable: false,
      valueGetter: ({ makeupDate }) => formatDate(makeupDate)
    },
    {
      field: "teacherName",
      label: "makeupClass.table.field.teacherName.label",
      sortable: false
    },
    {
      field: "status",
      label: "makeupClass.table.field.status.label",
      align: "center",
      component: (rowData) => (
        <Status
          prefix="makeupClass.historyStatus"
          postfix="label"
          status={rowData.historyStatus}
          type="HISTORY"
        />
      )
    },
    {
      field: "note",
      label: "makeupClass.table.field.description.label",
      sortable: false,
      component: ({ id, noteChange, notes, uuid }) => (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <TextField
            id="outlined-basic"
            value={notes[id - 1]}
            onChange={(e) => {
              noteChange(e, id);
            }}
            multiline
            maxRows={4}
            variant="outlined"
            sx={{ width: "100%" }}
            size="small"
          />
          <IconButton
            variant="contained"
            color="success"
            onClick={() => {
              post(`${UrlPaths.CLASSES}/histories/${uuid}/makeup/note`, {
                note: notes[id - 1]
              })
                .then(() => {
                  refreshData();
                  handleClose();
                })
                .catch(console.debug);
            }}>
            <SaveAsIcon />
          </IconButton>
        </Box>
      )
    }
  ];
  const rowActions = [
    {
      icon: <TaskAltIcon />,
      tooltip: t("attendance.table.action.markAsMadeUp.label"),
      action: (rowData) => {
        setSelectedMadeUpRow(rowData);
      },
      showIf: ({ historyStatus }) => historyStatus === "ABSENCE"
    },
    {
      icon: <UnpublishedIcon />,
      tooltip: t("attendance.table.action.markAsMakeUpUncompleted.label"),
      action: (rowData) => {
        setMakeUpUncompletedRow(rowData);
      },
      showIf: ({ historyStatus }) => historyStatus === "ABSENCE"
    },
    {
      icon: <DoNotDisturbOnIcon />,
      tooltip: t("attendance.table.action.markAsLOA.label"),
      action: (rowData) => {
        post(`${UrlPaths.STUDENTS}/attendance`, {
          attendanceUpdates: [
            {
              historyUuid: rowData.uuid
            }
          ],
          status: "LEAVE_OF_ABSENCE"
        })
          .then(() => {
            refreshData();
          })
          .catch(console.debug);
      },
      showIf: ({ historyStatus }) => historyStatus === "ABSENCE"
    },
    {
      icon: <HistoryIcon />,
      tooltip: t("attendance.table.action.revertMakeUpSchedule.label"),
      action: (rowData) => {
        setRevertRow(rowData);
      },
      showIf: ({ historyStatus }) => historyStatus !== "ABSENCE"
    }
  ];

  useEffect(() => {
    if (branchInfo.value.uuid) {
      refreshData();
    }
  }, [pagination]);

  useEffect(() => {
    setPagination({
      pageNumber: 0,
      pageSize: 30,
      order: "desc",
      orderBy: "absenceDate"
    });
  }, [branchInfo.value, searchCriteria]);

  const refreshData = () => {
    const { historyStatus, from, to } = searchCriteria;
    get(`${UrlPaths.CLASSES}/makeup`, {
      historyStatus,
      from,
      to,
      branchUuid: branchInfo.value.uuid,
      ...pagination
    })
      .then((res) => {
        const { pageSize, pageNumber } = pagination;
        setTotal(res.total);
        setData(res.results.map((x, index) => ({ id: pageNumber * pageSize + index + 1, ...x })));
        setNotes(res.results.map(({ note }) => note || ""));
      })
      .catch(console.debug);
  };

  const handleClose = () => {
    setSelectedMadeUpRow(null);
    setMakeUpUncompletedRow(null);
    setRevertRow(null);
  };

  const handleConfirmMakeUp = (data, isCompleted) => {
    const { uuid, teacherUuid, scheduleDate, note } = data;
    post(
      `${UrlPaths.CLASSES}/histories/${uuid}/makeup`,
      isCompleted
        ? {
            teacherUuid,
            status: "MAKE_UP_COMPLETED",
            scheduleDate: scheduleDate.toDate(),
            note
          }
        : { ...data, status: "MAKE_UP_UNCOMPLETED" }
    )
      .then(() => {
        refreshData();
        handleClose();
      })
      .catch(console.debug);
  };

  const handleMonthChange = (monthYear) => {
    const { from, to } = getMonthOptions().find(({ month, year }) => {
      return month + "|" + year === monthYear;
    });
    setSearchCriteria({
      ...searchCriteria,
      from: from,
      to: to,
      monthYear
    });
  };

  return (
    <Box>
      {/* SEARCH & ACTIONS BAR */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={t("header.title.label")} />
      </Box>
      <Box
        backgroundColor={colors.primary[400]}
        display="flex"
        mb="0.5rem"
        p="0.5rem"
        justifyContent="space-between">
        <Grid container mb="1rem" columnSpacing={1}>
          <Grid item xs={4}>
            <FormControl sx={{ minWidth: 150 }} size="small">
              <InputLabel id="class-status-label" color="neutral">
                {t("makeupClass.placeholder.status")}
              </InputLabel>
              <Select
                labelId="class-status-label"
                id="class-status"
                value={searchCriteria.historyStatus || ""}
                onChange={(e) =>
                  setSearchCriteria({ ...searchCriteria, historyStatus: e.target.value })
                }
                label={t("makeupClass.placeholder.status")}>
                <MenuItem value="">{t("makeupClass.historyStatus.ALL.label")}</MenuItem>
                <MenuItem value="ABSENCE">{t("makeupClass.historyStatus.ABSENCE.label")}</MenuItem>
                <MenuItem value="MAKE_UP_UNCOMPLETED">
                  {t("makeupClass.historyStatus.MAKE_UP_UNCOMPLETED.label")}
                </MenuItem>
                <MenuItem value="MAKE_UP_COMPLETED">
                  {t("makeupClass.historyStatus.MAKE_UP_COMPLETED.label")}
                </MenuItem>
                <MenuItem value="LEAVE_OF_ABSENCE">
                  {t("makeupClass.historyStatus.LEAVE_OF_ABSENCE.label")}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl sx={{ width: "100%", paddingX: "0.5rem" }} size="small">
              <InputLabel id="class-status-label" color="neutral">
                {t("makeupClass.placeholder.monthOptions")}
              </InputLabel>
              <Select
                labelId="class-status-label"
                id="class-status"
                label={t("makeupClass.placeholder.monthOptions")}
                value={searchCriteria.monthYear}
                onChange={(e) => handleMonthChange(e.target.value, true)}
                MenuProps={{
                  style: { zIndex: 15002 }
                }}
                sx={{ width: "100%" }}>
                {getMonthOptions().map(({ month, year, from, to }, index) => (
                  <MenuItem key={index} value={month + "|" + year}>
                    {t("makeupClass.monthOptions.label", {
                      month: month + 1,
                      year,
                      startDayOfMonth: from.format(DEFAULT_MONTH_FORMAT),
                      endDayOfMonth: to.format(DEFAULT_MONTH_FORMAT)
                    })}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      <Box
        backgroundColor={colors.primary[400]}
        display="flex"
        mb="0.5rem"
        p="0.5rem"
        justifyContent="space-between">
        <Table
          columnConfig={columns}
          data={data.map((x) => ({
            ...x,
            notes,
            noteChange: (e, id) => {
              const noteList = [...notes];
              noteList.splice(id - 1, 1, e.target.value);
              setNotes(noteList);
            }
          }))}
          rowActions={rowActions}
          totalResults={total}
          serverPaging={true}
          defaultPagination={pagination}
          paginationChanged={(paging) => setPagination(paging)}
        />
      </Box>
      <MakeupClassDialog
        open={!!selectedMadeUpRow}
        handleClose={handleClose}
        data={selectedMadeUpRow}
        confirmMakeUpClass={(data) => handleConfirmMakeUp(data, true)}
      />
      <Dialog
        open={!!makeUpUncompletedRow}
        data={makeUpUncompletedRow}
        onClose={handleClose}
        onConfirm={() => {
          handleConfirmMakeUp(
            { uuid: makeUpUncompletedRow.uuid, note: makeUpUncompletedNote },
            false
          );
        }}
        buttons={DialogButtons.CANCEL_CONFIRM}
        title={t("makeupClass.dialog.makeUpUncompleted.title")}>
        <TextField
          id="outlined-basic"
          label={t("attendance.makeupClass.placeholder.note")}
          value={makeUpUncompletedNote || ""}
          onChange={(e) => {
            setMakeUpUncompletedNote(e.target.value);
          }}
          variant="outlined"
          sx={{ width: "100%" }}
          size="small"
        />
      </Dialog>
      <Dialog
        open={!!revertRow}
        data={revertRow}
        onClose={handleClose}
        onConfirm={() => {
          post(`${UrlPaths.CLASSES}/histories/${revertRow.uuid}/makeup/revert`)
            .then(() => {
              refreshData();
              handleClose();
            })
            .catch(console.debug);
        }}
        buttons={[
          {
            type: "discard",
            id: "cancel",
            label: "common.button.cancel",
            variant: "outlined"
          },
          {
            type: "confirm",
            id: "confirm",
            label: "common.button.confirm",
            color: "secondary"
          }
        ]}
        headerTitleColor="white"
        title={t("makeupClass.dialog.revertMakeup.title")}>
        <Typography variant="h5" color={colors.grey[100]} sx={{ m: "0 0 5px 0" }}>
          <div
            dangerouslySetInnerHTML={{
              __html: t("makeupClass.dialog.revertMakeup.description")
            }}></div>
        </Typography>
      </Dialog>
    </Box>
  );
};

export default MakeupClass;

import { signal } from "@preact/signals-react";

export const currentRequests = signal([]);

export const globalLoading = signal(false);

export const globalError = signal(undefined);

export const currentRoute = signal("");

export const userProfile = signal({});

export const branches = signal([]);

export const branchInfo = signal({});

export const renewInvoice = signal(null);

export const invoiceCriterias = signal(null);

import Cookies from "universal-cookie";

const cookies = new Cookies();

const DEFAULT_SET_OPTIONS = {
  path: "/"
};

const setCookie = (name, value) => cookies.set(name, value, DEFAULT_SET_OPTIONS);

const getCookie = (name) => cookies.get(name);

const removeCookie = (name) => cookies.remove(name, DEFAULT_SET_OPTIONS);

export { setCookie, getCookie, removeCookie };

import { Edit } from "@mui/icons-material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DownloadIcon from "@mui/icons-material/Download";
import UploadIcon from "@mui/icons-material/Upload";
import {
  Box,
  ButtonGroup,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button, VisuallyHiddenInput } from "../../../components/button";
import { DialogButtons } from "../../../components/dialog";
import Header from "../../../components/header";
import Parent from "../../../components/parent-info";
import Status from "../../../components/status";
import Table from "../../../components/table";
import RequestHeaders from "../../../constants/RequestHeaders";
import UrlPaths from "../../../constants/UrlPaths";
import { get, post, postWithHeader, put } from "../../../services/HttpClient";
import { branchInfo } from "../../../signals";
import { tokens } from "../../../theme";
import { formatDate, getAge, getDayOfWeek } from "../../../utils/TimeUtil";
import { getDisplayName } from "../../../utils/UserUtil";
import StudentDetailsDialog from "./StudentDetailsDialog";

const MONTHS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

const StudentList = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();

  const [selectedRow, setSelectedRow] = useState(null);
  const [openStudentDialog, setOpenStudentDialog] = useState(false);
  const [total, setTotal] = useState(0);
  const [pagination, setPagination] = useState({
    pageNumber: 0,
    pageSize: 30,
    order: "desc",
    orderBy: "statusUpdatedAt"
  });
  const [searchCriteria, setSearchCriteria] = useState({});
  const [textFields, setTextFields] = useState({});
  const [data, setData] = useState([]);

  const columns = [
    { field: "id", label: "studentList.table.field.id.label", sortable: false },
    {
      field: "lastName",
      label: "studentList.table.field.student.label",
      valueGetter: (student) => (
        <Link to={`details/${student.uuid}`}>
          <Typography sx={{ fontWeight: "bold", color: colors.greenAccent[400] }}>
            {getDisplayName(student)}
          </Typography>
        </Link>
      )
    },
    {
      field: "birthday",
      label: "studentList.table.field.birthday.label",
      valueGetter: ({ birthday }) =>
        birthday ? (
          <>
            <span>{formatDate(birthday)}</span>&nbsp;
            <span style={{ fontStyle: "italic" }}>
              {t("studentList.table.field.age.label", { age: getAge(birthday) })}
            </span>
          </>
        ) : null
    },
    {
      field: "parent",
      label: "studentList.table.field.parents.label",
      valueGetter: ({ parent, parent2 }) => (
        <>
          {parent ? (
            <Parent parentName={getDisplayName(parent)} phoneNumber={parent?.mobile} />
          ) : null}
          {parent2 ? (
            <>
              <Divider sx={{ marginY: "0.5rem" }} />
              <Parent parentName={getDisplayName(parent2)} phoneNumber={parent2?.mobile} />
            </>
          ) : null}
        </>
      )
    },
    {
      field: "classes",
      label: "studentList.table.field.classes.label",
      component: ({ schedule }) => {
        return (schedule || []).map(({ className, classroomName, weekDay, timeSlot }, index) => (
          <Typography key={index} fontSize="14px">
            {className} / {timeSlot} {t(`timetable.weekDay.${getDayOfWeek(weekDay)}.label`)} (
            {classroomName})
          </Typography>
        ));
      }
    },
    {
      field: "status",
      label: "studentList.table.field.status.label",
      alignItems: "center",
      component: (rowData) => (
        <Status
          prefix="studentList.status"
          postfix="label"
          status={rowData.status}
          type="STUDENT"
        />
      )
    },
    {
      field: "referralSource",
      label: "studentList.table.field.referralSource.label",
      valueGetter: ({ referralSource }) =>
        referralSource ? t(`studentDetails.referralSource.${referralSource}.label`) : ""
    },
    {
      field: "note",
      label: "studentList.table.field.note.label"
    }
  ];
  const rowActions = [
    {
      icon: <Edit />,
      tooltip: "Edit student",
      action: (rowData) => {
        setOpenStudentDialog(true);
        setSelectedRow(rowData);
      }
    }
  ];

  useEffect(() => {
    if (branchInfo.value.uuid) {
      refreshData();
    }
  }, [pagination]);

  useEffect(() => {
    setPagination({
      pageNumber: 0,
      pageSize: 30,
      order: "desc",
      orderBy: "statusUpdatedAt"
    });
  }, [branchInfo.value, searchCriteria]);

  const handleCriteriaChange = (field, value) => {
    setSearchCriteria({ ...searchCriteria, [field]: value || undefined });
  };

  const handleTextFieldsChange = (field, value) => {
    setTextFields({ ...textFields, [field]: value || undefined });
  };

  const handleKeyDown = (event, field) => {
    if (event.key === "Enter") {
      handleCriteriaChange(field, textFields[field]);
    }
  };

  const refreshData = () => {
    get(UrlPaths.STUDENTS, {
      compactLoad: false,
      branchUuid: branchInfo.value.uuid,
      ...pagination,
      ...searchCriteria
    })
      .then((res) => {
        const { pageSize, pageNumber } = pagination;
        setTotal(res.total);
        setData(res.results.map((x, index) => ({ id: pageNumber * pageSize + index + 1, ...x })));
      })
      .catch(console.debug);
  };

  const handleClose = () => {
    setSelectedRow(null);
    setOpenStudentDialog(false);
  };

  const uploadParentList = (event) => {
    uploadCSV(event, "PARENT");
  };

  const uploadStudentList = (event) => {
    uploadCSV(event, "STUDENT");
  };

  const uploadCSV = (event, fileType) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("fileType", fileType);
      postWithHeader(
        `${UrlPaths.ADMIN}/csv`,
        {
          [RequestHeaders.CONTENT_TYPE]: "multipart/form-data"
        },
        formData
      )
        .then(() => {
          refreshData();
        })
        .catch(console.debug);
    }
  };

  return (
    <Box>
      {/* SEARCH & ACTIONS BAR */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Brisky Online" subtitle={t("studentList.header.subtitle")} />
      </Box>
      <Box
        backgroundColor={colors.primary[400]}
        mb="0.5rem"
        p="0.5rem"
        justifyContent="space-between">
        <Grid container mb="1rem" columnSpacing={1}>
          <Grid item xs={4}>
            <TextField
              id="outlined-basic"
              label={t("studentList.placeholder.studentName.label")}
              variant="outlined"
              value={textFields.any || ""}
              onKeyDown={(e) => handleKeyDown(e, "any")}
              onChange={(e) => handleTextFieldsChange("any", e.target.value)}
              sx={{ width: "100%", paddingX: "0.5rem" }}
              size="small"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="outlined-basic"
              label={t("studentList.placeholder.parentName.label")}
              variant="outlined"
              value={textFields.parentName || ""}
              onKeyDown={(e) => handleKeyDown(e, "parentName")}
              onChange={(e) => handleTextFieldsChange("parentName", e.target.value)}
              sx={{ width: "100%", paddingX: "0.5rem" }}
              size="small"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="outlined-basic"
              label={t("studentList.placeholder.parentPhone.label")}
              variant="outlined"
              value={textFields.parentPhone || ""}
              onKeyDown={(e) => handleKeyDown(e, "parentPhone")}
              onChange={(e) => handleTextFieldsChange("parentPhone", e.target.value)}
              sx={{ width: "100%", paddingX: "0.5rem" }}
              size="small"
            />
          </Grid>
        </Grid>
        <Grid container mb="1rem" columnSpacing={1}>
          <Grid item xs={4}>
            <FormControl sx={{ width: "100%", paddingX: "0.5rem" }} size="small">
              <InputLabel id="studentlist-status-label" color="neutral">
                {t("studentList.placeholder.status.label")}
              </InputLabel>
              <Select
                labelId="student-status-label"
                id="student-status"
                label={t("studentList.placeholder.status.label")}
                value={searchCriteria.status || ""}
                onChange={(e) => handleCriteriaChange("status", e.target.value)}>
                <MenuItem value="">&nbsp;</MenuItem>
                <MenuItem value="STD_ACTIVE">{t("studentList.status.STD_ACTIVE.label")}</MenuItem>
                <MenuItem value="STD_TRIAL">{t("studentList.status.STD_TRIAL.label")}</MenuItem>
                <MenuItem value="STD_LOA">{t("studentList.status.STD_LOA.label")}</MenuItem>
                <MenuItem value="STD_IN_DEBT">{t("studentList.status.STD_IN_DEBT.label")}</MenuItem>
                <MenuItem value="STD_INACTIVE">
                  {t("studentList.status.STD_INACTIVE.label")}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl sx={{ width: "100%", paddingX: "0.5rem" }} size="small">
              <InputLabel id="status-label" color="neutral">
                {t("studentDetails.placeholder.referralSource")}
              </InputLabel>
              <Select
                labelId="status-label"
                id="status"
                label={t("studentDetails.placeholder.referralSource")}
                MenuProps={{
                  style: { zIndex: 15003 }
                }}
                value={searchCriteria.referralSource || ""}
                onChange={(e) => handleCriteriaChange("referralSource", e.target.value)}
                sx={{ width: "100%" }}>
                <MenuItem value="">&nbsp;</MenuItem>
                <MenuItem value="PARENT">
                  {t("studentDetails.referralSource.PARENT.label")}
                </MenuItem>
                <MenuItem value="TEACHER">
                  {t("studentDetails.referralSource.TEACHER.label")}
                </MenuItem>
                <MenuItem value="STUDENT">
                  {t("studentDetails.referralSource.STUDENT.label")}
                </MenuItem>
                <MenuItem value="EMPLOYEE">
                  {t("studentDetails.referralSource.EMPLOYEE.label")}
                </MenuItem>
                <MenuItem value="FACEBOOK_TT">
                  {t("studentDetails.referralSource.FACEBOOK_TT.label")}
                </MenuItem>
                <MenuItem value="FACEBOOK_ADS">
                  {t("studentDetails.referralSource.FACEBOOK_ADS.label")}
                </MenuItem>
                <MenuItem value="KIDDI_HUB">
                  {t("studentDetails.referralSource.KIDDI_HUB.label")}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl sx={{ width: "100%", paddingX: "0.5rem" }} size="small">
              <InputLabel id="status-label" color="neutral">
                {t("studentDetails.placeholder.birthdayInMonth")}
              </InputLabel>
              <Select
                labelId="birthdayInMonth-label"
                id="birthdayInMonth"
                label={t("studentDetails.placeholder.birthdayInMonth")}
                MenuProps={{
                  style: { zIndex: 15003 }
                }}
                value={searchCriteria.birthdayInMonth || ""}
                onChange={(e) => handleCriteriaChange("birthdayInMonth", e.target.value)}
                sx={{ width: "100%" }}>
                <MenuItem value="">{t("studentDetails.placeholder.birthdayInAllMonths")}</MenuItem>
                {MONTHS.map((month) => (
                  <MenuItem key={month} value={month}>
                    {month}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container direction="row-reverse" columnSpacing={1}>
          <Grid item xs={6} sx={{ display: "flex", flexDirection: "row-reverse" }}>
            <ButtonGroup variant="contained" aria-label="outlined primary button group">
              <Button
                component="label"
                variant="contained"
                startIcon={<UploadIcon />}
                color="secondary">
                {t("studentList.button.uploadParent")}
                <VisuallyHiddenInput type="file" accept=".csv" onChange={uploadParentList} />
              </Button>
              <Button
                component="label"
                variant="contained"
                startIcon={<UploadIcon />}
                color="secondary">
                {t("studentList.button.uploadStudent")}
                <VisuallyHiddenInput type="file" accept=".csv" onChange={uploadStudentList} />
              </Button>
              <Button startIcon={<DownloadIcon />} variant="contained" color="neutral" disabled>
                {t("common.button.excel")}
              </Button>
              <Button
                variant="contained"
                color="success"
                startIcon={<AddCircleOutlineIcon />}
                onClick={() => setOpenStudentDialog(true)}>
                {t("common.button.new")}
              </Button>
            </ButtonGroup>
          </Grid>
        </Grid>
      </Box>
      <Box
        backgroundColor={colors.primary[400]}
        display="flex"
        mb="0.5rem"
        p="0.5rem"
        justifyContent="space-between">
        <Table
          columnConfig={columns}
          rowActions={rowActions}
          data={data}
          totalResults={total}
          serverPaging={true}
          defaultPagination={pagination}
          paginationChanged={(paging) => setPagination(paging)}
        />
      </Box>
      <StudentDetailsDialog
        open={openStudentDialog}
        editable={!!selectedRow}
        data={selectedRow}
        onClose={handleClose}
        onConfirm={(newStudent, formData) => {
          if (newStudent) {
            post(UrlPaths.STUDENTS, {
              ...formData,
              referralSource: formData.referralSource || undefined
            })
              .then(() => {
                handleClose();
                refreshData();
              })
              .catch(console.debug);
          } else {
            put(`${UrlPaths.STUDENTS}/${selectedRow.uuid}`, {
              ...formData,
              referralSource: formData.referralSource || undefined
            })
              .then(() => {
                handleClose();
                refreshData();
              })
              .catch(console.debug);
          }
        }}
        buttons={DialogButtons.CANCEL_SAVE}
        title={t(
          `studentList.dialog.${
            openStudentDialog && !selectedRow ? "newStudent" : "updateStudent"
          }.title`
        )}
      />
    </Box>
  );
};

export default StudentList;

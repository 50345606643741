import Delete from "@mui/icons-material/Delete";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useTheme
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Autocomplete from "../../../components/autocomplete";
import Dialog, { DialogButtons } from "../../../components/dialog";
import Header from "../../../components/header";
import Status from "../../../components/status";
import Table from "../../../components/table";
import UrlPaths from "../../../constants/UrlPaths";
import { deleteById, get } from "../../../services/HttpClient";
import { branchInfo, invoiceCriterias } from "../../../signals";
import { tokens } from "../../../theme";
import { toVNDCurrency } from "../../../utils/CurencyUtil";
import { DEFAULT_DATE_FORMAT, formatDate } from "../../../utils/TimeUtil";
import { getStudentDetailLabel } from "../../../utils/UserUtil";

const INITIAL_SEARCH_CRITERIA = {
  studentUuid: "",
  studentValue: {
    uuid: "",
    displayName: ""
  },
  contractType: "",
  fromDate: moment().subtract(1, "weeks"),
  toDate: moment()
};

const Invoices = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();

  const [data, setData] = useState([]);
  const [deletionRow, setDeletionRow] = useState(null);
  const [total, setTotal] = useState(0);
  const [pagination, setPagination] = useState({
    pageNumber: 0,
    pageSize: 30
  });
  const [searchCriteria, setSearchCriteria] = useState(INITIAL_SEARCH_CRITERIA);

  const columns = [
    { field: "id", label: "invoices.table.field.id.label", sortable: false },
    {
      field: "invoiceId",
      label: "invoices.table.field.contractId.label",
      valueGetter: ({ uuid, invoiceId }) => (
        <Link to={`../payment-contract/${uuid}`}>
          <Typography sx={{ fontWeight: "bold", color: colors.greenAccent[400] }}>
            {invoiceId}
          </Typography>
        </Link>
      )
    },
    {
      field: "studentName",
      label: "invoices.table.field.studentName.label"
    },
    {
      field: "creationDate",
      label: "invoices.table.field.creationDate.label",
      valueGetter: ({ creationDate }) => formatDate(creationDate)
    },
    {
      field: "paidAndDebt",
      label: "invoices.table.field.paidAndDebt.label",
      valueGetter: ({ totalAmount, paidAmount, discountAmount }) =>
        `${toVNDCurrency(paidAmount)}/${toVNDCurrency(totalAmount - (paidAmount + discountAmount))}`
    },
    {
      field: "contractType",
      label: "invoices.table.field.contractType.label",
      valueGetter: ({ contractType }) => t(`invoices.contractType.${contractType}`)
    },
    {
      field: "contractStatus",
      label: "invoices.table.field.contractStatus.label",
      component: (rowData) => (
        <Status prefix="invoices.status" postfix="label" status={rowData.status} type="INVOICE" />
      )
    }
  ];
  const rowActions = [
    {
      icon: <Delete />,
      tooltip: t("invoices.table.action.deleteInvoice.label"),
      action: (rowData) => {
        setDeletionRow(rowData);
      }
    },
    {
      icon: <FileDownloadIcon />,
      tooltip: t("invoices.table.action.downloadInvoice.label"),
      action: (rowData) => {
        get(`${UrlPaths.ADMIN}/invoices/${rowData.uuid}/export`)
          .then((base64Str) => {
            saveInvoiceAsDocx(base64Str, rowData.studentName);
          })
          .catch(console.debug);
      }
    }
  ];

  useEffect(() => {
    if (branchInfo.value.uuid) {
      refreshData();
    }
  }, [pagination]);

  useEffect(() => {
    setPagination({
      pageNumber: 0,
      pageSize: 30
    });
  }, [branchInfo.value, searchCriteria]);

  const refreshData = () => {
    if (invoiceCriterias.value) {
      setSearchCriteria({ ...searchCriteria, ...invoiceCriterias.value });
    }
    const criterias = invoiceCriterias.value
      ? { ...searchCriteria, ...invoiceCriterias.value }
      : searchCriteria;
    const { fromDate, toDate, contractType, studentUuid } = criterias;

    const params = {
      studentUuid,
      fromDate,
      toDate,
      contractType
    };
    get(`${UrlPaths.ADMIN}/invoices`, {
      branchUuid: branchInfo.value.uuid,
      ...pagination,
      ...params
    })
      .then((res) => {
        const { pageSize, pageNumber } = pagination;
        setTotal(res.total);
        setData(res.results.map((x, index) => ({ id: pageNumber * pageSize + index + 1, ...x })));
        if (invoiceCriterias.value) {
          invoiceCriterias.value = null;
        }
      })
      .catch(console.debug);
  };

  const saveInvoiceAsDocx = (base64Str, studentName) => {
    const link = document.createElement("a");
    link.href = `data:text/docx;base64,${base64Str}`;
    link.download =
      t("invoices.fileDownload.name", {
        studentName,
        creationDate: moment().format(DEFAULT_DATE_FORMAT)
      }) + ".docx";
    link.click();
  };

  return (
    <Box>
      {/* SEARCH & ACTIONS BAR */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={t("header.title.label")} />
      </Box>
      <Box
        backgroundColor={colors.primary[400]}
        mb="0.5rem"
        p="0.5rem"
        justifyContent="space-between">
        <Grid container mb="1rem" columnSpacing={1}>
          <Grid item xs={6}>
            <FormControl sx={{ width: "100%", paddingX: "0.5rem" }} size="small">
              <Autocomplete
                placeholder="invoices.placeholder.student"
                value={searchCriteria.studentValue}
                onChange={(e, value) => {
                  if (value) {
                    setSearchCriteria({
                      ...searchCriteria,
                      studentUuid: value.uuid || "",
                      studentValue: value
                    });
                  } else {
                    setSearchCriteria({
                      ...searchCriteria,
                      studentUuid: "",
                      studentValue: {
                        uuid: "",
                        displayName: getStudentDetailLabel(null)
                      }
                    });
                  }
                }}
                requestConfig={{
                  url: `${UrlPaths.STUDENTS}?branchUuid=${branchInfo.value.uuid}`,
                  responseField: "results",
                  label: (student) => getStudentDetailLabel(student),
                  value: "uuid",
                  onQueryChange: (value) => (value ? `&any=${value}` : "")
                }}></Autocomplete>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl sx={{ width: "100%", paddingX: "0.5rem" }} size="small">
              <InputLabel id="contract-type-label" color="neutral">
                {t("invoices.placeholder.contractType")}
              </InputLabel>
              <Select
                labelId="contract-type-label"
                id="contract-type"
                label={t("invoices.placeholder.contractType")}
                MenuProps={{
                  style: { zIndex: 15003 }
                }}
                value={searchCriteria.contractType}
                onChange={(e) => {
                  setSearchCriteria({ ...searchCriteria, contractType: e.target.value });
                }}
                sx={{ width: "100%" }}>
                <MenuItem value="">{t("invoices.contractType.ALL")}</MenuItem>
                <MenuItem value="RENEW">{t("invoices.contractType.RENEW")}</MenuItem>
                <MenuItem value="NEW">{t("invoices.contractType.NEW")}</MenuItem>
                <MenuItem value="VIRTUAL_CONTRACT">
                  {t("invoices.contractType.VIRTUAL_CONTRACT")}
                </MenuItem>
                <MenuItem value="ITEM">{t("invoices.contractType.ITEM")}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container mb="1rem" columnSpacing={1}>
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                format={DEFAULT_DATE_FORMAT}
                label={t("invoices.placeholder.fromDate")}
                maxDate={searchCriteria.toDate}
                value={searchCriteria.fromDate}
                onChange={(e) => setSearchCriteria({ ...searchCriteria, fromDate: e })}
                sx={{ width: "100%", padding: "0 0.5rem" }}
                timeSteps={{ hours: 1, minutes: 15 }}
                slotProps={{ textField: { size: "small" } }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                format={DEFAULT_DATE_FORMAT}
                label={t("invoices.placeholder.toDate")}
                minDate={searchCriteria.fromDate}
                value={searchCriteria.toDate}
                onChange={(e) => setSearchCriteria({ ...searchCriteria, toDate: e })}
                sx={{ width: "100%", padding: "0 0.5rem" }}
                timeSteps={{ hours: 1, minutes: 15 }}
                slotProps={{ textField: { size: "small" } }}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
        <Grid container direction="row-reverse" columnSpacing={1}></Grid>
      </Box>
      <Box
        backgroundColor={colors.primary[400]}
        display="flex"
        mb="0.5rem"
        p="0.5rem"
        justifyContent="space-between"
        alignItems="center">
        <Grid item xs={3} sx={{ display: "flex", alignItems: "center" }}>
          <Typography pr="0.5rem" color="#0e8416" fontWeight="bold">
            {t("invoices.totalInvoices.label")}
          </Typography>
          <Typography pr="0.5rem" color="#0e8416" fontWeight="bold">
            {data.length}
          </Typography>
        </Grid>
        <Grid item xs={3} sx={{ display: "flex", alignItems: "center" }}>
          <Typography pr="0.5rem" color="#06389d" fontWeight="bold">
            {t("invoices.totalAmount.label")}
          </Typography>
          <Typography pr="0.5rem" color="#06389d" fontWeight="bold">
            {toVNDCurrency(
              data.reduce(
                (amount, { totalAmount, discountAmount }) =>
                  (amount += totalAmount - discountAmount),
                0
              )
            )}
          </Typography>
        </Grid>
        <Grid item xs={3} sx={{ display: "flex", alignItems: "center" }}>
          <Typography pr="0.5rem" color="#d6420a" fontWeight="bold">
            {t("invoices.inDebtAmount.label")}
          </Typography>
          <Typography pr="0.5rem" color="#d6420a" fontWeight="bold">
            {toVNDCurrency(
              data.reduce(
                (amount, { paidAmount, totalAmount, discountAmount }) =>
                  (amount += totalAmount - (paidAmount + discountAmount)),
                0
              )
            )}
          </Typography>
        </Grid>
        <Grid item xs={3} sx={{ display: "flex", alignItems: "center" }}>
          <Typography pr="0.5rem" color="#101010" fontWeight="bold">
            {t("invoices.renewQuantity.label")}
          </Typography>
          <Typography pr="0.5rem" color="#101010" fontWeight="bold">
            {data.filter(({ contractType }) => contractType === "RENEW").length}
          </Typography>
        </Grid>
        <Grid item xs={3} sx={{ display: "flex", alignItems: "center" }}>
          <Typography pr="0.5rem" color="#101010" fontWeight="bold">
            {t("invoices.newQuantity.label")}
          </Typography>
          <Typography pr="0.5rem" color="#101010" fontWeight="bold">
            {data.filter(({ contractType }) => contractType === "NEW").length}
          </Typography>
        </Grid>
      </Box>
      <Box
        backgroundColor={colors.primary[400]}
        display="flex"
        mb="0.5rem"
        p="0.5rem"
        justifyContent="space-between">
        <Table
          columnConfig={columns}
          data={data}
          rowActions={rowActions}
          totalResults={total}
          serverPaging={true}
          defaultPagination={pagination}
          paginationChanged={(paging) => setPagination(paging)}
        />
      </Box>
      <Dialog
        open={!!deletionRow}
        data={deletionRow}
        onClose={() => setDeletionRow(null)}
        onConfirm={() => {
          deleteById(`${UrlPaths.ADMIN}/invoices`, deletionRow?.uuid)
            .then(() => {
              refreshData();
              setDeletionRow(null);
            })
            .catch(console.debug);
        }}
        buttons={DialogButtons.CANCEL_DELETE}
        headerBgColor="#d32f2f"
        headerTitleColor="white"
        title={t("invoices.dialog.deleteInvoice.title")}>
        <Typography variant="h5" color={colors.grey[100]} sx={{ m: "0 0 5px 0" }}>
          <div
            style={{ marginBottom: "1rem" }}
            dangerouslySetInnerHTML={{
              __html: t("invoices.dialog.deleteInvoice.confirmTitle", {
                invoiceId: deletionRow?.invoiceId
              })
            }}></div>
          <div>{t("invoices.dialog.deleteInvoice.description")}</div>
        </Typography>
      </Dialog>
    </Box>
  );
};

export default Invoices;

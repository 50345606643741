import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
  useTheme
} from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Autocomplete from "../../../components/autocomplete";
import Header from "../../../components/header";
import Table from "../../../components/table";
import UrlPaths from "../../../constants/UrlPaths";
import { get } from "../../../services/HttpClient";
import { branchInfo, userProfile } from "../../../signals";
import { tokens } from "../../../theme";
import { DEFAULT_MONTH_FORMAT, getMonthOptions } from "../../../utils/TimeUtil";
import { isTeacher } from "../../../utils/UserUtil";

const DEFAULT_SEARCH_CRITERIA = {
  monthYear: moment().month() + "|" + moment().isoWeekYear(),
  from: moment().startOf("month"),
  to: moment().endOf("month"),
  classUuid: "",
  classValue: {
    className: "",
    uuid: ""
  }
};

const ClassReports = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();

  const [data, setData] = useState([]);
  const [invoiceReports, setInvoiceReports] = useState([]);
  const [searchCriteria, setSearchCriteria] = useState(DEFAULT_SEARCH_CRITERIA);
  const [viewBy, setViewBy] = useState("QUANTITY");

  const columns = [
    {
      field: "className",
      label: "classReport.table.field.className.label",
      sortable: false,
      valueGetter: ({ classUuid, className }) =>
        classUuid ? (
          <Link to={`details/${classUuid}`}>
            <Typography sx={{ fontWeight: "bold", color: colors.greenAccent[400] }}>
              {className}
            </Typography>
          </Link>
        ) : (
          <Typography sx={{ fontWeight: "bold" }}>{className}</Typography>
        )
    },
    {
      field: "totalStudents",
      align: "center",
      label: "classReport.table.field.totalStudents.label"
    },
    {
      field: "completedQuantity",
      label: "classReport.table.field.completedQuantity.label",
      align: "center",
      valueGetter: ({ completedQuantity, totalSessions, viewBy }) =>
        getReportNumber(completedQuantity, totalSessions, viewBy)
    },
    {
      field: "absenceQuantity",
      label: "classReport.table.field.absenceQuantity.label",
      sortable: false,
      align: "center",
      valueGetter: ({
        madeUpQuantity,
        madeUpUncompletedQuantity,
        absenceQuantity,
        totalSessions,
        viewBy
      }) =>
        getReportNumber(
          madeUpQuantity + madeUpUncompletedQuantity + absenceQuantity,
          totalSessions,
          viewBy
        )
    },
    {
      field: "madeUpQuantity",
      label: "classReport.table.field.madeUpQuantity.label",
      sortable: false,
      align: "center",
      valueGetter: ({ madeUpQuantity, madeUpUncompletedQuantity, absenceQuantity, viewBy }) =>
        getReportNumber(
          madeUpQuantity,
          madeUpQuantity + madeUpUncompletedQuantity + absenceQuantity,
          viewBy
        )
    },
    {
      field: "madeUpUncompletedQuantity",
      label: "classReport.table.field.madeUpUncompletedQuantity.label",
      align: "center",
      valueGetter: ({ madeUpQuantity, madeUpUncompletedQuantity, absenceQuantity, viewBy }) =>
        getReportNumber(
          madeUpUncompletedQuantity,
          madeUpQuantity + madeUpUncompletedQuantity + absenceQuantity,
          viewBy
        )
    },
    {
      field: "loaQuantity",
      label: "classReport.table.field.loaQuantity.label",
      valueGetter: ({ loaQuantity, totalSessions, viewBy }) =>
        getReportNumber(loaQuantity, totalSessions, viewBy)
    },
    {
      field: "activeStudents",
      label: "classReport.table.field.activeStudents.label",
      align: "left"
    }
  ];
  const invoiceColumns = [
    {
      field: "month",
      label: "invoiceReport.table.field.month.label",
      sortable: false
    },
    {
      field: "totalEndedInvoices",
      align: "center",
      label: "invoiceReport.table.field.totalEndedInvoices.label"
    },
    {
      field: "renewQuantity",
      label: "invoiceReport.table.field.renewQuantity.label",
      align: "center",
      valueGetter: ({ renewQuantity, totalEndedInvoices, viewBy }) =>
        getReportNumber(renewQuantity, totalEndedInvoices, viewBy, false)
    },
    {
      field: "terminatedQuantity",
      label: "invoiceReport.table.field.terminatedQuantity.label",
      sortable: false,
      align: "center",
      valueGetter: ({ terminatedQuantity, totalEndedInvoices, viewBy }) =>
        getReportNumber(terminatedQuantity, totalEndedInvoices, viewBy, false)
    },
    {
      field: "inDebtQuantity",
      label: "invoiceReport.table.field.inDebtQuantity.label",
      sortable: false,
      align: "center",
      valueGetter: ({ inDebtQuantity, totalEndedInvoices, viewBy }) =>
        getReportNumber(inDebtQuantity, totalEndedInvoices, viewBy, false)
    },
    {
      field: "badDebtQuantity",
      label: "invoiceReport.table.field.badDebtQuantity.label",
      align: "center",
      valueGetter: ({ badDebtQuantity, totalEndedInvoices, viewBy }) =>
        getReportNumber(badDebtQuantity, totalEndedInvoices, viewBy, false)
    }
  ];

  useEffect(() => {
    refreshData();
  }, [branchInfo.value, searchCriteria]);

  useEffect(() => {
    setData(data.map((x) => ({ ...x, viewBy })));
    setInvoiceReports(invoiceReports.map((x) => ({ ...x, viewBy })));
  }, [viewBy]);

  const refreshData = () => {
    if (branchInfo.value.uuid && !isTeacher(userProfile.value)) {
      const { from, to, classUuid } = searchCriteria;
      get(`${UrlPaths.ADMIN}/statistics/${branchInfo.value.uuid}/classReports`, { from, to })
        .then((res) => {
          setData([
            ...res.map((x) => ({
              ...x,
              activeStudents: Number(
                (x.totalStudents * (x.completedQuantity / x.totalSessions)).toFixed(2)
              ),
              viewBy
            })),
            {
              className: t("statistics.total.label"),
              totalStudents: res.reduce((total, { totalStudents }) => {
                total += totalStudents;
                return total;
              }, 0),
              activeStudents: Number(
                res.reduce((total, { totalStudents }) => {
                  total += totalStudents;
                  return total;
                }, 0) *
                  (
                    res.reduce((total, { completedQuantity }) => {
                      total += completedQuantity;
                      return total;
                    }, 0) /
                    res.reduce((total, { totalSessions }) => {
                      total += totalSessions;
                      return total;
                    }, 0)
                  ).toFixed(2)
              ),
              viewBy
            }
          ]);
        })
        .catch(console.debug);
      get(`${UrlPaths.ADMIN}/statistics/${branchInfo.value.uuid}/invoiceReports`, { classUuid })
        .then((res) => {
          setInvoiceReports(res.map((x) => ({ ...x, viewBy })));
        })
        .catch(console.debug);
    }
  };

  const handleMonthChange = (monthYear) => {
    const { from, to } = getMonthOptions().find(({ month, year }) => {
      return month + "|" + year === monthYear;
    });
    setSearchCriteria({
      from: from,
      to: to,
      monthYear
    });
  };

  const getReportNumber = (actual, total, viewBy, showTotal = true) => {
    if (actual === null || actual === undefined || total === null || total === undefined) {
      return "";
    }
    if (viewBy === "QUANTITY") {
      return actual === 0 ? 0 : showTotal ? `${actual} / ${total}` : actual;
    } else {
      const percentage = (actual / total) * 100;
      return `${isNaN(percentage) || percentage === 0 ? 0 : percentage.toFixed(2)}%`;
    }
  };

  return (
    <Box>
      {/* SEARCH & ACTIONS BAR */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={t("header.title.label")} />
      </Box>
      <Box
        backgroundColor={colors.primary[400]}
        display="flex"
        mb="0.5rem"
        p="0.5rem"
        justifyContent="space-between">
        <Grid
          item
          xs={4}
          borderRadius="3px"
          sx={{ display: "flex", alignItems: "center", backgroundColor: colors.grey[900] }}>
          <FormControl
            sx={{
              paddingX: "0.5rem",
              display: "flex",
              flexDirection: "row",
              alignItems: "center"
            }}>
            <FormLabel id="status-label" sx={{ paddingRight: "0.5rem" }}>
              {t("classReport.placeholder.viewBy")}
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="status-label"
              value={viewBy}
              name="row-radio-buttons-group"
              onChange={(e) => {
                setViewBy(e.target.value);
              }}>
              <FormControlLabel
                value="QUANTITY"
                control={<Radio />}
                label={t("classReport.viewBy.quantity.label")}
              />
              <FormControlLabel
                value="PERCENTAGE"
                control={<Radio />}
                label={t("classReport.viewBy.percentage.label")}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl sx={{ width: "100%", paddingX: "0.5rem" }} size="small">
            <InputLabel id="class-status-label" color="neutral">
              {t("makeupClass.placeholder.monthOptions")}
            </InputLabel>
            <Select
              labelId="class-status-label"
              id="class-status"
              label={t("makeupClass.placeholder.monthOptions")}
              value={searchCriteria.monthYear}
              onChange={(e) => handleMonthChange(e.target.value, true)}
              MenuProps={{
                style: { zIndex: 15002 }
              }}
              sx={{ width: "100%" }}>
              {getMonthOptions().map(({ month, year, from, to }, index) => (
                <MenuItem key={index} value={month + "|" + year}>
                  {t("makeupClass.monthOptions.label", {
                    month: month + 1,
                    year,
                    startDayOfMonth: from.format(DEFAULT_MONTH_FORMAT),
                    endDayOfMonth: to.format(DEFAULT_MONTH_FORMAT)
                  })}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4} />
      </Box>
      <Box
        backgroundColor={colors.primary[400]}
        display="flex"
        mb="0.5rem"
        p="0.5rem"
        justifyContent="space-between">
        <Table columnConfig={columns} data={data} />
      </Box>
      <Box
        backgroundColor={colors.primary[400]}
        display="flex"
        mb="0.5rem"
        p="0.5rem"
        flexDirection="column">
        <Grid container mb="1rem" columnSpacing={1}>
          <Grid item xs={4}>
            <Typography sx={{ fontSize: 14, fontWeight: "bold" }}>
              {t("invoiceReport.details.title")}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h5" sx={{ marginY: "1rem" }}>
              {t("invoiceReport.details.average")}:&nbsp;
              <b>
                {(
                  invoiceReports.reduce((total, { totalEndedInvoices }) => {
                    total += totalEndedInvoices || 0;
                    return total;
                  }, 0) / 12
                ).toFixed(2)}
              </b>
              &nbsp;{t("invoiceReport.details.average.sufix")}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <FormControl sx={{ width: "100%", paddingX: "0.5rem" }} size="small">
              <Autocomplete
                value={searchCriteria.classValue}
                placeholder="loadStudents.placeholder.className"
                onChange={(e, value) => {
                  setSearchCriteria({
                    ...searchCriteria,
                    classUuid: value?.uuid,
                    classValue: value || {
                      className: "",
                      uuid: ""
                    }
                  });
                }}
                requestConfig={{
                  url: `${UrlPaths.CLASSES}?branchUuid=${branchInfo.value.uuid}`,
                  label: "className",
                  value: "uuid",
                  responseField: (res) => res.results.map(({ classDetails }) => classDetails),
                  onQueryChange: (value) => (value ? `&any=${value}` : "")
                }}></Autocomplete>
            </FormControl>
          </Grid>
        </Grid>
        <Table
          showHeaderToolbar={false}
          showFooterToolbar={false}
          columnConfig={invoiceColumns}
          data={invoiceReports}
        />
      </Box>
    </Box>
  );
};

export default ClassReports;

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Box, FormControl, Grid, TextField, Typography, useTheme } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Autocomplete from "../../../components/autocomplete";
import Dialog, { DialogButtons } from "../../../components/dialog";
import Header from "../../../components/header";
import Table from "../../../components/table";
import UrlPaths from "../../../constants/UrlPaths";
import { get, post } from "../../../services/HttpClient";
import { branchInfo, userProfile } from "../../../signals";
import { tokens } from "../../../theme";
import { DEFAULT_DATE_FORMAT, formatDate } from "../../../utils/TimeUtil";
import { getStudentDetailLabel, isTeacher } from "../../../utils/UserUtil";
import AddStudentToClassDialog from "./AddStudentToClassDialog";
import moment from "moment";

const DEFAULT_SEARCH_CRITERIA = {
  studentUuid: "",
  studentValue: {
    uuid: "",
    displayName: getStudentDetailLabel(null)
  }
};

const LOAStudents = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();

  const [data, setData] = useState([]);
  const [addStudent, setAddStudent] = useState(null);
  const [disableRow, setDisableRow] = useState(null);
  const [disableNote, setDisableNote] = useState("");
  const [disabledDate, setDisabledDate] = useState(moment());
  const [searchCriteria, setSearchCriteria] = useState(DEFAULT_SEARCH_CRITERIA);

  const columns = [
    { field: "id", label: "loaStudents.table.field.id.label" },
    {
      field: "studentName",
      label: "loaStudents.table.field.studentName.label"
    },
    {
      field: "loaDate",
      label: "loaStudents.table.field.loaDate.label",
      valueGetter: ({ startDate }) => formatDate(startDate)
    },
    {
      field: "note",
      label: "loaStudents.table.field.reason.label",
      sortable: false
    },
    {
      field: "className",
      label: "loaStudents.table.field.loaClass.label",
      sortable: false
    },
    {
      field: "updateQuantity",
      label: "loaStudents.table.field.loaQuantity.label",
      sortable: false
    }
  ];
  const rowActions = [
    {
      icon: <AddCircleOutlineIcon />,
      tooltip: t("loaStudents.table.action.addToClass.label"),
      action: ({ uuid, studentUuid, studentName, updateQuantity }) => {
        setAddStudent({
          uuid,
          studentName,
          studentUuid,
          sessions: updateQuantity
        });
      }
    },
    {
      icon: <HighlightOffIcon />,
      tooltip: t("classDetails.table.action.disable.label"),
      action: (rowData) => {
        setDisableRow(rowData);
      },
      showIf: () => !isTeacher(userProfile.value)
    }
  ];

  const handleClose = () => {
    setAddStudent(null);
    setDisableRow(null);
  };

  useEffect(() => {
    if (branchInfo.value.uuid) {
      refreshData();
    }
  }, [branchInfo.value, searchCriteria]);

  const refreshData = () => {
    const { studentUuid, loaDate } = searchCriteria;
    get(`${UrlPaths.STUDENTS}/loa`, {
      branchUuid: branchInfo.value.uuid,
      studentUuid,
      loaDate
    })
      .then((res) => {
        setData(res.map((x, index) => ({ id: index + 1, ...x })));
      })
      .catch(console.debug);
  };

  return (
    <Box>
      {/* SEARCH & ACTIONS BAR */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={t("header.title.label")} />
      </Box>
      <Box
        backgroundColor={colors.primary[400]}
        display="flex"
        mb="0.5rem"
        p="0.5rem"
        justifyContent="space-between">
        <Grid container mb="1rem" columnSpacing={1}>
          <Grid item xs={4}>
            <FormControl sx={{ width: "100%", paddingX: "0.5rem" }} size="small">
              <Autocomplete
                placeholder="loaStudents.placeholder.student"
                value={
                  searchCriteria.studentValue || {
                    uuid: "",
                    displayName: getStudentDetailLabel(null)
                  }
                }
                onChange={(e, value) => {
                  setSearchCriteria({
                    ...searchCriteria,
                    studentUuid: value?.uuid,
                    studentValue: value
                  });
                }}
                requestConfig={{
                  url: `${UrlPaths.STUDENTS}?branchUuid=${branchInfo.value.uuid}`,
                  responseField: "results",
                  label: (student) => getStudentDetailLabel(student),
                  value: "uuid",
                  onQueryChange: (value) => (value ? `&any=${value}` : "")
                }}></Autocomplete>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                id="start-date"
                label={t("loaStudents.placeholder.loaDate")}
                format={DEFAULT_DATE_FORMAT}
                sx={{ width: "100%", paddingX: "0.5rem" }}
                value={searchCriteria.startDate}
                onChange={(e) => setSearchCriteria({ ...searchCriteria, loaDate: e })}
                slotProps={{
                  textField: { size: "small" },
                  popper: {
                    style: {
                      zIndex: 15003
                    }
                  }
                }}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
      </Box>
      <Box
        backgroundColor={colors.primary[400]}
        display="flex"
        mb="0.5rem"
        p="0.5rem"
        justifyContent="space-between">
        <Table columnConfig={columns} data={data} rowActions={rowActions} />
      </Box>
      <Dialog
        open={!!disableRow}
        data={disableRow}
        onClose={handleClose}
        onConfirm={() => {
          post(`${UrlPaths.STUDENTS}/${disableRow.studentUuid}/schedule/disable`, {
            note: disableNote,
            disabledDate
          })
            .then(() => {
              refreshData();
              handleClose();
            })
            .catch(console.debug);
        }}
        buttons={[
          {
            type: "discard",
            id: "cancel",
            label: "common.button.cancel",
            variant: "outlined"
          },
          {
            type: "confirm",
            id: "confirm",
            label: "common.button.confirm",
            color: "error"
          }
        ]}
        headerBgColor="#d32f2f"
        headerTitleColor="white"
        title={t("classDetails.dialog.disableStudent.title")}>
        <Typography variant="h5" color={colors.grey[100]} sx={{ m: "0 0 5px 0" }}>
          <div
            style={{ marginBottom: "1rem" }}
            dangerouslySetInnerHTML={{
              __html: t("classDetails.dialog.student.confirmTitle", {
                studentName: disableRow?.studentName
              })
            }}></div>
          <div
            dangerouslySetInnerHTML={{
              __html: t("classDetails.dialog.disableStudent.description")
            }}></div>
        </Typography>
        <TextField
          margin="dense"
          id="name"
          label={t("addStudent.placeholder.note")}
          value={disableNote || ""}
          onChange={(e) => setDisableNote(e.target.value)}
          fullWidth
          size="small"
          variant="outlined"
        />
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            id="disabled-date"
            label={t("classDetails.placeholder.disabledDate")}
            format={DEFAULT_DATE_FORMAT}
            value={disabledDate}
            sx={{ width: "100%", marginTop: "1rem" }}
            onChange={(e) => {
              if (e?.isValid()) {
                setDisabledDate(e);
              }
            }}
            slotProps={{
              textField: { size: "small" },
              popper: {
                style: {
                  zIndex: 15003
                }
              }
            }}
          />
        </LocalizationProvider>
      </Dialog>
      <AddStudentToClassDialog
        open={!!addStudent}
        data={addStudent}
        onClose={handleClose}
        onConfirm={(res) => {
          const { uuid, studentUuid, startDate, sessions, classValue, note } = res;
          const classScheduleUuids = classValue.schedule.map(({ uuid }) => uuid);
          const request = {
            classScheduleUuids,
            studentUuid,
            startDate: startDate.toDate(),
            numberOfSessions: Number(sessions),
            note: note,
            loaHistoryUuid: uuid,
            status: "STUDENT_LEARNING",
            reschedule: true
          };
          post(`${UrlPaths.CLASSES}/${classValue.uuid}/schedule/students`, request)
            .then(() => {
              refreshData();
              handleClose();
            })
            .catch(console.debug);
        }}
        buttons={DialogButtons.CANCEL_SAVE}
        title={t("loaStudents.dialog.addStudent.title", {
          studentName: addStudent?.studentName
        })}
      />
    </Box>
  );
};

export default LOAStudents;

import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import GroupsIcon from "@mui/icons-material/Groups";
import { Box, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import BarChart from "../../components/bar/BarChart";
import Header from "../../components/header";
import LineChart from "../../components/line/LineChart";
import StatBox from "../../components/progress/StatBox";
import { tokens } from "../../theme";
import { ResponsivePie } from "@nivo/pie";

const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();

  return (
    <Box>
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={t("header.title.label")} />
      </Box>

      {/* GRID & CHARTS */}
      <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gridAutoRows="140px" gap="20px">
        {/* ROW 1 */}
        <Box
          gridColumn="span 6"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center">
          <StatBox
            title="361"
            subtitle="Học sinh mới"
            progress="0.75"
            increase="+14%"
            icon={<GroupsIcon sx={{ color: colors.greenAccent[600], fontSize: "26px" }} />}
          />
        </Box>
        <Box
          gridColumn="span 6"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center">
          <StatBox
            title="431,225"
            subtitle="Doanh thu"
            progress="0.63"
            increase="+21%"
            icon={
              <CurrencyExchangeIcon sx={{ color: colors.greenAccent[600], fontSize: "26px" }} />
            }
          />
        </Box>

        {/* ROW 2 */}
        <Box gridColumn="span 5" gridRow="span 4" backgroundColor={colors.primary[400]}>
          <Typography variant="h5" fontWeight="600" sx={{ padding: "30px 30px 0 30px" }}>
            Thống kê học sinh
          </Typography>
          <Box height="45vh" mt="-20px">
            <BarChart isDashboard={true} />
          </Box>
        </Box>
        <Box
          gridColumn="span 7"
          gridRow="span 4"
          backgroundColor={colors.primary[400]}
          overflow="auto">
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center">
            <Box>
              <Typography variant="h5" fontWeight="600" color={colors.grey[100]}>
                Thống kê doanh thu
              </Typography>
              <Typography variant="h3" fontWeight="bold" color={colors.greenAccent[500]}>
                $59,342.32
              </Typography>
            </Box>
          </Box>
          <Box height="40vh" m="-20px 0 0 0">
            {/* <ResponsivePie
              data={income[0].statistics.map(({ type, totalAmount }) => ({
                id: t(`financeReport.field.${type}.label`),
                label: t(`financeReport.field.${type}.label`),
                value: ((totalAmount / getAllAmount(data[0])) * 100).toFixed(2),
                color: PIE_COLORS.find((x) => x.type === type).color
              }))}
              theme={{
                axis: {
                  domain: {
                    line: {
                      stroke: colors.grey[100]
                    }
                  },
                  legend: {
                    text: {
                      fill: colors.grey[100]
                    }
                  },
                  ticks: {
                    line: {
                      stroke: colors.grey[100],
                      strokeWidth: 1
                    },
                    text: {
                      fill: colors.grey[100]
                    }
                  }
                },
                legends: {
                  text: {
                    fill: colors.grey[100]
                  }
                }
              }}
              margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
              innerRadius={0.5}
              padAngle={0.7}
              cornerRadius={3}
              activeOuterRadiusOffset={8}
              borderColor={{
                from: "color",
                modifiers: [["darker", 0.2]]
              }}
              arcLinkLabelsSkipAngle={10}
              arcLinkLabelsTextColor={colors.grey[100]}
              arcLinkLabelsThickness={2}
              arcLinkLabelsColor={{ from: "color" }}
              enableArcLabels={false}
              arcLabelsRadiusOffset={0.4}
              arcLabelsSkipAngle={7}
              arcLabelsTextColor={{
                from: "color",
                modifiers: [["darker", 2]]
              }}
              defs={[
                {
                  id: "dots",
                  type: "patternDots",
                  background: "inherit",
                  color: "rgba(255, 255, 255, 0.3)",
                  size: 4,
                  padding: 1,
                  stagger: true
                },
                {
                  id: "lines",
                  type: "patternLines",
                  background: "inherit",
                  color: "rgba(255, 255, 255, 0.3)",
                  rotation: -45,
                  lineWidth: 6,
                  spacing: 10
                }
              ]}
              legends={[
                {
                  anchor: "bottom",
                  direction: "row",
                  justify: false,
                  translateX: 0,
                  translateY: 56,
                  itemsSpacing: 0,
                  itemWidth: 100,
                  itemHeight: 18,
                  itemTextColor: "#999",
                  itemDirection: "left-to-right",
                  itemOpacity: 1,
                  symbolSize: 18,
                  symbolShape: "circle",
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemTextColor: "#000"
                      }
                    }
                  ]
                }
              ]}
            /> */}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;

import { Box, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import Header from "../../../components/header";
import { tokens } from "../../../theme";
import ActualFinanceReports from "./ActualFinanceReports";
import ClassFinancialReportDetails from "./ClassFinancialReportDetails";
import FinanceReportDetails from "./FinanceReportDetails";
import FinancialSummary from "./FinancialSummary";

const FinanceReports = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();

  return (
    <Box>
      {/* SEARCH & ACTIONS BAR */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={t("header.title.label")} />
      </Box>
      <Box backgroundColor={colors.primary[400]} mb="0.5rem" p="0.5rem">
        <FinanceReportDetails defaultExpanded={true} />
        <ClassFinancialReportDetails defaultExpanded={true} />
        <ActualFinanceReports defaultExpanded={true} />
        <FinancialSummary defaultExpanded={true} />
      </Box>
    </Box>
  );
};

export default FinanceReports;

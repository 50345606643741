export const COURSE_LIST = [
  {
    label: "",
    value: undefined
  },
  {
    label: "timetable.course.cambridge.label",
    value: "CAMBRIDGE"
  },
  {
    label: "timetable.course.online.label",
    value: "ONLINE"
  },
  {
    label: "timetable.course.ielts.label",
    value: "IELTS"
  }
];

export const WEEK_DAYS = [
  {
    label: "timetable.weekDay.allDays.label",
    value: ""
  },
  {
    label: "timetable.weekDay.mon.label",
    value: "MONDAY"
  },
  {
    label: "timetable.weekDay.tue.label",
    value: "TUESDAY"
  },
  {
    label: "timetable.weekDay.wed.label",
    value: "WEDNESDAY"
  },
  {
    label: "timetable.weekDay.thu.label",
    value: "THURSDAY"
  },
  {
    label: "timetable.weekDay.fri.label",
    value: "FRIDAY"
  },
  {
    label: "timetable.weekDay.sat.label",
    value: "SATURDAY"
  },
  {
    label: "timetable.weekDay.sun.label",
    value: "SUNDAY"
  }
];

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Typography,
  useTheme
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Dialog, { DialogButtons } from "../../../components/dialog";
import Table from "../../../components/table";
import UrlPaths from "../../../constants/UrlPaths";
import { getWithoutLoading } from "../../../services/HttpClient";
import { branchInfo, renewInvoice } from "../../../signals";
import { tokens } from "../../../theme";
import { toVNDCurrency } from "../../../utils/CurencyUtil";

const getSortedData = (data, field, sortBy) => {
  const sorted = [...data];
  sorted.sort((a, b) => {
    const aDiff = a[field] || 0;
    const bDiff = b[field] || 0;
    return sortBy === "HIGH_TO_LOW" ? bDiff - aDiff : aDiff - bDiff;
  });
  return sorted.map((x, index) => ({ ...x, id: index + 1 }));
};

const ClassFinancialReportDetails = ({ defaultExpanded }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();

  const [data, setData] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [classReportDetails, setClassReportDetails] = useState([]);
  const [selectedClassName, setSelectedClassName] = useState("");

  const columns = [
    {
      field: "id",
      label: "classFinancialReports.table.field.id.label",
      sortable: false
    },
    {
      field: "className",
      label: "classFinancialReports.table.field.className.label",
      component: ({ className, classStudentFinancialDetails }) => (
        <Link
          onClick={() => {
            setSelectedClassName(className);
            setClassReportDetails(
              getSortedData(
                classStudentFinancialDetails.map((x, index) => ({
                  ...x,
                  id: index + 1
                })),
                "sessionAmount",
                "HIGH_TO_LOW"
              )
            );
          }}>
          <Typography sx={{ fontWeight: "bold", color: colors.greenAccent[400] }}>
            {className}
          </Typography>
        </Link>
      )
    },
    {
      field: "totalActiveSessions",
      align: "center",
      label: "classFinancialReports.table.field.totalActiveSessions.label"
    },
    {
      field: "totalSessionAmount",
      label: "classFinancialReports.table.field.totalSessionAmount.label",
      align: "center",
      valueGetter: ({ totalSessionAmount }) => toVNDCurrency(totalSessionAmount)
    }
  ];
  const classReportDetailColumns = [
    {
      field: "id",
      label: "classStudentFinancialReports.table.field.id.label",
      sortable: false
    },
    {
      field: "studentName",
      label: "classStudentFinancialReports.table.field.studentName.label",
      valueGetter: ({ studentValue }) => studentValue.niceName
    },
    {
      field: "activeSessions",
      align: "center",
      label: "classStudentFinancialReports.table.field.activeSessions.label"
    },
    {
      field: "sessionAmount",
      label: "classStudentFinancialReports.table.field.sessionAmount.label",
      align: "center",
      component: ({ sessionAmount, activeInvoiceUuid, studentUuid, studentValue }) =>
        activeInvoiceUuid ? (
          <Typography>{toVNDCurrency(sessionAmount)}</Typography>
        ) : (
          <Typography sx={{ fontStyle: "italic", fontSize: "10px" }}>
            {t("classStudentFinancialReports.noneInvoice.label")}
            <Link
              to="../../finance/payment-contract/new"
              onClick={() => {
                renewInvoice.value = {
                  studentUuid,
                  studentValue,
                  isSettlement: false
                };
              }}>
              <Typography variant="span" sx={{ color: colors.greenAccent[400] }}>
                {"(" + t("classStudentFinancialReports.noneInvoice.new") + ")"}
              </Typography>
            </Link>
          </Typography>
        )
    }
  ];

  useEffect(() => {
    refreshData();
  }, [branchInfo.value]);

  const refreshData = () => {
    if (branchInfo.value.uuid) {
      setFetching(true);
      getWithoutLoading(`${UrlPaths.ADMIN}/statistics/${branchInfo.value.uuid}/financeReports`)
        .then((res) => {
          setData(
            getSortedData(
              res.map((x, index) => ({ ...x, id: index + 1 })),
              "totalSessionAmount",
              "HIGH_TO_LOW"
            )
          );
          setFetching(false);
        })
        .catch((err) => {
          console.debug(err);
          setFetching(false);
        });
    }
  };

  return (
    <>
      <Accordion defaultExpanded={defaultExpanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{
            backgroundColor: colors.greenAccent[600]
          }}>
          <Typography variant="h4" fontWeight="bolder">
            {t("classFinancialReports.details.title")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {fetching ? (
            <>
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
            </>
          ) : (
            <>
              <Box
                backgroundColor={colors.primary[400]}
                display="flex"
                mb="0.5rem"
                p="0.5rem"
                justifyContent="space-between">
                <Grid container mb="1rem" columnSpacing={1}>
                  <Grid item xs={4}>
                    <Typography variant="h5">
                      {t("classFinancialReports.totalAmount.label")}:&nbsp;
                      <b>
                        {toVNDCurrency(
                          data.reduce((totalAmount, { totalSessionAmount }) => {
                            totalAmount += totalSessionAmount;
                            return totalAmount;
                          }, 0)
                        )}
                      </b>
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl size="small">
                      <InputLabel id="class-status-label" color="neutral">
                        {t("financeReports.sortBy.label")}
                      </InputLabel>
                      <Select
                        labelId="class-status-label"
                        id="class-status"
                        defaultValue="HIGH_TO_LOW"
                        label={t("financeReports.sortBy.label")}
                        onChange={(e) => {
                          setData(getSortedData(data, "totalSessionAmount", e.target.value));
                        }}>
                        <MenuItem value="HIGH_TO_LOW">
                          {t("financeReports.sortBy.HIGH_TO_LOW")}
                        </MenuItem>
                        <MenuItem value="LOW_TO_HIGHT">
                          {t("financeReports.sortBy.LOW_TO_HIGHT")}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
              <Box
                backgroundColor={colors.primary[400]}
                display="flex"
                mb="0.5rem"
                p="0.5rem"
                justifyContent="space-between">
                <Table
                  showHeaderToolbar={false}
                  showFooterToolbar={false}
                  columnConfig={columns}
                  data={data}
                />
              </Box>
            </>
          )}
        </AccordionDetails>
      </Accordion>
      <Dialog
        open={classReportDetails.length > 0}
        width="80vw"
        onClose={() => setClassReportDetails([])}
        onConfirm={() => setClassReportDetails([])}
        buttons={DialogButtons.OK}
        title={t("classStudentFinancialReports.details.title", { className: selectedClassName })}>
        <Box>
          <Grid container mb="1rem" columnSpacing={1}>
            <Grid item xs={4}>
              <Typography variant="h5" sx={{ marginY: "1rem" }}>
                {t("classStudentFinancialReports.totalAmount.label")}:&nbsp;
                <b>
                  {toVNDCurrency(
                    classReportDetails.reduce((totalAmount, { sessionAmount }) => {
                      totalAmount += sessionAmount;
                      return totalAmount;
                    }, 0)
                  )}
                </b>
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <FormControl size="small">
                <InputLabel id="class-status-label" color="neutral">
                  {t("financeReports.sortBy.label")}
                </InputLabel>
                <Select
                  labelId="class-status-label"
                  MenuProps={{
                    style: { zIndex: 15002 }
                  }}
                  id="class-status"
                  defaultValue="HIGH_TO_LOW"
                  label={t("financeReports.sortBy.label")}
                  onChange={(e) => {
                    setClassReportDetails(
                      getSortedData(classReportDetails, "sessionAmount", e.target.value)
                    );
                  }}>
                  <MenuItem value="HIGH_TO_LOW">{t("financeReports.sortBy.HIGH_TO_LOW")}</MenuItem>
                  <MenuItem value="LOW_TO_HIGHT">
                    {t("financeReports.sortBy.LOW_TO_HIGHT")}
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        <Table
          showHeaderToolbar={false}
          showFooterToolbar={false}
          columnConfig={classReportDetailColumns}
          data={classReportDetails}
        />
      </Dialog>
    </>
  );
};

export default ClassFinancialReportDetails;

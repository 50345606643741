import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";
import { toVNDCurrency } from "../../../utils/CurencyUtil";
import { DEFAULT_DATE_FORMAT } from "../../../utils/TimeUtil";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

export const NEW_INSTALLMENT = {
  paidAmount: 0,
  ordinal: 0,
  status: "ACTIVE",
  note: ""
};

const PaymentContractSummary = ({
  invoiceUuid,
  setPaymentRequest,
  setInstallments,
  paymentRequest = {},
  installments = []
}) => {
  const { t } = useTranslation();

  return (
    <Box display="flex" flexDirection="column">
      <Grid container mb="1rem" columnSpacing={1}>
        <Grid item xs={6}></Grid>
        <Grid item xs={3} display="flex" alignItems="center">
          <Typography component="label" fontWeight="bold" marginRight="1rem">
            {t("paymentContract.conclusion.totalAmount.label")}:
          </Typography>
        </Grid>
        <Grid item xs={3} display="flex" alignItems="center">
          <Typography>{toVNDCurrency(paymentRequest.totalAmount)}</Typography>
        </Grid>
      </Grid>
      <Grid container mb="1rem" columnSpacing={1}>
        <Grid item xs={6}></Grid>
        <Grid item xs={3} display="flex" alignItems="center">
          <Typography component="label" fontWeight="bold" marginRight="1rem">
            {t("paymentContract.conclusion.otherDiscount.label")}:
          </Typography>
        </Grid>
        <Grid item xs={3} display="flex" alignItems="center">
          <Typography>{toVNDCurrency(paymentRequest.discount)}</Typography>
        </Grid>
      </Grid>
      <Grid container mb="1rem" columnSpacing={1}>
        <Grid item xs={6}></Grid>
        <Grid item xs={3} display="flex" alignItems="center">
          <Typography component="label" fontWeight="bold" marginRight="1rem">
            {t("paymentContract.conclusion.payAmount.label")}:
          </Typography>
        </Grid>
        <Grid item xs={3} display="flex" alignItems="center">
          <Typography>{toVNDCurrency(paymentRequest.payAmount)}</Typography>
        </Grid>
      </Grid>
      <Grid container mb="1rem" columnSpacing={1}>
        <Grid item xs={6}></Grid>
        <Grid item xs={3} display="flex" alignItems="center">
          <Typography component="label" fontWeight="bold" marginRight="1rem">
            {t("paymentContract.conclusion.payAmountLetters.label")}:
          </Typography>
        </Grid>
        <Grid item xs={3} display="flex" alignItems="center">
          <Typography component="i">{paymentRequest.payAmountLetters}</Typography>
        </Grid>
      </Grid>
      <Grid container mb="1rem" columnSpacing={1}>
        <Grid item xs={6}></Grid>
        <Grid item xs={3} display="flex" alignItems="center">
          <Typography component="label" fontWeight="bold" marginRight="1rem">
            {t("paymentContract.conclusion.paymentMethod.label")}:
          </Typography>
        </Grid>
        <Grid item xs={3} display="flex" alignItems="center" sx={{ paddingX: "0 !important" }}>
          <FormControl sx={{ width: "100%", minWidth: "100px" }} size="small">
            <Select
              labelId="class-status-label"
              id="class-status"
              disabled={!!invoiceUuid}
              MenuProps={{
                style: { zIndex: 15003 }
              }}
              value={paymentRequest.paymentMethod}
              onChange={(e) => {
                setPaymentRequest({ ...paymentRequest, paymentMethod: e.target.value });
              }}
              sx={{ width: "100%" }}>
              <MenuItem value="CASH">{t("paymentContract.paymentMeethod.CASH.label")}</MenuItem>
              <MenuItem value="BANK_TRANSFER">
                {t("paymentContract.paymentMeethod.BANK_TRANSFER.label")}
              </MenuItem>
              <MenuItem value="MASTERCARD">
                {t("paymentContract.paymentMeethod.MASTERCARD.label")}
              </MenuItem>
              <MenuItem value="INSTALLMENT">
                {t("paymentContract.paymentMeethod.INSTALLMENT.label")}
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      {paymentRequest.paymentMethod !== "INSTALLMENT" && (
        <Grid container mb="1rem" columnSpacing={1}>
          <Grid item xs={6}></Grid>
          <Grid item xs={3} display="flex" alignItems="center">
            <Typography component="label" fontWeight="bold" marginRight="1rem">
              {t("paymentContract.conclusion.paidAmount.label")}:
            </Typography>
          </Grid>
          <Grid item xs={3} display="flex" alignItems="center" sx={{ paddingX: "0 !important" }}>
            <NumericFormat
              margin="dense"
              value={paymentRequest.paidAmount || ""}
              onChange={(e) => {
                setPaymentRequest({
                  ...paymentRequest,
                  paidAmount: e.target.value,
                  debtAmount:
                    paymentRequest.payAmount -
                    (Number(e.target.value.replaceAll(",", "").replaceAll(" đ", "")) || 0)
                });
              }}
              fullWidth
              size="small"
              variant="outlined"
              sx={{ margin: 0 }}
              customInput={TextField}
              allowLeadingZeros
              thousandSeparator=","
              suffix=" đ"
            />
          </Grid>
        </Grid>
      )}
      {paymentRequest.paymentMethod === "INSTALLMENT" && (
        <>
          {installments.map((installment, index) => (
            <Grid container key={index} mb="1rem" columnSpacing={1}>
              <Grid item xs={6}></Grid>
              <Grid item xs={3} display="flex" alignItems="center">
                {installment.ordinal === 0 && (
                  <Typography component="label" fontWeight="bold" marginRight="1rem">
                    {t("paymentContract.conclusion.paidAmount.label")}:
                  </Typography>
                )}
              </Grid>
              <Grid
                item
                xs={1.5}
                display="flex"
                alignItems="center"
                sx={{ paddingX: "0 !important" }}>
                <NumericFormat
                  margin="dense"
                  value={installment.paidAmount || ""}
                  disabled={installment.status === "COMPLETED"}
                  onChange={(e) => {
                    const updatedList = [...installments];
                    updatedList.splice(index, 1, {
                      ...installment,
                      paidAmount: e.target.value
                    });
                    setInstallments(updatedList);
                  }}
                  fullWidth
                  size="small"
                  variant="outlined"
                  sx={{ margin: 0 }}
                  customInput={TextField}
                  allowLeadingZeros
                  thousandSeparator=","
                  suffix=" đ"
                />
              </Grid>
              <Grid
                item
                xs={1.2}
                display="flex"
                alignItems="center"
                sx={{ paddingX: "0 !important" }}>
                <TextField
                  multiline
                  maxRows={4}
                  disabled={installment.status === "COMPLETED"}
                  value={installment.note || ""}
                  onChange={(e) => {
                    const updatedList = [...installments];
                    updatedList.splice(index, 1, {
                      ...installment,
                      note: e.target.value
                    });
                    setInstallments(updatedList);
                  }}
                  margin="dense"
                  id="name"
                  label={t("paymentContract.installmentAmount.label")}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  size="small"
                  variant="outlined"
                  sx={{ paddingLeft: "0.5rem", margin: 0 }}
                />
              </Grid>
              <Grid
                item
                xs={0.3}
                display="flex"
                alignItems="center"
                sx={{ paddingX: "0 !important" }}>
                {installment.ordinal > 0 && installment.status !== "COMPLETED" && (
                  <IconButton
                    aria-label="delete"
                    onClick={() => {
                      const updatedList = [...installments];
                      updatedList.splice(index, 1);
                      updatedList.forEach((row, i) => (row.ordinal = i));
                      setInstallments(updatedList);
                    }}>
                    <DeleteIcon />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          ))}
          <Grid container mb="1rem" columnSpacing={1}>
            <Grid item xs={6}></Grid>
            <Grid item xs={3} display="flex" alignItems="center"></Grid>
            <Grid
              item
              xs={3}
              sx={{
                textAlign: "end"
              }}>
              <IconButton
                color="success"
                onClick={() => {
                  setInstallments([
                    ...installments,
                    { ...NEW_INSTALLMENT, ordinal: installments.length }
                  ]);
                }}>
                <AddCircleOutlineIcon />
              </IconButton>
            </Grid>
          </Grid>
        </>
      )}
      <Grid container mb="1rem" columnSpacing={1}>
        <Grid item xs={6}></Grid>
        <Grid item xs={3} display="flex" alignItems="center">
          <Typography component="label" fontWeight="bold" marginRight="1rem">
            {t("paymentContract.conclusion.debtAmount.label")}:
          </Typography>
        </Grid>
        <Grid item xs={3} display="flex" alignItems="center">
          <Typography>{toVNDCurrency(paymentRequest.debtAmount)}</Typography>
        </Grid>
      </Grid>
      <Grid container mb="1rem" columnSpacing={1}>
        <Grid item xs={6}></Grid>
        <Grid item xs={3} display="flex" alignItems="center">
          <Typography component="label" fontWeight="bold" marginRight="1rem">
            {paymentRequest.paymentMethod !== "INSTALLMENT"
              ? t("paymentContract.conclusion.paidDate.label")
              : t("paymentContract.conclusion.installmentCreationDate.label")}
            :
          </Typography>
        </Grid>
        <Grid item xs={3} display="flex" alignItems="center" sx={{ paddingX: "0 !important" }}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              id="paid-date"
              format={DEFAULT_DATE_FORMAT}
              sx={{ width: "100%" }}
              value={paymentRequest.paidDate || moment()}
              onChange={(e) => {
                setPaymentRequest({
                  ...paymentRequest,
                  paidDate: e
                });
              }}
              slotProps={{
                textField: { size: "small" },
                popper: {
                  style: {
                    zIndex: 15003
                  }
                }
              }}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PaymentContractSummary;

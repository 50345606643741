import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import {
  Box,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useTheme
} from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Autocomplete from "../../../components/autocomplete";
import Header from "../../../components/header";
import Table from "../../../components/table";
import UrlPaths from "../../../constants/UrlPaths";
import { get, put } from "../../../services/HttpClient";
import { branchInfo, renewInvoice } from "../../../signals";
import { tokens } from "../../../theme";
import { DEFAULT_MONTH_FORMAT, formatDate, getMonthOptions } from "../../../utils/TimeUtil";
import { getStudentDetailLabel } from "../../../utils/UserUtil";

const DEFAULT_SEARCH_CRITERIA = {
  monthYear: moment().month() + "|" + moment().isoWeekYear(),
  month: moment().month() + 1,
  studentUuid: "",
  studentValue: {
    uuid: "",
    displayName: getStudentDetailLabel(null)
  }
};

const DisabledStudents = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [subNote, setSubNote] = useState([]);
  const [data, setData] = useState([]);
  const [searchCriteria, setSearchCriteria] = useState(DEFAULT_SEARCH_CRITERIA);

  const columns = [
    { field: "id", label: "disabledStudents.table.field.id.label" },
    {
      field: "studentName",
      label: "disabledStudents.table.field.studentName.label",
      valueGetter: ({ studentValue }) => studentValue.niceName
    },
    {
      field: "disabledDate",
      label: "disabledStudents.table.field.disabledDate.label",
      valueGetter: ({ disabledDate }) => formatDate(disabledDate)
    },
    {
      field: "note",
      label: "disabledStudents.table.field.reason.label"
    },
    {
      field: "subNote",
      label: "disabledStudents.table.field.subNote.label",
      sortable: false,
      component: ({ id, noteChange, notes, studentUuid, refreshData }) => (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <TextField
            id="outlined-basic"
            value={notes[id - 1]}
            onChange={(e) => {
              noteChange(e, id);
            }}
            multiline
            maxRows={4}
            variant="outlined"
            sx={{ width: "100%" }}
            size="small"
          />
          <IconButton
            variant="contained"
            color="success"
            onClick={() => {
              put(`${UrlPaths.STUDENTS}/${studentUuid}/note`, {
                note: notes[id - 1]
              })
                .then(() => {
                  refreshData();
                })
                .catch(console.debug);
            }}>
            <SaveAsIcon />
          </IconButton>
        </Box>
      )
    }
  ];
  const rowActions = [
    {
      icon: <AddCircleOutlineIcon />,
      tooltip: t("disabledStudents.table.action.addPaymentContract.label"),
      action: ({ studentUuid, studentValue }) => {
        renewInvoice.value = {
          studentUuid,
          studentValue,
          isSettlement: false
        };
        navigate("../../finance/payment-contract/new");
      }
    }
  ];

  useEffect(() => {
    if (branchInfo.value.uuid) {
      refreshData();
    }
  }, [branchInfo.value, searchCriteria]);

  const refreshData = () => {
    const { studentUuid, month } = searchCriteria;
    get(`${UrlPaths.STUDENTS}/disabled`, {
      branchUuid: branchInfo.value.uuid,
      studentUuid,
      month
    })
      .then((res) => {
        setData(res.map((x, index) => ({ id: index + 1, ...x })));
        setSubNote(res.map(({ subNote }) => subNote || ""));
      })
      .catch(console.debug);
  };

  const handleMonthChange = (monthYear) => {
    const targetMonth = getMonthOptions().find(({ month, year }) => {
      return month + "|" + year === monthYear;
    });
    setSearchCriteria({
      ...searchCriteria,
      month: targetMonth.month + 1,
      monthYear
    });
  };

  return (
    <Box>
      {/* SEARCH & ACTIONS BAR */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={t("header.title.label")} />
      </Box>
      <Box
        backgroundColor={colors.primary[400]}
        display="flex"
        mb="0.5rem"
        p="0.5rem"
        justifyContent="space-between">
        <Grid container mb="1rem" columnSpacing={1}>
          <Grid item xs={4}>
            <FormControl sx={{ width: "100%", paddingX: "0.5rem" }} size="small">
              <Autocomplete
                placeholder="disabledStudents.placeholder.student"
                value={
                  searchCriteria.studentValue || {
                    uuid: "",
                    displayName: getStudentDetailLabel(null)
                  }
                }
                onChange={(e, value) => {
                  setSearchCriteria({
                    ...searchCriteria,
                    studentUuid: value?.uuid,
                    studentValue: value
                  });
                }}
                requestConfig={{
                  url: `${UrlPaths.STUDENTS}?branchUuid=${branchInfo.value.uuid}`,
                  responseField: "results",
                  label: (student) => getStudentDetailLabel(student),
                  value: "uuid",
                  onQueryChange: (value) => (value ? `&any=${value}` : "")
                }}></Autocomplete>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl sx={{ width: "100%", paddingX: "0.5rem" }} size="small">
              <InputLabel id="class-status-label" color="neutral">
                {t("disabledStudents.placeholder.monthOptions")}
              </InputLabel>
              <Select
                labelId="class-status-label"
                id="class-status"
                label={t("disabledStudents.placeholder.monthOptions")}
                value={searchCriteria.monthYear}
                onChange={(e) => handleMonthChange(e.target.value, true)}
                MenuProps={{
                  style: { zIndex: 15002 }
                }}
                sx={{ width: "100%" }}>
                {getMonthOptions().map(({ month, year, from, to }, index) => (
                  <MenuItem key={index} value={month + "|" + year}>
                    {t("disabledStudents.monthOptions.label", {
                      month: month + 1,
                      year,
                      startDayOfMonth: from.format(DEFAULT_MONTH_FORMAT),
                      endDayOfMonth: to.format(DEFAULT_MONTH_FORMAT)
                    })}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      <Box
        backgroundColor={colors.primary[400]}
        display="flex"
        mb="0.5rem"
        p="0.5rem"
        justifyContent="space-between">
        <Table
          columnConfig={columns}
          data={data.map((x) => ({
            ...x,
            notes: subNote,
            noteChange: (e, id) => {
              const noteList = [...subNote];
              noteList.splice(id - 1, 1, e.target.value);
              setSubNote(noteList);
            },
            refreshData: refreshData
          }))}
          rowActions={rowActions}
        />
      </Box>
    </Box>
  );
};

export default DisabledStudents;

import { Grid, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Dialog from "../../../components/dialog";
import { DEFAULT_DATE_FORMAT } from "../../../utils/TimeUtil";

const INITIAL_DATA = {
  loaDate: moment(),
  loaSessions: "",
  note: ""
};

const LeaveOfAbsenceDialog = ({ data, onConfirm, ...props }) => {
  const { t } = useTranslation();

  const [loa, setLoa] = useState(INITIAL_DATA);

  useEffect(() => {
    setLoa({ ...data, ...INITIAL_DATA });
  }, [data]);

  const updateFieldValue = (fieldName, fieldValue) => {
    setLoa({ ...loa, [fieldName]: fieldValue });
  };

  const confirmSaveClass = () => {
    onConfirm(loa);
  };

  return (
    <Dialog {...props} onConfirm={confirmSaveClass}>
      <Grid
        container
        flexDirection="column"
        justifyContent="center"
        sx={{
          width: "100%"
        }}
        rowSpacing={4}>
        <Grid item>
          <TextField
            margin="dense"
            id="name"
            label={t("loa.placeholder.studentName")}
            value={loa.studentName || ""}
            disabled
            InputLabelProps={{ shrink: true }}
            onChange={(e) => updateFieldValue("studentName", e.target.value)}
            fullWidth
            size="small"
            variant="outlined"
            sx={{ paddingX: "0.5rem", margin: 0 }}
          />
        </Grid>
        <Grid item>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              id="loa-date"
              format={DEFAULT_DATE_FORMAT}
              label={t("loa.placeholder.loaDate")}
              sx={{ width: "100%", paddingX: "0.5rem" }}
              value={loa.loaDate}
              onChange={(e) => updateFieldValue("loaDate", e)}
              slotProps={{
                textField: { size: "small" },
                popper: {
                  style: {
                    zIndex: 15003
                  }
                }
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item>
          <TextField
            margin="dense"
            id="name"
            label={t("loa.placeholder.sessions")}
            value={loa.loaSessions || ""}
            onChange={(e) => updateFieldValue("loaSessions", e.target.value)}
            fullWidth
            size="small"
            variant="outlined"
            sx={{ paddingX: "0.5rem", margin: 0 }}
          />
        </Grid>
        <Grid item>
          <TextField
            margin="dense"
            id="name"
            label={t("loa.placeholder.note")}
            value={loa.note || ""}
            onChange={(e) => updateFieldValue("note", e.target.value)}
            fullWidth
            size="small"
            variant="outlined"
            sx={{ paddingX: "0.5rem", margin: 0 }}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default LeaveOfAbsenceDialog;

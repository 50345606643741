import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@mui/material";
import Divider from "@mui/material/Divider";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Autocomplete from "../../../components/autocomplete";
import Dialog from "../../../components/dialog";
import { SCHEDULE_TIMES } from "../../../constants/ScheduleTime";
import UrlPaths from "../../../constants/UrlPaths";
import { branchInfo } from "../../../signals";
import { DEFAULT_DATE_FORMAT, getWeekDay } from "../../../utils/TimeUtil";

const INITIAL_DATA = {
  className: "",
  classAgeLevel: "",
  startDate: moment(),
  schedule: [],
  status: "ACTIVE"
};

const TIMESLOT_DELIMITER = "/";

const ClassDetailsDialog = ({ data, editable, onConfirm, onClose, ...props }) => {
  const { t } = useTranslation();

  const [classDetails, setClassDetails] = useState(INITIAL_DATA);
  const [schedule, setSchedule] = useState([]);

  useEffect(() => {
    if (editable) {
      setClassDetails({
        ...data,
        branchUuid: branchInfo.value.uuid,
        branchName: branchInfo.value.branchName,
        status: data.classStatus,
        startDate: moment(new Date(data?.startDate))
      });
      const scheduleList = data.schedule.reduce((results, x) => {
        const weekDay = getWeekDay(x.dayOfWeek);
        const existedIndex = results.findIndex(
          ({ dayOfWeek, timeSlot, classroomUuid }) =>
            Number(weekDay) === Number(getWeekDay(dayOfWeek)) &&
            x.timeSlot === timeSlot &&
            x.classroomUuid === classroomUuid
        );
        if (existedIndex > -1) {
          const existedSchedule = results[existedIndex];
          results.splice(existedIndex, 1, {
            ...existedSchedule,
            dayOfWeek: weekDay,
            startDate: x.startDate
              ? moment(new Date(x.startDate))
              : moment(new Date(data?.startDate))
          });
          return [...results];
        } else {
          return [
            ...results,
            {
              dayOfWeek: weekDay,
              timeSlot: x.timeSlot,
              startDate: x.startDate
                ? moment(new Date(x.startDate))
                : moment(new Date(data?.startDate)),
              classroomUuid: x.classroomUuid,
              classroomType: x.classroomType,
              classroomName: x.classroomName,
              rawValue: weekDay + TIMESLOT_DELIMITER + x.timeSlot,
              teacherUuid: x.teacherUuid,
              teacherValue: { uuid: x.teacherUuid, niceName: x.teacherName },
              teacherTimeSlot: x.teacherTimeSlot,
              teacherStartDate: moment(new Date(x.teacherStartDate)),
              foreignTeacherUuid: x.foreignTeacherUuid,
              foreignTeacherValue: {
                uuid: x.foreignTeacherUuid,
                niceName: x.foreignTeacherName
              },
              foreignTeacherTimeSlot: x.foreignTeacherTimeSlot,
              foreignTeacherStartDate: moment(new Date(x.foreignTeacherStartDate)),
              teachingAssistantUuid: x.teachingAssistantUuid,
              teachingAssistantValue: {
                uuid: x.teachingAssistantUuid,
                niceName: x.teachingAssistantName
              },
              teachingAssistantTimeSlot: x.teachingAssistantTimeSlot,
              teachingAssistantStartDate: moment(new Date(x.teachingAssistantStartDate)),
              reviewerUuid: x.reviewerUuid,
              reviewerValue: { uuid: x.reviewerUuid, niceName: x.reviewerName },
              reviewerStartDate: moment(new Date(x.reviewerStartDate))
            }
          ];
        }
      }, []);
      setSchedule(scheduleList);
    } else {
      setClassDetails(INITIAL_DATA);
      setSchedule([]);
    }
  }, [data, editable]);

  const updateFieldValue = (fieldName, fieldValue) => {
    setClassDetails({ ...classDetails, [fieldName]: fieldValue });
  };

  const confirmSaveClass = () => {
    const formData = {
      ...classDetails,
      branchUuid: branchInfo.value.uuid,
      branchName: branchInfo.value.branchName,
      schedule: schedule.map((x) => ({ ...x, dayOfWeek: x.dayOfWeek - 1 }))
    };
    onConfirm(!editable, formData);
  };

  const resetClass = () => {
    setClassDetails(INITIAL_DATA);
    setSchedule([]);
    onClose();
  };

  const isTimeSlotSelected = (time, weekDay) =>
    schedule.some(
      ({ timeSlot, dayOfWeek }) => timeSlot === time && Number(dayOfWeek) === Number(weekDay)
    );

  const getTimeSlotOptions = (timeSlot) => {
    const result = [];
    if (!timeSlot) {
      return result;
    }

    const getMinutes = (s) => s.slice(0, 2) * 60 + +s.slice(-2);
    const getTime = (m) => new Date(m * 6e4).toJSON().slice(11, 16);

    const durations = [0.5, 1, 1.5];
    const timeParts = timeSlot.split("-");
    const startTime = getMinutes(timeParts[0]);
    const endTime = getMinutes(timeParts[1]);

    durations.forEach((d) => {
      const timeSlots = [];
      const duration = d * 60;
      for (var m = startTime; m <= endTime - duration; m += duration) {
        timeSlots.push(getTime(m) + "-" + getTime(m + duration));
      }
      result.push({ duration: d, timeSlots });
    });
    return result;
  };

  return (
    <Dialog {...props} onConfirm={confirmSaveClass} onClose={resetClass} width="1000px">
      <Grid
        container
        p="1rem"
        flexDirection="column"
        justifyContent="center"
        sx={{
          width: "100%"
        }}
        rowSpacing={4}>
        <Grid item>
          <FormControl sx={{ width: "100%", paddingX: "0.5rem" }} size="small">
            <Autocomplete
              onChange={(e, value) => {
                setClassDetails({
                  ...classDetails,
                  branchUuid: branchInfo.value.uuid || "",
                  branchName: branchInfo.value.branchName || ""
                });
              }}
              disabled
              value={{
                uuid: branchInfo.value.uuid,
                branchName: branchInfo.value.branchName
              }}
              placeholder="classDetails.placeholder.branchName"
              requestConfig={{
                url: `${UrlPaths.ADMIN}/branches`,
                label: "branchName",
                value: "uuid"
              }}></Autocomplete>
          </FormControl>
        </Grid>
        <Grid item>
          <TextField
            margin="dense"
            id="name"
            label={t("classDetails.placeholder.className")}
            value={classDetails.className}
            onChange={(e) => updateFieldValue("className", e.target.value)}
            fullWidth
            size="small"
            variant="outlined"
            sx={{ paddingX: "0.5rem" }}
          />
        </Grid>
        <Grid item>
          <TextField
            margin="dense"
            id="name"
            label={t("classDetails.placeholder.classAgeLevel")}
            value={classDetails.classAgeLevel || ""}
            onChange={(e) => updateFieldValue("classAgeLevel", e.target.value)}
            fullWidth
            size="small"
            variant="outlined"
            sx={{ paddingX: "0.5rem" }}
          />
        </Grid>
        <Grid item>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              id="start-date"
              format={DEFAULT_DATE_FORMAT}
              label={t("classDetails.placeholder.startDate")}
              sx={{ width: "100%", paddingX: "0.5rem" }}
              value={classDetails.startDate}
              onChange={(e) => updateFieldValue("startDate", e)}
              slotProps={{
                textField: { size: "small" },
                popper: {
                  style: {
                    zIndex: 15003
                  }
                }
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item>
          <FormControl sx={{ width: "100%", paddingX: "0.5rem" }} size="small">
            <Autocomplete
              placeholder="classDetails.placeholder.courseName"
              onChange={(e, value) => {
                setClassDetails({
                  ...classDetails,
                  courseUuid: value.uuid || "",
                  courseName: value.courseName || ""
                });
              }}
              value={{
                uuid: classDetails.courseUuid,
                courseName: classDetails.courseName
              }}
              requestConfig={{
                url: `${UrlPaths.ADMIN}/courses?branchUuid=${branchInfo.value.uuid}`,
                label: "courseName",
                value: "uuid"
              }}></Autocomplete>
          </FormControl>
        </Grid>
        <Grid item sx={{ paddingX: "0.5rem" }}>
          <Typography component="label">{t("classDetails.placeholder.schedule")}</Typography>
          <IconButton color="success" onClick={() => setSchedule([...schedule, {}])}>
            <AddCircleOutlineIcon />
          </IconButton>
        </Grid>
        {schedule.map((scheduleItem, index) => (
          <Box key={index}>
            {/* SCHEDULE SECTION */}
            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "center",
                paddingTop: "5px !important",
                width: "100%"
              }}>
              <FormControl
                sx={{ width: "100%", maxWidth: "300px", marginY: "0.5rem", paddingX: "0.5rem" }}
                size="small">
                <InputLabel id="class-status-label" color="neutral">
                  {t("classDetails.placeholder.schedule")}
                </InputLabel>
                <Select
                  labelId="class-status-label"
                  id="class-status"
                  label={t("classDetails.placeholder.schedule")}
                  MenuProps={{
                    style: { zIndex: 15003 }
                  }}
                  value={scheduleItem.rawValue || ""}
                  onChange={(e) => {
                    const [dayOfWeek, timeSlot] = e.target.value.split(TIMESLOT_DELIMITER);
                    const scheduleList = [...schedule];
                    scheduleList.splice(index, 1, {
                      ...scheduleItem,
                      dayOfWeek,
                      timeSlot,
                      rawValue: e.target.value
                    });
                    setSchedule(scheduleList);
                  }}
                  sx={{ width: "100%" }}>
                  {SCHEDULE_TIMES.reduce((items, x) => {
                    items.push(
                      <ListSubheader key={x.weekDayLabel}>
                        <Typography component="label" fontWeight="bold" fontSize="16px">
                          {t(x.weekDayLabel)}
                        </Typography>
                      </ListSubheader>
                    );
                    items.push(
                      x.timeSlots.map((time, index) => (
                        <MenuItem
                          disabled={isTimeSlotSelected(time, x.weekDay)}
                          key={x.weekDay + time}
                          value={x.weekDay + TIMESLOT_DELIMITER + time}>
                          {time}&nbsp;{t(x.weekDayLabel)}
                        </MenuItem>
                      ))
                    );
                    return items;
                  }, [])}
                </Select>
              </FormControl>
              <FormControl
                sx={{ width: "100%", maxWidth: "300px", marginY: "0.5rem", paddingX: "0.5rem" }}
                size="small">
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    id="schedule-start-date"
                    format={DEFAULT_DATE_FORMAT}
                    minDate={classDetails.startDate}
                    label={t("classDetails.placeholder.startDate")}
                    sx={{ width: "100%", paddingX: "0.5rem" }}
                    value={scheduleItem.startDate || classDetails.startDate}
                    onChange={(e) => {
                      const scheduleList = [...schedule];
                      scheduleList.splice(index, 1, {
                        ...scheduleItem,
                        startDate: e || classDetails.startDate
                      });
                      setSchedule(scheduleList);
                    }}
                    slotProps={{
                      textField: { size: "small" },
                      popper: {
                        style: {
                          zIndex: 15003
                        }
                      }
                    }}
                  />
                </LocalizationProvider>
              </FormControl>
              <FormControl
                sx={{ width: "100%", maxWidth: "300px", padding: "0.5rem" }}
                size="small">
                <Autocomplete
                  value={{
                    uuid: scheduleItem.classroomUuid,
                    classroomName: scheduleItem.classroomName
                  }}
                  placeholder="classDetails.placeholder.classroomName"
                  onChange={(e, value) => {
                    if (value) {
                      const { uuid, classroomType, classroomName } = value;
                      const scheduleList = [...schedule];
                      scheduleList.splice(index, 1, {
                        ...scheduleItem,
                        classroomUuid: uuid,
                        classroomType,
                        classroomName
                      });
                      setSchedule(scheduleList);
                    }
                  }}
                  requestConfig={{
                    url: `${UrlPaths.ADMIN}/classrooms?branchUuid=${branchInfo.value.uuid}&status=ACTIVE`,
                    label: "classroomName",
                    value: "uuid"
                  }}></Autocomplete>
              </FormControl>
              <IconButton
                color="error"
                onClick={() => {
                  const scheduleList = [...schedule];
                  scheduleList.splice(index, 1);
                  setSchedule(scheduleList);
                }}>
                <DeleteIcon />
              </IconButton>
            </Grid>
            {/* NATIVE TEACHER SECTION */}
            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "center",
                paddingTop: "5px !important",
                width: "100%"
              }}>
              <FormControl
                sx={{ width: "100%", maxWidth: "300px", marginY: "0.5rem", paddingX: "0.5rem" }}
                size="small">
                <InputLabel id="class-status-label" color="neutral">
                  {t("class.placeholder.schedule.timeSlot")}
                </InputLabel>
                <Select
                  labelId="class-status-label"
                  id="class-status"
                  label={t("class.placeholder.schedule.timeSlot")}
                  MenuProps={{
                    style: { zIndex: 15003 }
                  }}
                  value={scheduleItem.teacherTimeSlot || ""}
                  onChange={(e) => {
                    const timeSlot = e.target.value;
                    const scheduleList = [...schedule];
                    scheduleList.splice(index, 1, {
                      ...scheduleItem,
                      teacherTimeSlot: timeSlot
                    });
                    setSchedule(scheduleList);
                  }}
                  sx={{ width: "100%" }}>
                  <MenuItem sx={{ display: "hidden" }} key={"00"} value={""}></MenuItem>
                  {getTimeSlotOptions(scheduleItem.timeSlot).reduce((items, x) => {
                    items.push(
                      <ListSubheader key={x.duration}>
                        <Typography component="label" fontWeight="bold" fontSize="16px">
                          {x.duration + "h"}
                        </Typography>
                      </ListSubheader>
                    );
                    items.push(
                      x.timeSlots.map((time) => (
                        <MenuItem key={x.duration + time} value={time}>
                          {time}
                        </MenuItem>
                      ))
                    );
                    return items;
                  }, [])}
                </Select>
              </FormControl>
              <Grid item xs={3} sx={{ marginY: "0.5rem", paddingX: "0.5rem" }}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    id="start-date"
                    format={DEFAULT_DATE_FORMAT}
                    minDate={classDetails.startDate}
                    label={t("classDetails.placeholder.teacherStartingDate")}
                    value={scheduleItem.teacherStartDate || classDetails.startDate}
                    onChange={(e) => {
                      const scheduleList = [...schedule];
                      scheduleList.splice(index, 1, {
                        ...scheduleItem,
                        teacherStartDate: e
                      });
                      setSchedule(scheduleList);
                    }}
                    sx={{ width: "100%", paddingX: "0.5rem" }}
                    slotProps={{
                      textField: { size: "small" },
                      popper: {
                        style: {
                          zIndex: 15003
                        }
                      }
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={4}>
                <FormControl sx={{ width: "100%", padding: "0.5rem" }} size="small">
                  <Autocomplete
                    value={scheduleItem.teacherValue || {}}
                    placeholder="classDetails.placeholder.nativeTeacherName"
                    onChange={(e, value) => {
                      const scheduleList = [...schedule];
                      scheduleList.splice(index, 1, {
                        ...scheduleItem,
                        teacherUuid: value?.uuid || "",
                        teacherValue: value || {}
                      });
                      setSchedule(scheduleList);
                    }}
                    requestConfig={{
                      url: `${UrlPaths.TEACHERS}?status=ACTIVE&branchUuid=${branchInfo.value.uuid}&orderBy=displayName&order=asc`,
                      label: "niceName",
                      value: "uuid",
                      responseField: "results"
                    }}></Autocomplete>
                </FormControl>
              </Grid>
            </Grid>
            {/* FOREIGN TEACHER SECTION */}
            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "center",
                paddingTop: "5px !important",
                width: "100%"
              }}>
              <FormControl
                sx={{ width: "100%", maxWidth: "300px", marginY: "0.5rem", paddingX: "0.5rem" }}
                size="small">
                <InputLabel id="class-status-label" color="neutral">
                  {t("class.placeholder.schedule.timeSlot")}
                </InputLabel>
                <Select
                  labelId="class-status-label"
                  id="class-status"
                  label={t("class.placeholder.schedule.timeSlot")}
                  MenuProps={{
                    style: { zIndex: 15003 }
                  }}
                  value={scheduleItem.foreignTeacherTimeSlot || ""}
                  onChange={(e) => {
                    const timeSlot = e.target.value;
                    const scheduleList = [...schedule];
                    scheduleList.splice(index, 1, {
                      ...scheduleItem,
                      foreignTeacherTimeSlot: timeSlot
                    });
                    setSchedule(scheduleList);
                  }}
                  sx={{ width: "100%" }}>
                  <MenuItem sx={{ display: "hidden" }} key={"00"} value={""}></MenuItem>
                  {getTimeSlotOptions(scheduleItem.timeSlot).reduce((items, x) => {
                    items.push(
                      <ListSubheader key={x.duration}>
                        <Typography component="label" fontWeight="bold" fontSize="16px">
                          {x.duration + "h"}
                        </Typography>
                      </ListSubheader>
                    );
                    items.push(
                      x.timeSlots.map((time) => (
                        <MenuItem key={x.duration + time} value={time}>
                          {time}
                        </MenuItem>
                      ))
                    );
                    return items;
                  }, [])}
                </Select>
              </FormControl>
              <Grid item xs={3} sx={{ marginY: "0.5rem", paddingX: "0.5rem" }}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    id="start-date"
                    format={DEFAULT_DATE_FORMAT}
                    minDate={classDetails.startDate}
                    label={t("classDetails.placeholder.teacherStartingDate")}
                    value={scheduleItem.foreignTeacherStartDate || classDetails.startDate}
                    onChange={(e) => {
                      const scheduleList = [...schedule];
                      scheduleList.splice(index, 1, {
                        ...scheduleItem,
                        foreignTeacherStartDate: e
                      });
                      setSchedule(scheduleList);
                    }}
                    sx={{ width: "100%", paddingX: "0.5rem" }}
                    slotProps={{
                      textField: { size: "small" },
                      popper: {
                        style: {
                          zIndex: 15003
                        }
                      }
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={4}>
                <FormControl sx={{ width: "100%", padding: "0.5rem" }} size="small">
                  <Autocomplete
                    value={scheduleItem.foreignTeacherValue || {}}
                    placeholder="classDetails.placeholder.foreignTeacherName"
                    onChange={(e, value) => {
                      const scheduleList = [...schedule];
                      scheduleList.splice(index, 1, {
                        ...scheduleItem,
                        foreignTeacherUuid: value?.uuid || "",
                        foreignTeacherValue: value || {}
                      });
                      setSchedule(scheduleList);
                    }}
                    requestConfig={{
                      url: `${UrlPaths.TEACHERS}?status=ACTIVE&branchUuid=${branchInfo.value.uuid}&role=T_FOREIGNER&orderBy=displayName&order=asc`,
                      label: "niceName",
                      value: "uuid",
                      responseField: "results"
                    }}></Autocomplete>
                </FormControl>
              </Grid>
            </Grid>
            {/* TEACHING ASSISTANT SECTION */}
            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "center",
                paddingTop: "5px !important",
                width: "100%"
              }}>
              <FormControl
                sx={{ width: "100%", maxWidth: "300px", marginY: "0.5rem", paddingX: "0.5rem" }}
                size="small">
                <InputLabel id="class-status-label" color="neutral">
                  {t("class.placeholder.schedule.timeSlot")}
                </InputLabel>
                <Select
                  labelId="class-status-label"
                  id="class-status"
                  label={t("class.placeholder.schedule.timeSlot")}
                  MenuProps={{
                    style: { zIndex: 15003 }
                  }}
                  value={scheduleItem.teachingAssistantTimeSlot || ""}
                  onChange={(e) => {
                    const timeSlot = e.target.value;
                    const scheduleList = [...schedule];
                    scheduleList.splice(index, 1, {
                      ...scheduleItem,
                      teachingAssistantTimeSlot: timeSlot
                    });
                    setSchedule(scheduleList);
                  }}
                  sx={{ width: "100%" }}>
                  <MenuItem sx={{ display: "none" }} key={"00"} value={""}></MenuItem>
                  {getTimeSlotOptions(scheduleItem.timeSlot).reduce((items, x) => {
                    items.push(
                      <ListSubheader key={x.duration}>
                        <Typography component="label" fontWeight="bold" fontSize="16px">
                          {x.duration + "h"}
                        </Typography>
                      </ListSubheader>
                    );
                    items.push(
                      x.timeSlots.map((time) => (
                        <MenuItem key={x.duration + time} value={time}>
                          {time}
                        </MenuItem>
                      ))
                    );
                    return items;
                  }, [])}
                </Select>
              </FormControl>
              <Grid item xs={3} sx={{ marginY: "0.5rem", paddingX: "0.5rem" }}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    id="start-date"
                    format={DEFAULT_DATE_FORMAT}
                    label={t("classDetails.placeholder.teacherStartingDate")}
                    minDate={classDetails.startDate}
                    value={scheduleItem.teachingAssistantStartDate || classDetails.startDate}
                    onChange={(e) => {
                      const scheduleList = [...schedule];
                      scheduleList.splice(index, 1, {
                        ...scheduleItem,
                        teachingAssistantStartDate: e
                      });
                      setSchedule(scheduleList);
                    }}
                    sx={{ width: "100%", paddingX: "0.5rem" }}
                    slotProps={{
                      textField: { size: "small" },
                      popper: {
                        style: {
                          zIndex: 15003
                        }
                      }
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={4}>
                <FormControl sx={{ width: "100%", padding: "0.5rem" }} size="small">
                  <Autocomplete
                    value={scheduleItem.teachingAssistantValue || {}}
                    placeholder="classDetails.placeholder.teachingAssistantName"
                    onChange={(e, value) => {
                      const scheduleList = [...schedule];
                      scheduleList.splice(index, 1, {
                        ...scheduleItem,
                        teachingAssistantUuid: value?.uuid || "",
                        teachingAssistantValue: value || {}
                      });
                      setSchedule(scheduleList);
                    }}
                    requestConfig={{
                      url: `${UrlPaths.TEACHERS}?status=ACTIVE&branchUuid=${branchInfo.value.uuid}&role=T_ASSISTANT&orderBy=displayName&order=asc`,
                      label: "niceName",
                      value: "uuid",
                      responseField: "results"
                    }}></Autocomplete>
                </FormControl>
              </Grid>
            </Grid>
            {/* REVIEWER SECTION */}
            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "center",
                paddingTop: "5px !important",
                width: "100%"
              }}>
              <FormControl
                sx={{
                  display: "hidden",
                  width: "100%",
                  maxWidth: "300px",
                  marginY: "0.5rem",
                  paddingX: "0.5rem"
                }}></FormControl>
              <Grid item xs={3} sx={{ marginY: "0.5rem", paddingX: "1rem" }}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    id="start-date"
                    format={DEFAULT_DATE_FORMAT}
                    label={t("classDetails.placeholder.teacherStartingDate")}
                    minDate={classDetails.startDate}
                    value={scheduleItem.reviewerStartDate || classDetails.startDate}
                    onChange={(e) => {
                      const scheduleList = [...schedule];
                      scheduleList.splice(index, 1, {
                        ...scheduleItem,
                        reviewerStartDate: e
                      });
                      setSchedule(scheduleList);
                    }}
                    slotProps={{
                      textField: { size: "small" },
                      popper: {
                        style: {
                          zIndex: 15003
                        }
                      }
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={4}>
                <FormControl sx={{ width: "100%", padding: "0.5rem" }} size="small">
                  <Autocomplete
                    value={scheduleItem.reviewerValue || {}}
                    placeholder="classDetails.placeholder.reviewerName"
                    onChange={(e, value) => {
                      const scheduleList = [...schedule];
                      scheduleList.splice(index, 1, {
                        ...scheduleItem,
                        reviewerUuid: value?.uuid || "",
                        reviewerValue: value || {}
                      });
                      setSchedule(scheduleList);
                    }}
                    requestConfig={{
                      url: `${UrlPaths.TEACHERS}?status=ACTIVE&branchUuid=${branchInfo.value.uuid}&orderBy=displayName&order=asc`,
                      label: "niceName",
                      value: "uuid",
                      responseField: "results"
                    }}></Autocomplete>
                </FormControl>
              </Grid>
            </Grid>
            <Divider sx={{ paddingBottom: "0.5rem" }} />
          </Box>
        ))}
        <Grid item>
          <FormControl sx={{ width: "100%", paddingX: "0.5rem" }} size="small">
            <InputLabel id="class-status-label" color="neutral">
              {t("classDetails.placeholder.status")}
            </InputLabel>
            <Select
              labelId="class-status-label"
              id="class-status"
              label={t("classDetails.placeholder.status")}
              MenuProps={{
                style: { zIndex: 15003 }
              }}
              value={classDetails.status}
              onChange={(e) => updateFieldValue("status", e.target.value)}
              sx={{ width: "100%" }}>
              <MenuItem value="ACTIVE">{t("class.status.ACTIVE.label")}</MenuItem>
              <MenuItem value="ON_HOLD">{t("class.status.ON_HOLD.label")}</MenuItem>
              <MenuItem value="INACTIVE">{t("class.status.INACTIVE.label")}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default ClassDetailsDialog;

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme
} from "@mui/material";
import moment from "moment";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import TableCellStyled from "./TableCellStyled";
import { tokens } from "../../theme";
import { SCHEDULE_TIMES } from "../../constants/ScheduleTime";
import { getTimeSlots } from "../../utils/TimeUtil";

const DayView = ({ rows, date }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const slotTimesLookup =
    useMemo(() => {
      const dayOfWeek = moment(date).isoWeekday();
      const schedule = SCHEDULE_TIMES.find((schedule) => schedule.weekDay === dayOfWeek);
      return getTimeSlots(schedule?.timeSlots || []);
    }, [date]) || [];
  const columns =
    useMemo(() => {
      const tmpColumns = [];
      tmpColumns.push({ field: "class", headerName: t("timetable.table.field.class.label") });
      for (let i = 0; i < slotTimesLookup.length; i++) {
        tmpColumns.push({ field: i.toString(), headerName: slotTimesLookup[i] });
      }
      return tmpColumns;
    }, [slotTimesLookup]) || [];

  const day = useMemo(() => {
    return rows.find(
      (row) => row.dayOfWeek.toLowerCase() === moment(date).format("dddd").toLowerCase()
    );
  }, [rows, date]);

  const [columnWidth, setColumnWidth] = useState(0);
  const tableRef = useRef(null);
  useEffect(() => {
    if (tableRef.current) {
      const tableWidth = tableRef.current.offsetWidth;
      setColumnWidth(tableWidth / columns.length);
    }
  }, [tableRef, columns]);
  const renderEventContent = (event) => {
    return (
      <Typography aria-describedby="test" variant="contained">
        <span>{t("timetable.classroomName.label")}: </span>
        <b>{event?.classroomName}</b>
        <br />
        <span>{t("timetable.teacherName.label")}: </span>
        <b>
          {event?.teacherName} <i>{event?.teacherTimeSlot}</i>
        </b>
        <br />

        <span>{t("timetable.foreignTeacherName.label")}: </span>
        <b>
          {event?.foreignTeacherName} <i>{event?.foreignTeacherTimeSlot}</i>
        </b>
        <br />

        <span>{t("timetable.teachingAssistantName.label")}: </span>
        <b>
          {event?.teachingAssistantName} <i>{event?.teachingAssistantTimeSlot}</i>
        </b>
      </Typography>
    );
  };
  const findTimeSlot = (time) => {
    const timeLookup = moment(time).format("HH:mm");
    return slotTimesLookup.findIndex((slot) => slot === timeLookup);
  };
  return (
    <Table>
      <TableHead>
        <TableRow>
          {columns.map((column) => (
            <TableCellStyled
              key={column.field}
              colSpan={column.colSpan}
              sx={{ background: colors.primary[900], width: columnWidth }}>
              <Typography>
                <b>{column.headerName}</b>
              </Typography>
            </TableCellStyled>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {day.classes.length > 0 ? (
          day.classes.map((data) => {
            const startSlot = findTimeSlot(data.start) - 1;
            const endSlot = findTimeSlot(data.end);
            const duration = endSlot - startSlot;
            return (
              <TableRow key={data.classID}>
                <TableCellStyled>
                  <Typography variant="h5" fontWeight="bold">
                    {data.className}
                  </Typography>
                </TableCellStyled>
                <TableCellStyled colSpan={startSlot + 1}></TableCellStyled>

                <TableCell
                  sx={{ background: data.color, border: "1px solid rgba(224, 224, 224, 1)" }}
                  colSpan={duration}>
                  {renderEventContent(data)}
                </TableCell>

                {slotTimesLookup.length - endSlot - 1 !== 0 && (
                  <TableCellStyled colSpan={slotTimesLookup.length - endSlot - 1}></TableCellStyled>
                )}
              </TableRow>
            );
          })
        ) : (
          <TableRow>
            <TableCellStyled colSpan={100}>
              <Typography sx={{ textAlign: "center" }}>
                {t("timetable.table.field.noClass.label")}
              </Typography>
            </TableCellStyled>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default DayView;

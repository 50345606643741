import { useTheme } from "@emotion/react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Autocomplete from "../../../components/autocomplete";
import { Button } from "../../../components/button";
import Header from "../../../components/header";
import UrlPaths from "../../../constants/UrlPaths";
import { get, put } from "../../../services/HttpClient";
import { branchInfo } from "../../../signals";
import { tokens } from "../../../theme";
import { DEFAULT_DATE_FORMAT } from "../../../utils/TimeUtil";
import StudentClassHistories from "./StudentClassHistories";
import StudentInvoices from "./StudentInvoices";
import StudentMakeUpHistories from "./StudentMakeupHistories";

const DEFAULT_PARENT_ID = "Brk-PH-" + new Date().getTime();
const NEW_PARENT_DATA = {
  uuid: "",
  nameAndPhone: "",
  username: DEFAULT_PARENT_ID,
  email: "",
  password: "",
  mobile: ""
};
const INITIAL_DATA = {
  username: "",
  parentUuid: "",
  password: "",
  firstName: "",
  lastName: "",
  displayName: "",
  entranceTestResult: "",
  referralSource: "PARENT",
  gender: "MALE",
  email: "",
  birthday: null,
  note: "",
  status: "STD_NEW"
};

const StudentDetails = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();
  const { studentId } = useParams();

  const [studentDetails, setStudentDetails] = useState(INITIAL_DATA);
  const [parent, setParent] = useState(NEW_PARENT_DATA);
  const [parent2, setParent2] = useState({
    ...NEW_PARENT_DATA,
    username: "Brk-PH-" + new Date().getTime()
  });

  useEffect(() => {
    if (studentId) {
      refreshData();
    }
  }, [studentId]);

  const refreshData = () => {
    get(`${UrlPaths.STUDENTS}/${studentId}`)
      .then((res) => {
        setStudentDetails({
          ...res,
          birthday: res.birthday ? moment(new Date(res.birthday)) : undefined
        });
        setParent(res.parent);
        setParent2(res.parent2);
      })
      .catch(console.debug);
  };

  const updateFieldValue = (fieldName, fieldValue) => {
    setStudentDetails({ ...studentDetails, [fieldName]: fieldValue });
  };

  const getParentNameAndPhone = (name, phone) => {
    if (name && phone !== "-") {
      return name + " - 0" + phone;
    }
    return name || phone || "";
  };

  return (
    <Box>
      {/* SEARCH & ACTIONS BAR */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={t("header.title.label")} />
      </Box>
      <Box backgroundColor={colors.primary[400]} mb="0.5rem" p="0.5rem">
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{
              backgroundColor: colors.greenAccent[600]
            }}>
            <Typography variant="h4" fontWeight="bolder">
              {t("studentDetails.overview.title")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid
              container
              p="1rem"
              flexDirection="column"
              justifyContent="center"
              sx={{
                width: "100%"
              }}
              rowSpacing={4}>
              <Grid item container alignItems="center">
                <Grid item xs={6}>
                  <FormControl sx={{ width: "100%", paddingX: "0.5rem" }} size="small">
                    <Autocomplete
                      onChange={(e, value) => {
                        setStudentDetails({
                          ...studentDetails,
                          branchUuid: branchInfo.value.uuid || "",
                          branchName: branchInfo.value.branchName || ""
                        });
                      }}
                      disabled
                      value={{
                        uuid: branchInfo.value.uuid,
                        branchName: branchInfo.value.branchName
                      }}
                      placeholder="studentDetails.placeholder.branchName"
                      requestConfig={{
                        url: `${UrlPaths.ADMIN}/branches`,
                        label: "branchName",
                        value: "uuid"
                      }}></Autocomplete>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl sx={{ width: "100%", paddingX: "0.5rem" }} size="small">
                    <InputLabel id="status-label" color="neutral">
                      {t("studentDetails.placeholder.status")}
                    </InputLabel>
                    <Select
                      labelId="status-label"
                      id="status"
                      label={t("studentDetails.placeholder.status")}
                      MenuProps={{
                        style: { zIndex: 15003 }
                      }}
                      value={studentDetails.status}
                      onChange={(e) => updateFieldValue("status", e.target.value)}
                      sx={{ width: "100%" }}>
                      <MenuItem value="STD_NEW">{t("studentList.status.STD_NEW.label")}</MenuItem>
                      <MenuItem value="STD_ACTIVE">
                        {t("studentList.status.STD_ACTIVE.label")}
                      </MenuItem>
                      <MenuItem value="STD_TRIAL">
                        {t("studentList.status.STD_TRIAL.label")}
                      </MenuItem>
                      <MenuItem value="STD_IN_DEBT">
                        {t("studentList.status.STD_IN_DEBT.label")}
                      </MenuItem>
                      <MenuItem value="STD_LOA">{t("studentList.status.STD_LOA.label")}</MenuItem>
                      <MenuItem value="STD_INACTIVE">
                        {t("studentList.status.STD_INACTIVE.label")}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item container alignItems="center">
                <Grid item xs={6}>
                  <TextField
                    margin="dense"
                    label={t("studentDetails.placeholder.firstName")}
                    value={studentDetails.firstName || ""}
                    onChange={(e) => updateFieldValue("firstName", e.target.value)}
                    fullWidth
                    size="small"
                    variant="outlined"
                    sx={{ paddingX: "0.5rem" }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    margin="dense"
                    label={t("studentDetails.placeholder.lastName")}
                    value={studentDetails.lastName || ""}
                    onChange={(e) => updateFieldValue("lastName", e.target.value)}
                    fullWidth
                    size="small"
                    variant="outlined"
                    sx={{ paddingX: "0.5rem" }}
                  />
                </Grid>
              </Grid>
              <Grid item container alignItems="center">
                <Grid item xs={6}>
                  <TextField
                    margin="dense"
                    label={t("studentDetails.placeholder.displayName")}
                    value={studentDetails.displayName || ""}
                    onChange={(e) => updateFieldValue("displayName", e.target.value)}
                    fullWidth
                    size="small"
                    variant="outlined"
                    sx={{ paddingX: "0.5rem" }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      id="birthday"
                      format={DEFAULT_DATE_FORMAT}
                      label={t("studentDetails.placeholder.birthday")}
                      sx={{ width: "100%", paddingX: "0.5rem" }}
                      value={studentDetails.birthday}
                      onChange={(e) => updateFieldValue("birthday", e)}
                      slotProps={{
                        textField: { size: "small" },
                        popper: {
                          style: {
                            zIndex: 15003
                          }
                        }
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
              <Grid item container alignItems="center">
                <Grid item xs={6}>
                  <TextField
                    margin="dense"
                    label={t("studentDetails.placeholder.entranceTestResult")}
                    value={studentDetails.entranceTestResult || ""}
                    onChange={(e) => updateFieldValue("entranceTestResult", e.target.value)}
                    fullWidth
                    size="small"
                    variant="outlined"
                    sx={{ paddingX: "0.5rem" }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl sx={{ width: "100%", paddingX: "0.5rem" }} size="small">
                    <InputLabel id="status-label" color="neutral">
                      {t("studentDetails.placeholder.referralSource")}
                    </InputLabel>
                    <Select
                      labelId="status-label"
                      id="status"
                      label={t("studentDetails.placeholder.referralSource")}
                      MenuProps={{
                        style: { zIndex: 15003 }
                      }}
                      value={studentDetails.referralSource}
                      onChange={(e) => updateFieldValue("referralSource", e.target.value)}
                      sx={{ width: "100%" }}>
                      <MenuItem value="PARENT">
                        {t("studentDetails.referralSource.PARENT.label")}
                      </MenuItem>
                      <MenuItem value="TEACHER">
                        {t("studentDetails.referralSource.TEACHER.label")}
                      </MenuItem>
                      <MenuItem value="STUDENT">
                        {t("studentDetails.referralSource.STUDENT.label")}
                      </MenuItem>
                      <MenuItem value="EMPLOYEE">
                        {t("studentDetails.referralSource.EMPLOYEE.label")}
                      </MenuItem>
                      <MenuItem value="FACEBOOK_TT">
                        {t("studentDetails.referralSource.FACEBOOK_TT.label")}
                      </MenuItem>
                      <MenuItem value="FACEBOOK_ADS">
                        {t("studentDetails.referralSource.FACEBOOK_ADS.label")}
                      </MenuItem>
                      <MenuItem value="KIDDI_HUB">
                        {t("studentDetails.referralSource.KIDDI_HUB.label")}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item container alignItems="center" display="none">
                <Grid item xs={6}>
                  <TextField
                    margin="dense"
                    label={t("studentDetails.placeholder.username")}
                    value={studentDetails.username || ""}
                    onChange={(e) => updateFieldValue("username", e.target.value)}
                    fullWidth
                    size="small"
                    variant="outlined"
                    sx={{ paddingX: "0.5rem" }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    margin="dense"
                    label={t("studentDetails.placeholder.password")}
                    value={studentDetails.password || ""}
                    onChange={(e) => updateFieldValue("password", e.target.value)}
                    fullWidth
                    size="small"
                    variant="outlined"
                    sx={{ paddingX: "0.5rem" }}
                  />
                </Grid>
              </Grid>
              <Grid item container alignItems="center">
                <Grid item>
                  <Typography sx={{ fontWeight: "bold" }}>
                    {t("studentDetails.placeholder.parent")}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={5}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%"
                  }}>
                  <FormControl sx={{ width: "100%", padding: "0.5rem" }} size="small">
                    <Autocomplete
                      placeholder="studentDetails.placeholder.selectParent"
                      value={{
                        uuid: parent?.uuid || "",
                        firstName: parent?.firstName || "",
                        mobile: parent?.mobile || ""
                      }}
                      onChange={(e, value) => {
                        if (value) {
                          const {
                            uuid,
                            firstName,
                            displayName,
                            mobile,
                            email,
                            password,
                            username
                          } = value;
                          setParent({
                            ...parent,
                            uuid,
                            firstName,
                            nameAndPhone: getParentNameAndPhone(firstName, mobile),
                            displayName,
                            mobile,
                            email,
                            password,
                            username
                          });
                        } else {
                          setParent(NEW_PARENT_DATA);
                        }
                      }}
                      requestConfig={{
                        url: `${UrlPaths.USER}/parents?branchUuid=${branchInfo.value.uuid}`,
                        label: ({ firstName, mobile }) => getParentNameAndPhone(firstName, mobile),
                        value: "uuid"
                      }}></Autocomplete>
                  </FormControl>
                </Grid>
                <Grid item xs={5}>
                  <Grid item sx={{ paddingX: "0.5rem" }}>
                    <Typography component="label">
                      {t("studentDetails.placeholder.updateParent")}
                    </Typography>
                    {!parent ? (
                      <IconButton color="success" onClick={() => setParent(NEW_PARENT_DATA)}>
                        <AddCircleOutlineIcon />
                      </IconButton>
                    ) : (
                      <IconButton color="error" onClick={() => setParent(null)}>
                        <RemoveCircleOutlineIcon />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              {parent && (
                <Grid
                  item
                  container
                  sx={{ paddingTop: "0 !important", padding: "1rem" }}
                  alignItems="center">
                  <Grid item container alignItems="center">
                    <Grid item xs={6}>
                      <TextField
                        margin="dense"
                        disabled={!!parent?.uuid}
                        label={t("studentDetails.placeholder.parentName")}
                        value={parent?.firstName || ""}
                        onChange={(e) => setParent({ ...parent, firstName: e.target.value })}
                        fullWidth
                        size="small"
                        variant="outlined"
                        sx={{ paddingX: "0.5rem" }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        margin="dense"
                        disabled={!!parent?.uuid}
                        label={t("studentDetails.placeholder.parentMobile")}
                        value={parent?.mobile || ""}
                        onChange={(e) => setParent({ ...parent, mobile: e.target.value })}
                        fullWidth
                        size="small"
                        variant="outlined"
                        sx={{ paddingX: "0.5rem" }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item container alignItems="center">
                    <Grid item xs={6}>
                      <TextField
                        margin="dense"
                        disabled={!!parent?.uuid}
                        label={t("studentDetails.placeholder.parentEmail")}
                        value={parent?.email || ""}
                        onChange={(e) => setParent({ ...parent, email: e.target.value })}
                        fullWidth
                        size="small"
                        variant="outlined"
                        sx={{ paddingX: "0.5rem" }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item container alignItems="center" display="none">
                    <Grid item xs={6}>
                      <TextField
                        margin="dense"
                        disabled={!!parent?.uuid}
                        label={t("studentDetails.placeholder.parentUsername")}
                        value={parent?.username || ""}
                        onChange={(e) => setParent({ ...parent, username: e.target.value })}
                        fullWidth
                        size="small"
                        variant="outlined"
                        sx={{ paddingX: "0.5rem" }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        margin="dense"
                        disabled={!!parent?.uuid}
                        label={t("studentDetails.placeholder.parentPassword")}
                        value={parent?.password || ""}
                        onChange={(e) => setParent({ ...parent, password: e.target.value })}
                        fullWidth
                        size="small"
                        variant="outlined"
                        sx={{ paddingX: "0.5rem" }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {/** Parent 2 */}
              <Grid item container alignItems="center" sx={{ paddingTop: "0 !important" }}>
                <Grid item>
                  <Typography sx={{ fontWeight: "bold" }}>
                    {t("studentDetails.placeholder.parent2")}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={5}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%"
                  }}>
                  <FormControl sx={{ width: "100%", padding: "0.5rem" }} size="small">
                    <Autocomplete
                      placeholder="studentDetails.placeholder.selectParent"
                      value={{
                        uuid: parent2?.uuid || "",
                        firstName: parent2?.firstName || "",
                        mobile: parent2?.mobile || ""
                      }}
                      onChange={(e, value) => {
                        if (value) {
                          const {
                            uuid,
                            firstName,
                            displayName,
                            mobile,
                            email,
                            password,
                            username
                          } = value;
                          setParent2({
                            ...parent2,
                            uuid,
                            firstName,
                            nameAndPhone: getParentNameAndPhone(firstName, mobile),
                            displayName,
                            mobile,
                            email,
                            password,
                            username
                          });
                        } else {
                          setParent2({
                            ...NEW_PARENT_DATA,
                            username: "Brk-PH-" + new Date().getTime()
                          });
                        }
                      }}
                      requestConfig={{
                        url: `${UrlPaths.USER}/parents?branchUuid=${branchInfo.value.uuid}`,
                        label: ({ firstName, mobile }) => getParentNameAndPhone(firstName, mobile),
                        value: "uuid"
                      }}></Autocomplete>
                  </FormControl>
                </Grid>
                <Grid item xs={5}>
                  <Grid item sx={{ paddingX: "0.5rem" }}>
                    <Typography component="label">
                      {t("studentDetails.placeholder.updateParent")}
                    </Typography>
                    {!parent2 ? (
                      <IconButton
                        color="success"
                        onClick={() =>
                          setParent2({
                            ...NEW_PARENT_DATA,
                            username: "Brk-PH-" + new Date().getTime()
                          })
                        }>
                        <AddCircleOutlineIcon />
                      </IconButton>
                    ) : (
                      <IconButton color="error" onClick={() => setParent2(null)}>
                        <RemoveCircleOutlineIcon />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              {parent2 && (
                <Grid
                  item
                  container
                  sx={{ paddingTop: "0 !important", padding: "1rem" }}
                  alignItems="center">
                  <Grid item container alignItems="center">
                    <Grid item xs={6}>
                      <TextField
                        margin="dense"
                        disabled={!!parent2?.uuid}
                        label={t("studentDetails.placeholder.parentName")}
                        value={parent2?.firstName || ""}
                        onChange={(e) => setParent2({ ...parent2, firstName: e.target.value })}
                        fullWidth
                        size="small"
                        variant="outlined"
                        sx={{ paddingX: "0.5rem" }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        margin="dense"
                        disabled={!!parent2?.uuid}
                        label={t("studentDetails.placeholder.parentMobile")}
                        value={parent2?.mobile || ""}
                        onChange={(e) => setParent2({ ...parent2, mobile: e.target.value })}
                        fullWidth
                        size="small"
                        variant="outlined"
                        sx={{ paddingX: "0.5rem" }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item container alignItems="center">
                    <Grid item xs={6}>
                      <TextField
                        margin="dense"
                        disabled={!!parent2?.uuid}
                        label={t("studentDetails.placeholder.parentEmail")}
                        value={parent2?.email || ""}
                        onChange={(e) => setParent2({ ...parent2, email: e.target.value })}
                        fullWidth
                        size="small"
                        variant="outlined"
                        sx={{ paddingX: "0.5rem" }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item container alignItems="center" display="none">
                    <Grid item xs={6}>
                      <TextField
                        margin="dense"
                        disabled={!!parent2?.uuid}
                        label={t("studentDetails.placeholder.parentUsername")}
                        value={parent2?.username || ""}
                        onChange={(e) => setParent2({ ...parent2, username: e.target.value })}
                        fullWidth
                        size="small"
                        variant="outlined"
                        sx={{ paddingX: "0.5rem" }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        margin="dense"
                        disabled={!!parent2?.uuid}
                        label={t("studentDetails.placeholder.parentPassword")}
                        value={parent2?.password || ""}
                        onChange={(e) => setParent2({ ...parent2, password: e.target.value })}
                        fullWidth
                        size="small"
                        variant="outlined"
                        sx={{ paddingX: "0.5rem" }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
              <Button
                variant="contained"
                color="success"
                startIcon={<SaveAsIcon />}
                sx={{ width: "fit-content" }}
                onClick={() => {
                  const formData = {
                    ...studentDetails,
                    branchUuid: branchInfo.value.uuid,
                    branchName: branchInfo.value.branchName,
                    parent,
                    parent2
                  };
                  put(`${UrlPaths.STUDENTS}/${studentId}`, {
                    ...formData,
                    referralSource: formData.referralSource || undefined
                  }).catch(console.debug);
                }}>
                {t("common.button.save")}
              </Button>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{
              backgroundColor: colors.greenAccent[600]
            }}>
            <Typography variant="h4" fontWeight="bolder">
              {t("studentDetails.makeupHistories.title")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <StudentMakeUpHistories studentUuid={studentId} />
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{
              backgroundColor: colors.greenAccent[600]
            }}>
            <Typography variant="h4" fontWeight="bolder">
              {t("studentDetails.classHistories.title")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <StudentClassHistories studentId={studentId} studentName={studentDetails.niceName} />
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{
              backgroundColor: colors.greenAccent[600]
            }}>
            <Typography variant="h4" fontWeight="bolder">
              {t("studentDetails.financeHistories.title")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <StudentInvoices studentId={studentId} />
          </AccordionDetails>
        </Accordion>
      </Box>
    </Box>
  );
};

export default StudentDetails;

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import * as trans_VI from "./locales/vi/translation.json";
import * as trans_EN from "./locales/en/translation.json";

const resources = {
  vi: {
    translation: trans_VI
  },
  en: {
    translation: trans_EN
  }
};

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  //   .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    lng: "vi",
    fallbackLng: "vi",
    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });

export default i18n;

import { Box } from "@mui/material";

const ColorPreview = ({ color }) => {
  return (
    <Box
      sx={{
        borderRadius: "50%",
        border: "1px solid",
        "&:hover": {
          cursor: "pointer"
        }
      }}>
      <Box
        sx={{ backgroundColor: color, width: "20px", height: "20px", borderRadius: "50%" }}></Box>
    </Box>
  );
};
export default ColorPreview;

import { useState, useRef, useEffect } from "react";
import ColorPreview from "./ColorPreview";
import { Box, TextField } from "@mui/material";
import { SketchPicker } from "react-color";

const ColorPicker = ({ onPick, ...props }) => {
  const [open, setOpen] = useState(false);
  const ref = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Box ref={ref} sx={{ position: "relative", width: "100%" }}>
      <TextField
        {...props}
        onClick={() => {
          setOpen(!open);
        }}
        InputProps={{
          endAdornment: <ColorPreview color={props.value} />
        }}
      />
      <Box
        sx={{
          position: "absolute",
          top: "0",
          right: "100%",
          zIndex: "10000",
          display: open ? "block" : "none"
        }}>
        <SketchPicker
          color={props.value}
          onChange={(color) => {
            onPick(color.hex);
          }}
        />
      </Box>
    </Box>
  );
};

export default ColorPicker;

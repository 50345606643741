import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControl,
  Grid,
  InputLabel,
  Table as MUITable,
  MenuItem,
  Paper,
  Select,
  Skeleton,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Autocomplete from "../../../components/autocomplete";
import Table from "../../../components/table";
import UrlPaths from "../../../constants/UrlPaths";
import { getWithoutLoading } from "../../../services/HttpClient";
import { branchInfo } from "../../../signals";
import { tokens } from "../../../theme";
import { toVNDCurrency } from "../../../utils/CurencyUtil";

const getSortedData = (data, sortBy) => {
  const sorted = [...data];
  sorted.sort((a, b) => {
    const aDiff = a.expectedRevenue - a.actualRevenue;
    const bDiff = b.expectedRevenue - b.actualRevenue;
    return sortBy === "HIGH_TO_LOW" ? bDiff - aDiff : aDiff - bDiff;
  });
  return sorted.map((x, index) => ({ ...x, id: index + 1 }));
};

const FinancialSummary = ({ defaultExpanded }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();

  const [summary, setSummary] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [rankingData, setRankingData] = useState([]);
  const [selectedClass, setSelectedClass] = useState({
    classUuid: "",
    classValue: {
      uuid: "",
      className: ""
    }
  });

  const rankingColumns = [
    {
      field: "id",
      label: "rankingColumns.table.field.id.label",
      sortable: false
    },
    {
      field: "className",
      label: "rankingColumns.table.field.className.label",
      sortable: false
    },
    {
      field: "revenueDiff",
      label: "rankingColumns.table.field.revenueDiff.label",
      sortable: false,
      valueGetter: ({ expectedRevenue, actualRevenue }) =>
        toVNDCurrency(expectedRevenue - actualRevenue)
    }
  ];

  useEffect(() => {
    if (branchInfo.value.uuid) {
      refreshData();
    }
  }, [branchInfo.value, selectedClass.classUuid]);

  const refreshData = () => {
    const { classUuid } = selectedClass;
    setFetching(true);
    getWithoutLoading(`${UrlPaths.ADMIN}/statistics/${branchInfo.value.uuid}/financialSummary`, {
      classUuid
    })
      .then((res) => {
        setSummary(
          res.reduce((summary, { details }) => {
            return details.map(({ month, expectedRevenue, actualRevenue }) => {
              const target = summary.find((x) => month === x.month);
              return {
                month,
                expectedRevenue: expectedRevenue + (target?.expectedRevenue || 0),
                actualRevenue: actualRevenue + (target?.actualRevenue || 0)
              };
            });
          }, [])
        );
        const data = res.map(({ classUuid, className, details }, index) => ({
          id: index + 1,
          classUuid,
          className,
          expectedRevenue: details.reduce((totalAmount, { expectedRevenue }) => {
            totalAmount += expectedRevenue;
            return totalAmount;
          }, 0),
          actualRevenue: details.reduce((totalAmount, { actualRevenue }) => {
            totalAmount += actualRevenue;
            return totalAmount;
          }, 0)
        }));
        setRankingData(
          getSortedData(
            [
              ...data,
              {
                id: data.length + 1,
                className: t("rankingColumns.data.total.label"),
                expectedRevenue: data.reduce((totalAmount, { expectedRevenue }) => {
                  totalAmount += expectedRevenue;
                  return totalAmount;
                }, 0),
                actualRevenue: data.reduce((totalAmount, { actualRevenue }) => {
                  totalAmount += actualRevenue;
                  return totalAmount;
                }, 0)
              }
            ],
            "HIGH_TO_LOW"
          )
        );
        setFetching(false);
      })
      .catch((err) => {
        console.debug(err);
        setFetching(false);
      });
  };

  return (
    <>
      <Accordion defaultExpanded={defaultExpanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{
            backgroundColor: colors.greenAccent[600]
          }}>
          <Typography variant="h4" fontWeight="bolder">
            {t("financialSummary.details.title")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {fetching ? (
            <>
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
            </>
          ) : (
            <>
              <Box
                backgroundColor={colors.primary[400]}
                display="flex"
                mb="0.5rem"
                p="0.5rem"
                justifyContent="space-between">
                <Grid container mb="1rem" columnSpacing={1}>
                  <Grid item xs={4}>
                    <FormControl sx={{ width: "100%", paddingX: "0.5rem" }} size="small">
                      <Autocomplete
                        value={selectedClass.classValue}
                        placeholder="loadStudents.placeholder.className"
                        onChange={(e, value) => {
                          setSelectedClass({
                            classUuid: value?.uuid,
                            classValue: value || {
                              className: "",
                              uuid: ""
                            }
                          });
                        }}
                        requestConfig={{
                          url: `${UrlPaths.CLASSES}?branchUuid=${branchInfo.value.uuid}`,
                          label: "className",
                          value: "uuid",
                          responseField: (res) =>
                            res.results.map(({ classDetails }) => classDetails),
                          onQueryChange: (value) => (value ? `&any=${value}` : "")
                        }}></Autocomplete>
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
              <Box
                backgroundColor={colors.primary[400]}
                display="flex"
                mb="0.5rem"
                p="0.5rem"
                justifyContent="space-between">
                <TableContainer component={Paper}>
                  <MUITable aria-label="spanning table" size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">
                          {t("financialSummary.table.field.id.label")}
                        </TableCell>
                        <TableCell align="center">
                          {t("financialSummary.table.field.time.label")}
                        </TableCell>
                        {summary.map(({ month }, index) => (
                          <TableCell key={index} align="center">
                            {t("financialSummary.table.field.month.label", {
                              month
                            })}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell align="center">1</TableCell>
                        <TableCell align="center">
                          {t("financialSummary.table.field.expectedRevenue.label")}
                        </TableCell>
                        {summary.map(({ expectedRevenue }, index) => (
                          <TableCell align="center" key={index}>
                            {toVNDCurrency(expectedRevenue)}
                          </TableCell>
                        ))}
                      </TableRow>
                      <TableRow>
                        <TableCell align="center">2</TableCell>
                        <TableCell align="center">
                          {t("financialSummary.table.field.actualRevenue.label")}
                        </TableCell>
                        {summary.map(({ actualRevenue }, index) => (
                          <TableCell align="center" key={index}>
                            {toVNDCurrency(actualRevenue)}
                          </TableCell>
                        ))}
                      </TableRow>
                      <TableRow>
                        <TableCell align="center">3</TableCell>
                        <TableCell align="center">
                          {t("financialSummary.table.field.revenueDiff.label")}
                        </TableCell>
                        {summary.map(({ expectedRevenue, actualRevenue }, index) => (
                          <TableCell align="center" key={index}>
                            {toVNDCurrency(expectedRevenue - actualRevenue)}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableBody>
                  </MUITable>
                </TableContainer>
              </Box>
              <Box
                backgroundColor={colors.primary[400]}
                display="flex"
                flexDirection="column"
                mb="0.5rem"
                p="0.5rem">
                <Grid
                  container
                  mb="1rem"
                  columnSpacing={1}
                  sx={{ width: "100%", paddingX: "0.5rem" }}>
                  <Grid item xs={4}>
                    <FormControl size="small">
                      <InputLabel id="class-status-label" color="neutral">
                        {t("financeReports.sortBy.label")}
                      </InputLabel>
                      <Select
                        labelId="class-status-label"
                        id="class-status"
                        defaultValue="HIGH_TO_LOW"
                        label={t("financeReports.sortBy.label")}
                        onChange={(e) => {
                          setRankingData(getSortedData(rankingData, e.target.value));
                        }}>
                        <MenuItem value="HIGH_TO_LOW">
                          {t("financeReports.sortBy.HIGH_TO_LOW")}
                        </MenuItem>
                        <MenuItem value="LOW_TO_HIGHT">
                          {t("financeReports.sortBy.LOW_TO_HIGHT")}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid
                  container
                  mb="1rem"
                  columnSpacing={1}
                  sx={{ width: "100%", paddingX: "0.5rem" }}>
                  <Table
                    showHeaderToolbar={false}
                    showFooterToolbar={false}
                    columnConfig={rankingColumns}
                    data={rankingData}
                  />
                </Grid>
              </Box>
            </>
          )}
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default FinancialSummary;

import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";

const ProgressCircle = ({
  progress = 0,
  size = "40",
  showPercentage = true,
  progressingColor,
  circleColor,
  textColor
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const angle = progress * 360;

  return (
    <Box
      sx={{
        background: `radial-gradient(${colors.primary[400]} 55%, transparent 56%),
            conic-gradient(transparent 0deg ${angle}deg, ${
              circleColor || colors.blueAccent[500]
            } ${angle}deg 360deg),
            ${progressingColor || colors.greenAccent[500]}`,
        borderRadius: "50%",
        width: `${size}px`,
        height: `${size}px`,
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}>
      {showPercentage && (
        <Typography
          variant="caption"
          component="div"
          color={textColor || "text.secondary"}
          sx={{ fontWeight: "bold" }}>
          {`${Math.round(progress * 100)}%`}
        </Typography>
      )}
    </Box>
  );
};

export default ProgressCircle;

import {
  Box,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme
} from "@mui/material";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import Header from "../../../components/header";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../theme";
import Table from "../../../components/table";
import { useEffect, useState } from "react";
import { branchInfo } from "../../../signals";
import { DEFAULT_DATE_FORMAT, formatDate } from "../../../utils/TimeUtil";
import Autocomplete from "../../../components/autocomplete";
import UrlPaths from "../../../constants/UrlPaths";

import { get, put } from "../../../services/HttpClient";
import { toVNDCurrency } from "../../../utils/CurencyUtil";
import { Link } from "react-router-dom";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { DEPARTMENTS } from "../../../constants/Department";

const DEFAULT_SEARCH_CRITERIA = {
  contractType: "",
  createdBy: "",
  extraInfo: true,
  fromDate: moment().subtract(1, "weeks"),
  toDate: moment()
};

const ContractHistories = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [searchCriteria, setSearchCriteria] = useState(DEFAULT_SEARCH_CRITERIA);
  const [creator, setCreator] = useState({
    uuid: "",
    niceName: ""
  });
  const [total, setTotal] = useState(0);
  const [notes, setNotes] = useState([]);
  const [pagination, setPagination] = useState({
    pageNumber: 0,
    pageSize: 30
  });
  useEffect(() => {
    if (branchInfo.value.uuid) {
      refreshData();
    }
  }, [pagination]);
  useEffect(() => {
    setPagination({
      pageNumber: 0,
      pageSize: 30
    });
  }, [branchInfo.value, searchCriteria]);
  const refreshData = () => {
    get(`${UrlPaths.ADMIN}/invoices`, {
      branchUuid: branchInfo.value.uuid,
      ...pagination,
      ...searchCriteria
    })
      .then((res) => {
        const { pageSize, pageNumber } = pagination;
        setTotal(res.total);
        setData(res.results.map((x, index) => ({ id: pageNumber * pageSize + index + 1, ...x })));
        setNotes(res.results.map(({ note }) => note || ""));
      })
      .catch(console.debug);
  };

  const handleCriteriaChange = (field, value) => {
    setSearchCriteria({ ...searchCriteria, [field]: value || "" });
  };
  const noteChange = (e, id) => {
    const noteList = [...notes];
    noteList.splice(id - 1, 1, e.target.value);
    setNotes(noteList);
  };

  const columns = [
    { field: "id", label: "contractHistories.table.field.id.label" },

    {
      field: "studentName",
      label: "contractHistories.table.field.studentName.label"
    },
    {
      field: "updateQuantity",
      label: "contractHistories.table.field.numberOfAttendances.label",
      valueGetter: ({ updateQuantity }) => updateQuantity || 0
    },
    {
      field: "contractType",
      label: "contractHistories.table.field.contractType.label",
      valueGetter: ({ contractType }) => t(`contractHistories.contractType.${contractType}`)
    },
    {
      field: "invoiceId",
      label: "contractHistories.table.field.contractId.label",
      valueGetter: ({ uuid, invoiceId }) => (
        <Link to={`../payment-contract/${uuid}`}>
          <Typography sx={{ fontWeight: "bold", color: colors.greenAccent[400] }}>
            {invoiceId}
          </Typography>
        </Link>
      )
    },
    {
      field: "paidAndDebt",
      label: "contractHistories.table.field.paidAndDebt.label",
      valueGetter: ({ totalAmount, paidAmount, discountAmount }) =>
        `${toVNDCurrency(paidAmount)}/${toVNDCurrency(totalAmount - (paidAmount + discountAmount))}`
    },
    {
      field: "createDate",
      label: "contractHistories.table.field.createDate.label",
      valueGetter: ({ creationDate }) => formatDate(creationDate)
    },
    {
      field: "creatorName",
      label: "contractHistories.table.field.employeeCreate.label",
      valueGetter: ({ creatorName }) => creatorName || "?"
    },
    {
      field: "note",
      label: "contractHistories.table.field.note.label",
      sortable: false,
      component: ({ id, notes, uuid }) => (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <TextField
            id="outlined-basic"
            value={notes[id - 1]}
            onChange={(e) => noteChange(e, id)}
            multiline
            maxRows={4}
            variant="outlined"
            sx={{ width: "100%" }}
            size="small"
          />
          <IconButton
            variant="contained"
            color="success"
            onClick={() => {
              put(`${UrlPaths.ADMIN}/invoices/${uuid}/note`, { note: notes[id - 1] })
                .then(() => {
                  refreshData();
                })
                .catch(console.debug);
            }}>
            <SaveAsIcon />
          </IconButton>
        </Box>
      )
    }
  ];

  return (
    <Box>
      {/* SEARCH & ACTIONS BAR */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={t("header.title.label")} />
      </Box>
      <Box
        backgroundColor={colors.primary[400]}
        mb="0.5rem"
        p="0.5rem"
        justifyContent="space-between">
        <Grid container mb="1rem" columnSpacing={1}>
          <Grid item xs={6}>
            <FormControl sx={{ minWidth: 150, width: "100%", paddingX: "0.5rem" }} size="small">
              <InputLabel id="class-status-label" color="neutral">
                {t("contractHistories.placeholder.contractType")}
              </InputLabel>
              <Select
                labelId="class-status-label"
                id="class-status"
                label={t("contractHistories.placeholder.contractType")}
                value={searchCriteria.contractType}
                onChange={(e) => handleCriteriaChange("contractType", e.target.value)}>
                <MenuItem value="">{t("contractHistories.contractType.ALL")}</MenuItem>
                <MenuItem value="NEW">{t("contractHistories.contractType.NEW")}</MenuItem>
                <MenuItem value="RENEW">{t("contractHistories.contractType.RENEW")}</MenuItem>
                <MenuItem value="VIRTUAL_CONTRACT">
                  {t("contractHistories.contractType.VIRTUAL_CONTRACT")}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <FormControl sx={{ width: "100%", paddingX: "0.5rem" }} size="small">
              <Autocomplete
                placeholder="contractHistories.placeholder.employeeValue"
                value={creator}
                onChange={(e, value) => {
                  if (value) {
                    handleCriteriaChange("createdBy", value.uuid);
                    setCreator(value);
                  } else {
                    handleCriteriaChange("createdBy", undefined);
                    setCreator({
                      uuid: "",
                      niceName: ""
                    });
                  }
                }}
                requestConfig={{
                  url: `${UrlPaths.ADMIN}/${branchInfo.value.uuid}/staffs`,
                  responseField: (results) =>
                    results.filter(({ departments }) =>
                      ["ALL", DEPARTMENTS.OFFICE].includes(departments[0])
                    ),
                  label: "niceName"
                }}></Autocomplete>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container mb="1rem" columnSpacing={1}>
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                format={DEFAULT_DATE_FORMAT}
                label={t("contractHistories.placeholder.fromDate")}
                maxDate={searchCriteria.toDate}
                value={searchCriteria.fromDate}
                onChange={(e) => setSearchCriteria({ ...searchCriteria, fromDate: e })}
                sx={{ width: "100%", padding: "0 0.5rem" }}
                timeSteps={{ hours: 1, minutes: 15 }}
                slotProps={{ textField: { size: "small" } }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                format={DEFAULT_DATE_FORMAT}
                label={t("contractHistories.placeholder.toDate")}
                minDate={searchCriteria.fromDate}
                value={searchCriteria.toDate}
                onChange={(e) => setSearchCriteria({ ...searchCriteria, toDate: e })}
                sx={{ width: "100%", padding: "0 0.5rem" }}
                timeSteps={{ hours: 1, minutes: 15 }}
                slotProps={{ textField: { size: "small" } }}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
      </Box>
      <Box backgroundColor={colors.primary[400]} mb="0.5rem" p="0.5rem">
        <Table
          columnConfig={columns}
          data={data.map((x) => ({
            ...x,
            notes
          }))}
          totalResults={total}
          serverPaging={true}
          defaultPagination={pagination}
          paginationChanged={(paging) => setPagination(paging)}
        />
      </Box>
    </Box>
  );
};
export default ContractHistories;

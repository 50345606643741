import DownloadIcon from "@mui/icons-material/Download";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import NavigateNext from "@mui/icons-material/NavigateNext";
import NavigateBefore from "@mui/icons-material/NavigateBefore";
import {
  Box,
  ButtonGroup,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme
} from "@mui/material";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Autocomplete from "../../../components/autocomplete";
import { Button } from "../../../components/button";
import Header from "../../../components/header";
import { WEEK_DAYS } from "../../../constants/Timetable";
import UrlPaths from "../../../constants/UrlPaths";
import { get } from "../../../services/HttpClient";
import { branchInfo } from "../../../signals";
import { tokens } from "../../../theme";
import { DEFAULT_MONTH_FORMAT } from "../../../utils/TimeUtil";
import MonthView from "../../../components/calendar/MonthView";
import TableCellStyled from "../../../components/calendar/TableCellStyled";
import DayView from "../../../components/calendar/DayView";

const getTime = (weekYear, dayOfWeek, timeSlot) => {
  const weekYearParts = weekYear.split("|");
  const timeParts = timeSlot.split(":");
  let day = moment().isoWeekday(dayOfWeek);
  day.isoWeek(weekYearParts[0]);
  day.isoWeekYear(weekYearParts[1]);
  day.hour(timeParts[0]);
  day.minutes(timeParts[1]);
  return day.toDate();
};
const getDate = (weekYear, dayOfWeek) => {
  const weekYearParts = weekYear.split("|");
  let day = moment().isoWeekday(dayOfWeek);
  day.isoWeek(weekYearParts[0]);
  day.isoWeekYear(weekYearParts[1]);
  return day.toDate();
};
const getWeekOptions = (pastWeeks = 12, futureWeeks = 72) => {
  const now = moment();
  const weekOptions = [];
  for (let i = 0; i < pastWeeks; i++) {
    const date = now.clone().subtract(pastWeeks - i, "weeks");
    const startDayOfWeek = date.clone().isoWeekday(1);
    const endDayOfWeek = date.clone().isoWeekday(7);
    weekOptions.push({
      week: date.isoWeek(),
      year: date.isoWeekYear(),
      from: startDayOfWeek,
      to: endDayOfWeek
    });
  }
  for (let i = 0; i < futureWeeks; i++) {
    const date = now.clone().add(i, "weeks");
    const startDayOfWeek = date.clone().isoWeekday(1);
    const endDayOfWeek = date.clone().isoWeekday(7);
    weekOptions.push({
      week: date.isoWeek(),
      year: date.isoWeekYear(),
      from: startDayOfWeek,
      to: endDayOfWeek
    });
  }
  return weekOptions;
};
const getNextWeek = (weekYear) => {
  const weekYearParts = weekYear.split("|");
  const currentWeek = moment().isoWeek(weekYearParts[0]);
  currentWeek.isoWeekYear(weekYearParts[1]);
  const nextWeek = currentWeek.clone().add(1, "week");
  return nextWeek.isoWeek() + "|" + nextWeek.isoWeekYear();
};
const getPreviousWeek = (weekYear) => {
  const weekYearParts = weekYear.split("|");
  const currentWeek = moment().isoWeek(weekYearParts[0]);
  currentWeek.isoWeekYear(weekYearParts[1]);
  const previousWeek = currentWeek.clone().subtract(1, "week");
  return previousWeek.isoWeek() + "|" + previousWeek.isoWeekYear();
};
const DEFAULT_SEARCH_CRITERIA = {
  weekYear: moment().isoWeek() + "|" + moment().isoWeekYear(),
  from: moment().isoWeekday(1),
  to: moment().isoWeekday(7),
  weekDay: "",
  month: moment().month() + 1
};
const DEFAULT_TEACHER_COLOR = "#c6c6c6";
const INITIAL_ROWS = [
  {
    id: 1,
    dayOfWeek: "MONDAY",
    numOfClass: 0,
    classes: []
  },
  {
    id: 2,
    dayOfWeek: "TUESDAY",
    numOfClass: 0,
    classes: []
  },
  {
    id: 3,
    dayOfWeek: "WEDNESDAY",
    numOfClass: 0,
    classes: []
  },
  {
    id: 4,
    dayOfWeek: "THURSDAY",
    numOfClass: 0,
    classes: []
  },
  {
    id: 5,
    dayOfWeek: "FRIDAY",
    numOfClass: 0,
    classes: []
  },
  {
    id: 6,
    dayOfWeek: "SATURDAY",
    numOfClass: 0,
    classes: []
  },
  {
    id: 7,
    dayOfWeek: "SUNDAY",
    numOfClass: 0,
    classes: []
  }
];
const Timetable = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const tableRef = useRef(null);
  const [searchCriteria, setSearchCriteria] = useState(DEFAULT_SEARCH_CRITERIA);
  const [columnWidth, setColumnWidth] = useState(0);
  const [rows, setRows] = useState(INITIAL_ROWS);
  const [view, setView] = useState("week");
  const [maxClass, setMaxClass] = useState(0);
  const colors = tokens(theme.palette.mode);
  useEffect(() => {
    if (branchInfo.value.uuid) {
      get(`${UrlPaths.ADMIN}/schedule`, { ...searchCriteria, branchUuid: branchInfo.value.uuid })
        .then((res) => {
          const { weekYear } = searchCriteria;
          const weekDays = INITIAL_ROWS.map((weekDay) => {
            const classesInDay = res.filter(({ dayOfWeek }) => dayOfWeek === weekDay.dayOfWeek);
            setMaxClass(Math.max(classesInDay.length.numOfClass, maxClass));
            return {
              ...weekDay,
              numOfClass: classesInDay.length,
              date: getDate(weekYear, weekDay.dayOfWeek),
              classes: classesInDay.map(
                ({
                  classUuid,
                  className,
                  classroomName,
                  timeSlot,
                  dayOfWeek,
                  teacherName,
                  teacherColor,
                  ...eventData
                }) => {
                  const timeSlotParts = timeSlot.split("-");
                  return {
                    className,
                    classID: classUuid,
                    color: teacherColor || DEFAULT_TEACHER_COLOR,
                    start: getTime(weekYear, dayOfWeek, timeSlotParts[0]),
                    end: getTime(weekYear, dayOfWeek, timeSlotParts[1]),
                    timeSlot,
                    classroomName,
                    teacherName,
                    ...eventData
                  };
                }
              )
            };
          });
          setRows(weekDays);
        })
        .catch(console.debug);
    }
  }, [searchCriteria, branchInfo.value]);
  useEffect(() => {
    if (tableRef.current) {
      const tableWidth = tableRef.current.offsetWidth;
      setColumnWidth(tableWidth / 7);
    }
  }, [tableRef]);
  const handleCriteriaChange = (field, value) => {
    setSearchCriteria({ ...searchCriteria, [field]: value || "" });
  };
  const renderDayHeaderContent = ({ text, date }) => {
    if (!text || !date) return null;
    const weekDay = text.substring(0, 3).toLowerCase();
    const day = date.toLocaleDateString("en-GB");
    return (
      <b>
        {t(`timetable.weekDay.${weekDay}.label`)}
        <br />
        {day}
      </b>
    );
  };
  const renderEventContent = (event) => {
    return (
      <Typography aria-describedby="test" variant="contained">
        <span>{t("timetable.className.label")}: </span>
        <b>{event?.className}&nbsp;/&nbsp;</b>
        <i style={{ fontWeight: "bold" }}>
          {event?.classroomName}&nbsp;({event?.timeSlot || ""})
        </i>
        <br />
        <span>{t("timetable.teacherName.label")}: </span>
        <b>
          {event?.teacherName} <i>{event?.teacherTimeSlot || ""}</i>
        </b>
        <br />

        <span>{t("timetable.foreignTeacherName.label")}: </span>
        <b>
          {event?.foreignTeacherName} <i>{event?.foreignTeacherTimeSlot || ""}</i>
        </b>
        <br />

        <span>{t("timetable.teachingAssistantName.label")}: </span>
        <b>
          {event?.teachingAssistantName} <i>{event?.teachingAssistantTimeSlot || ""}</i>
        </b>
      </Typography>
    );
  };
  const handleDayChange = (day) => {
    const date = moment(day);
    setSearchCriteria({
      ...searchCriteria,
      date,
      from: date.startOf("day"),
      to: date.endOf("day"),
      month: date.month() + 1,
      weekYear: date.isoWeek() + "|" + date.isoWeekYear()
    });
  };
  const handleWeekChange = (weekYear) => {
    const { from, to } = getWeekOptions().find(({ week, year }) => {
      return week + "|" + year === weekYear;
    });
    setSearchCriteria({
      ...searchCriteria,
      from: from,
      to: to,
      month: moment(from).month() + 1,
      weekYear
    });
  };
  const handleMonthChange = (month) => {
    const startOfMonth = moment()
      .month(month - 1)
      .startOf("month");
    const endOfMonth = moment()
      .month(month - 1)
      .endOf("month");
    setSearchCriteria({
      ...searchCriteria,
      from: startOfMonth,
      to: endOfMonth,
      month
    });
  };
  const handleViewChange = (view) => {
    if (view === "month") {
      handleMonthChange(searchCriteria.month);
    } else if (view === "week") {
      handleWeekChange(searchCriteria.weekYear);
    } else if (view === "day") {
      handleDayChange(searchCriteria.date);
    }
    setView(view);
  };
  const getNextDuration = (view) => {
    if (view === "month") {
      handleMonthChange(searchCriteria.month + 1);
    } else if (view === "week") {
      handleWeekChange(getNextWeek(searchCriteria.weekYear));
    } else if (view === "day") {
      handleDayChange(moment(searchCriteria.date).add(1, "day"));
    }
  };
  const getPreviousDuration = (view) => {
    if (view === "month") {
      handleMonthChange(searchCriteria.month - 1);
    } else if (view === "week") {
      handleWeekChange(getPreviousWeek(searchCriteria.weekYear));
    } else if (view === "day") {
      handleDayChange(moment(searchCriteria.date).subtract(1, "day"));
    }
  };
  const getViewHeader = () => {
    if (view === "month") {
      return t("timetable.monthView.label", {
        month: moment(searchCriteria.from).format("MM"),
        year: moment(searchCriteria.from).format("YYYY"),
        startDayOfMonth: moment(searchCriteria.from).format(DEFAULT_MONTH_FORMAT),
        endDayOfMonth: moment(searchCriteria.to).format(DEFAULT_MONTH_FORMAT)
      });
    } else if (view === "week") {
      return t("timetable.weekView.label", {
        week: moment(searchCriteria.from).isoWeek(),
        year: moment(searchCriteria.from).isoWeekYear(),
        startDayOfWeek: moment(searchCriteria.from).format(DEFAULT_MONTH_FORMAT),
        endDayOfWeek: moment(searchCriteria.to).format(DEFAULT_MONTH_FORMAT)
      });
    } else if (view === "day") {
      return t("timetable.dayView.label", {
        date: moment(searchCriteria.date).format("DD/MM/YYYY")
      });
    }
  };
  const columns = [
    {
      field: "MONDAY",
      headerName: renderDayHeaderContent({
        text: "MONDAY",
        date: getDate(searchCriteria.weekYear, "MONDAY")
      })
    },
    {
      field: "TUESDAY",
      headerName: renderDayHeaderContent({
        text: "TUESDAY",
        date: getDate(searchCriteria.weekYear, "TUESDAY")
      })
    },
    {
      field: "WEDNESDAY",
      headerName: renderDayHeaderContent({
        text: "WEDNESDAY",
        date: getDate(searchCriteria.weekYear, "WEDNESDAY")
      })
    },
    {
      field: "THURSDAY",
      headerName: renderDayHeaderContent({
        text: "THURSDAY",
        date: getDate(searchCriteria.weekYear, "THURSDAY")
      })
    },
    {
      field: "FRIDAY",
      headerName: renderDayHeaderContent({
        text: "FRIDAY",
        date: getDate(searchCriteria.weekYear, "FRIDAY")
      })
    },
    {
      field: "SATURDAY",
      headerName: renderDayHeaderContent({
        text: "SATURDAY",
        date: getDate(searchCriteria.weekYear, "SATURDAY")
      })
    },
    {
      field: "SUNDAY",
      headerName: renderDayHeaderContent({
        text: "SUNDAY",
        date: getDate(searchCriteria.weekYear, "SUNDAY")
      })
    }
  ];
  return (
    <Box>
      {/* SEARCH & ACTIONS BAR */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={t("header.title.label")} />
      </Box>
      <Box
        backgroundColor={colors.primary[400]}
        mb="0.5rem"
        p="0.5rem"
        justifyContent="space-between">
        <Grid container mb="1rem" columnSpacing={1}>
          <Grid item xs={4}>
            <FormControl sx={{ width: "100%", paddingX: "0.5rem" }} size="small">
              <InputLabel id="class-status-label" color="neutral">
                {t("timetable.placeholder.weekOptions")}
              </InputLabel>
              <Select
                labelId="class-status-label"
                id="class-status"
                label={t("timetable.placeholder.weekOptions")}
                value={searchCriteria.weekYear}
                onChange={(e) => handleWeekChange(e.target.value, true)}
                MenuProps={{
                  style: { zIndex: 15002 }
                }}
                sx={{ width: "100%" }}>
                {getWeekOptions().map(({ week, year, from, to }, index) => (
                  <MenuItem key={index} value={week + "|" + year}>
                    {t("timetable.weekOptions.label", {
                      week,
                      year,
                      startDayOfWeek: from.format(DEFAULT_MONTH_FORMAT),
                      endDayOfWeek: to.format(DEFAULT_MONTH_FORMAT)
                    })}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl sx={{ width: "100%", paddingX: "0.5rem" }} size="small">
              <InputLabel id="class-status-label" color="neutral">
                {t("timetable.placeholder.weekDayOptions")}
              </InputLabel>
              <Select
                labelId="class-status-label"
                id="class-status"
                label={t("timetable.placeholder.weekDayOptions")}
                value={searchCriteria.weekDay}
                onChange={(e) => handleCriteriaChange("weekDay", e.target.value)}
                MenuProps={{
                  style: { zIndex: 15002 }
                }}
                sx={{ width: "100%" }}>
                {WEEK_DAYS.map(({ label, value }, index) => (
                  <MenuItem key={index} value={value}>
                    {t(label)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl sx={{ width: "100%", paddingX: "0.5rem" }} size="small">
              <Autocomplete
                placeholder="timetable.placeholder.teacherOptions"
                onChange={(e, value) => {
                  handleCriteriaChange("teacherUuid", value?.uuid || undefined);
                }}
                requestConfig={{
                  url: `${UrlPaths.TEACHERS}?status=ACTIVE&branchUuid=${branchInfo.value.uuid}&orderBy=displayName&order=asc`,
                  label: "displayName",
                  value: "uuid",
                  responseField: "results"
                }}></Autocomplete>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container mb="1rem" columnSpacing={1}>
          <Grid item xs={4}>
            <FormControl sx={{ width: "100%", paddingX: "0.5rem" }} size="small">
              <Autocomplete
                placeholder="timetable.placeholder.courseOptions"
                onChange={(e, value) => {
                  handleCriteriaChange("courseUuid", value?.uuid || undefined);
                }}
                requestConfig={{
                  url: `${UrlPaths.ADMIN}/courses?branchUuid=${branchInfo.value.uuid}`,
                  label: "courseName",
                  value: "uuid"
                }}></Autocomplete>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl sx={{ width: "100%", paddingX: "0.5rem" }} size="small">
              <Autocomplete
                placeholder="timetable.placeholder.classOptions"
                onChange={(e, value) => {
                  handleCriteriaChange("classUuid", value?.uuid || undefined);
                }}
                requestConfig={{
                  url: `${UrlPaths.CLASSES}?branchUuid=${branchInfo.value.uuid}`,
                  label: "className",
                  value: "uuid",
                  responseField: (res) => res.results.map(({ classDetails }) => classDetails),
                  onQueryChange: (value) => (value ? `&any=${value}` : "")
                }}></Autocomplete>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="outlined-basic"
              disabled
              label={t("timetable.placeholder.session")}
              variant="outlined"
              sx={{ width: "100%", paddingX: "0.5rem" }}
              size="small"
            />
          </Grid>
        </Grid>
        <Grid container mb="1rem" columnSpacing={1}>
          <Grid item xs={4}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <TimePicker
                label={t("timetable.placeholder.fromTime")}
                ampm={false}
                onChange={(e) => {
                  let hour = e.hour();
                  let min = e.minutes();
                  if (hour.length < 2) {
                    hour = "0" + hour;
                  }
                  if (min.length < 2) {
                    min = "0" + min;
                  }
                  handleCriteriaChange("startTime", hour + ":" + min);
                }}
                sx={{ width: "100%", padding: "0 0.5rem" }}
                timeSteps={{ hours: 1, minutes: 15 }}
                slotProps={{ textField: { size: "small" } }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={4}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <TimePicker
                label={t("timetable.placeholder.toTime")}
                ampm={false}
                onChange={(e) => {
                  let hour = e.hour();
                  let min = e.minutes();
                  if (hour.length < 2) {
                    hour = "0" + hour;
                  }
                  if (min.length < 2) {
                    min = "0" + min;
                  }
                  handleCriteriaChange("endTime", hour + ":" + min);
                }}
                sx={{ width: "100%", padding: "0 0.5rem" }}
                slotProps={{ textField: { size: "small" } }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={4}>
            <FormControl sx={{ width: "100%", paddingX: "0.5rem" }} size="small">
              <Autocomplete
                placeholder="timetable.placeholder.roomOptions"
                onChange={(e, value) => {
                  handleCriteriaChange("classroomUuid", value?.uuid || undefined);
                }}
                requestConfig={{
                  url: `${UrlPaths.ADMIN}/classrooms?branchUuid=${branchInfo.value.uuid}&status=ACTIVE`,
                  label: "classroomName",
                  value: "uuid"
                }}></Autocomplete>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container direction="row-reverse" columnSpacing={1}>
          <Grid item xs={4} sx={{ display: "flex", flexDirection: "row-reverse" }}>
            <ButtonGroup
              variant="contained"
              aria-label="outlined primary button group"
              sx={{ marginX: "0.5rem" }}>
              <Button startIcon={<DownloadIcon />} variant="contained" color="neutral" disabled>
                {t("common.button.excel")}
              </Button>
              <Button
                variant="contained"
                color="success"
                startIcon={<RestartAltIcon />}
                onClick={() => setSearchCriteria(DEFAULT_SEARCH_CRITERIA)}>
                {t("common.button.reset")}
              </Button>
            </ButtonGroup>
          </Grid>
        </Grid>
      </Box>
      <Container
        sx={{
          backgroundColor: colors.primary[400],
          marginBottom: "0.5rem",
          padding: "0.5rem",
          minWidth: "100% ",
          display: "flex",
          gap: "0.5rem",
          flexDirection: "column"
        }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <ButtonGroup sx={{ height: "min-content" }}>
            <Button onClick={() => getPreviousDuration(view)}>
              <NavigateBefore />
            </Button>
            <Button onClick={() => getNextDuration(view)}>
              <NavigateNext />
            </Button>
          </ButtonGroup>
          <h1>{getViewHeader()}</h1>
          <ButtonGroup sx={{ height: "min-content" }}>
            <Button onClick={() => handleViewChange("month")}>{t("timetable.month.label")}</Button>
            <Button
              onClick={() => {
                handleViewChange("week");
              }}>
              {t("timetable.week.label")}
            </Button>
            <Button
              onClick={() => {
                handleViewChange("day");
              }}>
              {t("timetable.day.label")}
            </Button>
          </ButtonGroup>
        </Box>

        <TableContainer component={Box} sx={{ width: "100%" }}>
          {view === "month" && (
            <MonthView rows={rows} from={searchCriteria.from} to={searchCriteria.to} />
          )}
          {view === "week" && (
            <Table ref={tableRef}>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCellStyled
                      key={column.field}
                      sx={{ textAlign: "center", background: colors.primary[900] }}>
                      <Typography>{column.headerName}</Typography>
                    </TableCellStyled>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.from({ length: 6 }, (_, index) => (
                  <TableRow key={index}>
                    {rows.map((row) => {
                      const currentDay = moment().date();
                      return row.numOfClass > index ? (
                        <TableCell
                          key={row.id}
                          sx={{
                            width: columnWidth,
                            background: row.classes[index]?.color,
                            verticalAlign: "top",
                            border: "1px solid rgba(224, 224, 224, 1)"
                          }}>
                          {renderEventContent(row.classes[index])}
                        </TableCell>
                      ) : (
                        <TableCellStyled
                          key={row.id}
                          sx={{
                            width: columnWidth,
                            background: currentDay === moment(row.date).date() ? "#fffadf" : "white"
                          }}></TableCellStyled>
                      );
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
          {view === "day" && <DayView rows={rows} date={searchCriteria.date} />}
        </TableContainer>
      </Container>
    </Box>
  );
};

export default Timetable;

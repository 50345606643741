import { Edit } from "@mui/icons-material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SearchIcon from "@mui/icons-material/Search";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import {
  Box,
  ButtonGroup,
  Chip,
  Grid,
  IconButton,
  InputBase,
  Typography,
  useTheme
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../../components/button";
import Dialog, { DialogButtons } from "../../../components/dialog";
import Header from "../../../components/header";
import Status from "../../../components/status";
import Table from "../../../components/table";
import UrlPaths from "../../../constants/UrlPaths";
import { get, post, put } from "../../../services/HttpClient";
import { tokens } from "../../../theme";
import { formatDate } from "../../../utils/TimeUtil";
import AddHolidayDialog from "./AddHolidayDialog";
import UpdateHolidayDialog from "./UpdateHolidayDialog";

const Holidays = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();

  const [selectedRow, setSelectedRow] = useState(null);
  const [newHoliday, setNewHoliday] = useState(false);
  const [data, setData] = useState([]);
  const [updateTimeTable, setUpdateTimeTable] = useState(false);
  const [total, setTotal] = useState(0);
  const [searchCriteria, setSearchCriteria] = useState({});
  const [any, setAny] = useState("");
  const [pagination, setPagination] = useState({
    pageNumber: 0,
    pageSize: 30,
    order: "asc",
    orderBy: "startDate"
  });

  const columns = [
    { field: "id", label: "holiday.table.field.id.label" },
    {
      field: "name",
      label: "holiday.table.field.name.label",
      sortable: false
    },
    {
      field: "startDate",
      label: "holiday.table.field.from.label",
      valueGetter: ({ startDate }) => formatDate(startDate)
    },
    {
      field: "endDate",
      label: "holiday.table.field.to.label",
      valueGetter: ({ endDate }) => formatDate(endDate)
    },
    {
      field: "description",
      label: "holiday.table.field.description.label",
      sortable: false
    },
    {
      field: "notification",
      label: "holiday.table.field.notification.label",
      sortable: false
    },
    {
      field: "status",
      label: "holiday.table.field.status.label",
      align: "center",
      component: (rowData) => (
        <Status prefix="holiday.status" postfix="label" status={rowData.status} type="HOLIDAY" />
      )
    }
  ];
  const rowActions = [
    {
      icon: <Edit />,
      tooltip: t("holiday.table.action.update.label"),
      action: (rowData) => {
        setSelectedRow(rowData);
      }
    }
  ];

  useEffect(() => {
    refreshData();
  }, [pagination]);

  useEffect(() => {
    setPagination({
      pageNumber: 0,
      pageSize: 30,
      order: "asc",
      orderBy: "startDate"
    });
  }, [searchCriteria]);

  const refreshData = () => {
    get(`${UrlPaths.ADMIN}/holidays`, { ...pagination, ...searchCriteria })
      .then((res) => {
        const { pageSize, pageNumber } = pagination;
        setTotal(res.total);
        setData(res.results.map((x, index) => ({ id: pageNumber * pageSize + index + 1, ...x })));
      })
      .catch(console.debug);
  };

  const handleClose = () => {
    setSelectedRow(null);
    setNewHoliday(false);
    setUpdateTimeTable(false);
  };

  const handleKeyDown = (event, field) => {
    if (event.key === "Enter") {
      setSearchCriteria({ any });
    }
  };

  return (
    <Box>
      {/* SEARCH & ACTIONS BAR */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={t("header.title.label")} />
      </Box>
      <Box
        backgroundColor={colors.primary[400]}
        display="flex"
        mb="0.5rem"
        p="0.5rem"
        justifyContent="space-between">
        <Grid
          item
          xs={4}
          borderRadius="3px"
          sx={{ display: "flex", alignItems: "center", backgroundColor: colors.grey[900] }}>
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder={t("holiday.placeholder.search")}
            value={any || ""}
            onKeyDown={(e) => handleKeyDown(e, "any")}
            onChange={(e) => setAny(e.target.value)}
          />
          <IconButton
            disabled
            type="button"
            sx={{ p: 1 }}
            onKeyDown={() => setSearchCriteria({ any })}>
            <SearchIcon />
          </IconButton>
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={4} sx={{ display: "flex", alignItems: "center" }}>
          <Chip
            icon={<WarningAmberIcon />}
            label={t("holiday.updateTimetable.warn.label")}
            sx={{
              marginRight: "0.5rem",
              backgroundColor: "#f2d349",
              fontWeight: "bold",
              fontSize: "14px"
            }}
          />
          <ButtonGroup variant="contained" aria-label="outlined primary button group">
            <Button
              variant="contained"
              startIcon={<RestartAltIcon />}
              color="warning"
              sx={{ textTransform: "none" }}
              onClick={() => setUpdateTimeTable(true)}>
              {t("holiday.button.updateTimetable.label")}
            </Button>
            <Button
              variant="contained"
              color="success"
              startIcon={<AddCircleOutlineIcon />}
              onClick={() => setNewHoliday(true)}>
              {t("holiday.button.new.label")}
            </Button>
          </ButtonGroup>
        </Grid>
      </Box>
      <Box
        backgroundColor={colors.primary[400]}
        display="flex"
        mb="0.5rem"
        p="0.5rem"
        justifyContent="space-between">
        <Table
          columnConfig={columns}
          rowActions={rowActions}
          data={data}
          totalResults={total}
          serverPaging={true}
          defaultPagination={pagination}
          paginationChanged={(paging) => setPagination(paging)}
        />
      </Box>
      <AddHolidayDialog
        open={newHoliday}
        onClose={handleClose}
        onConfirm={(res) => {
          const {
            holidayName,
            holidayFrom,
            holidayTo,
            description,
            status,
            excludedClasses,
            notification
          } = res;
          const newHoliday = {
            name: holidayName,
            startDate: holidayFrom,
            endDate: holidayTo,
            excludedClassUuids: excludedClasses.map(({ uuid }) => uuid),
            notification,
            description,
            status
          };
          post(`${UrlPaths.ADMIN}/holidays`, newHoliday)
            .then(() => {
              refreshData();
              handleClose(false);
            })
            .catch(console.debug);
        }}
        buttons={DialogButtons.CANCEL_SAVE}
        title={t("holiday.dialog.addHoliday.title", { holidayName: selectedRow?.name })}
      />
      <UpdateHolidayDialog
        open={!!selectedRow}
        data={selectedRow}
        onClose={handleClose}
        onConfirm={(res) => {
          const { holidayFrom, holidayTo, excludedClasses, status } = res;
          const newHoliday = {
            startDate: holidayFrom,
            endDate: holidayTo,
            excludedClassUuids: excludedClasses.map(({ uuid }) => uuid),
            status
          };
          put(`${UrlPaths.ADMIN}/holidays/${selectedRow.uuid}`, newHoliday)
            .then(() => {
              refreshData();
              handleClose(false);
            })
            .catch(console.debug);
        }}
        buttons={DialogButtons.CANCEL_CONFIRM}
        title={t("holiday.dialog.updateHoliday.title", { holidayName: selectedRow?.name })}
      />
      <Dialog
        open={!!updateTimeTable}
        onClose={handleClose}
        onConfirm={() => {
          post(`${UrlPaths.ADMIN}/timetable/sync`)
            .then(() => {
              refreshData();
              handleClose(false);
            })
            .catch(console.debug);
        }}
        buttons={DialogButtons.CANCEL_CONFIRM}
        headerBgColor="#e65100"
        headerTitleColor="white"
        title={t("holiday.dialog.updateTimeTable.title")}>
        <Typography variant="div" color={colors.grey[100]} sx={{ m: "0 0 5px 0" }}>
          <div
            dangerouslySetInnerHTML={{ __html: t("holiday.dialog.updateTimeTable.description") }}
          />
        </Typography>
      </Dialog>
    </Box>
  );
};

export default Holidays;

import { Delete, Edit } from "@mui/icons-material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  ButtonGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputBase,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useTheme
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button } from "../../../components/button";
import Dialog, { DialogButtons } from "../../../components/dialog";
import Header from "../../../components/header";
import Status from "../../../components/status";
import Table from "../../../components/table";
import UrlPaths from "../../../constants/UrlPaths";
import { deleteById, get, post, put } from "../../../services/HttpClient";
import { branchInfo, userProfile } from "../../../signals";
import { tokens } from "../../../theme";
import { DEFAULT_DATE_FORMAT, formatDate, toDayOfWeekShorten } from "../../../utils/TimeUtil";
import ClassDetailsDialog from "./ClassDetailsDialog";
import { isAdmin } from "../../../utils/UserUtil";
import Autocomplete from "../../../components/autocomplete";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

const CLASS_ID_PREFIX = "Brk-Class-";

const Classes = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();

  const [selectedRow, setSelectedRow] = useState(null);
  const [deletionRow, setDeletionRow] = useState(null);
  const [openClassDialog, setOpenClassDialog] = useState(false);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [searchCriteria, setSearchCriteria] = useState({});
  const [any, setAny] = useState("");
  const [viewType, setViewType] = useState("CURRICULUM");
  const [openNewCurriculumDialog, setOpenNewCurriculumDialog] = useState(false);
  const [newCurriculumn, setNewCurriculumn] = useState({});
  const [openNewProcessDialog, setOpenNewProcessDialog] = useState(false);
  const [newProcess, setNewProcess] = useState({});
  const [showColumns, setShowColumns] = useState([]);
  const [pagination, setPagination] = useState({
    pageNumber: 0,
    pageSize: 30,
    order: "asc",
    orderBy: "className"
  });

  const columns = [
    { field: "id", label: "class.table.field.id.label", sortable: false },
    {
      field: "classDetails.className",
      label: "class.table.field.className.label",
      valueGetter: ({ classDetails }) => (
        <Link to={`details/${classDetails.uuid}`}>
          <Typography sx={{ fontWeight: "bold", color: colors.greenAccent[400] }}>
            {classDetails.className}
          </Typography>
        </Link>
      )
    },
    {
      field: "totalStudents",
      label: "class.table.field.totalStudents.label",
      viewType: "CLASSIC",
      valueGetter: ({ totalStudents }) => totalStudents || 0
    },
    {
      field: "trialStudents",
      label: "class.table.field.trialStudents.label",
      viewType: "CLASSIC",
      valueGetter: ({ trialStudents }) => trialStudents || 0
    },
    {
      field: "learningStudents",
      label: "class.table.field.learningStudents.label",
      viewType: "CLASSIC",
      valueGetter: ({ learningStudents }) => learningStudents || 0
    },
    {
      field: "inDebtStudents",
      label: "class.table.field.debtor.label",
      viewType: "CLASSIC",
      valueGetter: ({ inDebtStudents }) => inDebtStudents || 0
    },
    {
      field: "loaStudents",
      label: "class.table.field.loa.label",
      viewType: "CLASSIC",
      valueGetter: ({ loaStudents }) => loaStudents || 0
    },
    {
      field: "classDetails.classAgeLevel",
      label: "class.table.field.classAgeLevel.label",
      viewType: "CURRICULUM",
      valueGetter: ({ classDetails }) => classDetails.classAgeLevel
    },
    {
      field: "classDetails.schedule",
      label: "class.table.field.teacherNameAndSchedule.label",
      component: ({ classDetails }) => {
        return classDetails.schedule.map(
          ({ teacherName, classroomName, dayOfWeek, timeSlot }, index) => (
            <Typography key={index} fontSize="14px">
              {teacherName} / {timeSlot}{" "}
              {t(`timetable.weekDay.${toDayOfWeekShorten(dayOfWeek)}.label`)} ({classroomName})
            </Typography>
          )
        );
      }
    },
    {
      field: "curriculums",
      label: "class.table.field.curriculums.label",
      viewType: "CURRICULUM",
      component: (rowData) => (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          {(rowData.curriculums || []).map(({ curriculumName, curriculumStartDate }, index) => (
            <Typography key={index} pr="0.5rem">
              &middot;&nbsp;{formatDate(curriculumStartDate)}: {curriculumName}
            </Typography>
          ))}
          <IconButton
            variant="contained"
            color="success"
            onClick={() => {
              setOpenNewCurriculumDialog(true);
              setSelectedRow(rowData);
            }}
            sx={{ display: "flex", width: "fit-content", alignSelf: "center" }}>
            <AddCircleOutlineIcon />
          </IconButton>
        </Box>
      )
    },
    {
      field: "classProcesses",
      label: "class.table.field.classProcesses.label",
      viewType: "CURRICULUM",
      component: (rowData) => (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          {(rowData.classProcesses || []).map(
            ({ classProcessStartDate, classProcessId }, index) => (
              <Typography key={index} pr="0.5rem">
                &middot;&nbsp;{formatDate(classProcessStartDate)}: {classProcessId}
              </Typography>
            )
          )}
          <IconButton
            variant="contained"
            color="success"
            onClick={() => {
              setOpenNewProcessDialog(true);
              setSelectedRow(rowData);
            }}
            sx={{ display: "flex", width: "fit-content", alignSelf: "center" }}>
            <AddCircleOutlineIcon />
          </IconButton>
        </Box>
      )
    },
    {
      field: "classDetails.schedule",
      label: "class.table.field.classStatus.label",
      component: (rowData) => (
        <Status
          prefix="class.status"
          type="CLASS"
          postfix="label"
          status={rowData.classDetails.classStatus}
        />
      )
    }
  ];
  const rowActions = [
    {
      icon: <Edit />,
      tooltip: t("class.table.action.edit.label"),
      action: (rowData) => {
        setOpenClassDialog(true);
        setSelectedRow(rowData);
      },
      showIf: () => isAdmin(userProfile.value)
    },
    {
      icon: <Delete />,
      tooltip: t("class.table.action.delete.label"),
      action: (rowData) => {
        setDeletionRow(rowData);
      },
      showIf: () => isAdmin(userProfile.value)
    }
  ];

  useEffect(() => {
    setShowColumns(columns.filter((col) => !col.viewType || col.viewType === viewType));
  }, [viewType]);

  useEffect(() => {
    if (branchInfo.value.uuid) {
      refreshData();
    }
  }, [pagination]);

  useEffect(() => {
    setPagination({
      pageNumber: 0,
      pageSize: 30,
      order: "asc",
      orderBy: "className"
    });
  }, [searchCriteria, branchInfo.value]);

  const refreshData = () => {
    get(UrlPaths.CLASSES, {
      branchUuid: branchInfo.value.uuid,
      ...pagination,
      ...searchCriteria
    })
      .then((res) => {
        const { pageSize, pageNumber } = pagination;
        setTotal(res.total);
        setData(res.results.map((x, index) => ({ id: pageNumber * pageSize + index + 1, ...x })));
      })
      .catch(console.debug);
  };

  const handleClose = () => {
    setOpenClassDialog(false);
    setOpenNewCurriculumDialog(false);
    setOpenNewProcessDialog(false);
    setSelectedRow(null);
    setDeletionRow(null);
    setNewCurriculumn({});
    setNewProcess({});
  };

  const handleKeyDown = (event, field) => {
    if (event.key === "Enter") {
      setSearchCriteria({ any });
    }
  };

  // const uploadTeacherList = (event) => {
  //   const file = event.target.files[0];
  //   if (file) {
  //     const formData = new FormData();
  //     formData.append("file", file);
  //     formData.append("fileType", "TEACHER");
  //     postWithHeader(
  //       `${UrlPaths.ADMIN}/csv`,
  //       {
  //         [RequestHeaders.CONTENT_TYPE]: "multipart/form-data"
  //       },
  //       formData
  //     )
  //       .then(() => {
  //         refreshData();
  //       })
  //       .catch(console.debug);
  //   }
  // };

  return (
    <Box>
      {/* SEARCH & ACTIONS BAR */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={t("header.title.label")} />
      </Box>
      <Box
        backgroundColor={colors.primary[400]}
        display="flex"
        mb="0.5rem"
        p="0.5rem"
        justifyContent="space-between">
        <Grid container columnSpacing={1} sx={{ display: "flex", alignItems: "center" }}>
          <Grid item xs={4} px="0.5rem">
            <FormControl sx={{ width: "100%" }} size="small">
              <Autocomplete
                placeholder="class.placeholder.teacher"
                onChange={(e, value) => {
                  setSearchCriteria({ ...searchCriteria, teacherUuid: value?.uuid || "" });
                }}
                requestConfig={{
                  url: `${UrlPaths.TEACHERS}?status=ACTIVE&branchUuid=${branchInfo.value.uuid}&orderBy=displayName&order=asc`,
                  label: "niceName",
                  value: "uuid",
                  responseField: "results"
                }}></Autocomplete>
            </FormControl>
          </Grid>
          <Grid
            item
            xs={4}
            borderRadius="3px"
            sx={{ display: "flex", alignItems: "center", backgroundColor: colors.grey[900] }}>
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder={t("class.placeholder.search")}
              value={any || ""}
              onKeyDown={(e) => handleKeyDown(e, "any")}
              onChange={(e) => setAny(e.target.value)}
            />
            <IconButton
              disabled
              type="button"
              sx={{ p: 1 }}
              onKeyDown={() => setSearchCriteria({ any })}>
              <SearchIcon />
            </IconButton>
          </Grid>
          <Grid item xs={4} sx={{ display: "flex", flexDirection: "row-reverse" }}>
            <ButtonGroup variant="contained" aria-label="outlined primary button group">
              {/* <Button
              component="label"
              variant="contained"
              startIcon={<UploadIcon />}
              color="secondary">
              {t("class.button.uploadTeachers")}
              <VisuallyHiddenInput type="file" accept=".csv" onChange={uploadTeacherList} />
            </Button>
            <Button startIcon={<DownloadIcon />} variant="contained" color="neutral" disabled>
              {t("common.button.excel")}
            </Button> */}
              <Button
                variant="contained"
                color="success"
                disabled={!isAdmin(userProfile.value)}
                startIcon={<AddCircleOutlineIcon />}
                onClick={() => setOpenClassDialog(true)}>
                {t("common.button.new")}
              </Button>
            </ButtonGroup>
          </Grid>
        </Grid>
      </Box>
      <Box
        backgroundColor={colors.primary[400]}
        display="flex"
        mb="0.5rem"
        p="0.5rem"
        justifyContent="space-between"
        alignItems="center">
        <Grid item>
          <FormControl
            sx={{
              paddingX: "0.5rem",
              display: "flex",
              flexDirection: "row",
              alignItems: "center"
            }}>
            <FormLabel id="status-label" sx={{ paddingRight: "0.5rem" }}>
              {t("class.viewType.label")}
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="status-label"
              name="row-radio-buttons-group"
              value={viewType}
              onChange={(e) => setViewType(e.target.value)}>
              <FormControlLabel
                value="CLASSIC"
                control={<Radio />}
                label={t("class.classicView.label")}
              />
              <FormControlLabel
                value="CURRICULUM"
                control={<Radio />}
                label={t("class.curriculumnView.label")}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Box>
      <Box
        backgroundColor={colors.primary[400]}
        display="flex"
        mb="0.5rem"
        p="0.5rem"
        justifyContent="space-between"
        alignItems="center">
        <Grid item xs={2} sx={{ display: "flex", alignItems: "center" }}>
          <Typography pr="0.5rem" color="#06389d" fontWeight="bold">
            {t("class.totalStudents.label")}
          </Typography>
          <Typography pr="0.5rem" color="#06389d" fontWeight="bold">
            {data.reduce((count, { totalStudents }) => count + (totalStudents || 0), 0)}
          </Typography>
        </Grid>
        <Grid item xs={2} sx={{ display: "flex", alignItems: "center" }}>
          <Typography pr="0.5rem" color="#b97f11" fontWeight="bold">
            {t("class.trialStudents.label")}
          </Typography>
          <Typography pr="0.5rem" color="#b97f11" fontWeight="bold">
            {data.reduce((count, { trialStudents }) => count + (trialStudents || 0), 0)}
          </Typography>
        </Grid>
        <Grid item xs={2} sx={{ display: "flex", alignItems: "center" }}>
          <Typography pr="0.5rem" color="#0e8416" fontWeight="bold">
            {t("class.learningStudents.label")}
          </Typography>
          <Typography pr="0.5rem" color="#0e8416" fontWeight="bold">
            {data.reduce((count, { learningStudents }) => count + (learningStudents || 0), 0)}
          </Typography>
        </Grid>
        <Grid item xs={2} sx={{ display: "flex", alignItems: "center" }}>
          <Typography pr="0.5rem" color="#d6420a" fontWeight="bold">
            {t("class.inDebtStudents.label")}
          </Typography>
          <Typography pr="0.5rem" color="#d6420a" fontWeight="bold">
            {data.reduce((count, { inDebtStudents }) => count + (inDebtStudents || 0), 0)}
          </Typography>
        </Grid>
        <Grid item xs={2} sx={{ display: "flex", alignItems: "center" }}>
          <Typography pr="0.5rem" color="#101010" fontWeight="bold">
            {t("class.loaStudents.label")}
          </Typography>
          <Typography pr="0.5rem" color="#101010" fontWeight="bold">
            {data.reduce((count, { loaStudents }) => count + (loaStudents || 0), 0)}
          </Typography>
        </Grid>
      </Box>
      <Box
        backgroundColor={colors.primary[400]}
        display="flex"
        mb="0.5rem"
        p="0.5rem"
        justifyContent="space-between">
        <Table
          columnConfig={columns}
          rowActions={rowActions}
          showColumns={showColumns}
          data={data}
          totalResults={total}
          serverPaging={true}
          defaultPagination={pagination}
          paginationChanged={(paging) => setPagination(paging)}
        />
      </Box>
      <ClassDetailsDialog
        open={openClassDialog}
        editable={!!selectedRow}
        data={selectedRow?.classDetails}
        onClose={handleClose}
        onConfirm={(newClass, formData) => {
          if (newClass) {
            post(UrlPaths.CLASSES, {
              ...formData,
              classStatus: formData.status,
              classType: "ONLINE",
              classId: CLASS_ID_PREFIX + new Date().getTime()
            })
              .then(() => {
                refreshData();
                handleClose();
              })
              .catch(console.debug);
          } else {
            const { classType, classId } = selectedRow;
            put(`${UrlPaths.CLASSES}/${selectedRow?.classDetails.uuid}`, {
              ...formData,
              classStatus: formData.status,
              classType,
              classId
            })
              .then(() => {
                refreshData();
                handleClose();
              })
              .catch(console.debug);
          }
        }}
        buttons={DialogButtons.CANCEL_SAVE}
        title={t(
          `class.dialog.${openClassDialog && !selectedRow ? "newClass" : "updateClass"}.title`
        )}
      />
      <Dialog
        open={!!deletionRow}
        data={deletionRow}
        onClose={handleClose}
        onConfirm={() => {
          deleteById(UrlPaths.CLASSES, deletionRow?.classDetails.uuid)
            .then(() => {
              refreshData();
              handleClose();
            })
            .catch(console.debug);
        }}
        buttons={DialogButtons.CANCEL_DELETE}
        headerBgColor="#d32f2f"
        headerTitleColor="white"
        title={t("class.dialog.deleteClass.title")}>
        <Typography variant="h5" color={colors.grey[100]} sx={{ m: "0 0 5px 0" }}>
          <div
            style={{ marginBottom: "1rem" }}
            dangerouslySetInnerHTML={{
              __html: t("class.dialog.deleteClass.confirmTitle", {
                className: deletionRow?.className
              })
            }}></div>
          <div>{t("class.dialog.deleteClass.description")}</div>
        </Typography>
      </Dialog>
      <Dialog
        open={!!openNewCurriculumDialog}
        onClose={handleClose}
        onConfirm={() => {
          if (newCurriculumn.curriculumUuid && newCurriculumn.curriculumStartDate) {
            put(`${UrlPaths.CLASSES}/${selectedRow.classDetails.uuid}`, {
              ...selectedRow.classDetails,
              curriculum: newCurriculumn
            })
              .then(() => {
                refreshData();
                handleClose();
              })
              .catch(console.debug);
          }
        }}
        buttons={[
          {
            type: "discard",
            id: "cancel",
            label: "common.button.cancel",
            variant: "outlined"
          },
          {
            type: "confirm",
            id: "save",
            label: "common.button.save",
            disabled: !newCurriculumn.curriculumUuid || !newCurriculumn.curriculumStartDate
          }
        ]}
        title={t("class.dialog.newCurriculum.title")}>
        <FormControl
          sx={{ width: "100%", minWidth: "200px", paddingX: "0.5rem", marginBottom: "1rem" }}
          size="small">
          <Autocomplete
            placeholder="class.newCurriculum.package.label"
            value={newCurriculumn.packageValue || { uuid: "", packageName: "" }}
            onChange={(e, value) => {
              setNewCurriculumn({
                ...newCurriculumn,
                curriculumUuid: value?.uuid,
                curriculumName: value?.packageName,
                packageValue: value
              });
            }}
            requestConfig={{
              url: `${UrlPaths.ADMIN}/packages?branchUuid=${branchInfo.value.uuid}&status=ACTIVE&types=ITEM`,
              label: "packageName",
              value: "uuid"
            }}></Autocomplete>
        </FormControl>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            id="start-date"
            format={DEFAULT_DATE_FORMAT}
            label={t("class.newCurriculum.startDate.label")}
            onChange={(e) =>
              setNewCurriculumn({
                ...newCurriculumn,
                curriculumStartDate: e
              })
            }
            sx={{ width: "100%", paddingX: "0.5rem" }}
            slotProps={{
              textField: { size: "small" },
              popper: {
                style: {
                  zIndex: 15003
                }
              }
            }}
          />
        </LocalizationProvider>
      </Dialog>
      <Dialog
        open={!!openNewProcessDialog}
        onClose={handleClose}
        onConfirm={() => {
          if (newProcess.classProcessId && newProcess.classProcessStartDate) {
            put(`${UrlPaths.CLASSES}/${selectedRow.classDetails.uuid}`, {
              ...selectedRow.classDetails,
              classProcess: newProcess
            })
              .then(() => {
                refreshData();
                handleClose();
              })
              .catch(console.debug);
          }
        }}
        buttons={[
          {
            type: "discard",
            id: "cancel",
            label: "common.button.cancel",
            variant: "outlined"
          },
          {
            type: "confirm",
            id: "save",
            label: "common.button.save",
            disabled: !newProcess.classProcessId || !newProcess.classProcessStartDate
          }
        ]}
        title={t("class.dialog.newProcess.title")}>
        <FormControl sx={{ width: "100%", minWidth: "200px", marginBottom: "1rem" }} size="small">
          <TextField
            margin="dense"
            id="name"
            label={t("class.newProcess.processId.label")}
            onChange={(e) =>
              setNewProcess({
                ...newProcess,
                classProcessId: e.target.value
              })
            }
            fullWidth
            size="small"
            variant="outlined"
            sx={{ paddingX: "0.5rem" }}
          />
        </FormControl>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            id="start-date"
            format={DEFAULT_DATE_FORMAT}
            label={t("class.newProcess.startDate.label")}
            onChange={(e) =>
              setNewProcess({
                ...newProcess,
                classProcessStartDate: e
              })
            }
            sx={{ width: "100%", paddingX: "0.5rem" }}
            slotProps={{
              textField: { size: "small" },
              popper: {
                style: {
                  zIndex: 15003
                }
              }
            }}
          />
        </LocalizationProvider>
      </Dialog>
    </Box>
  );
};

export default Classes;

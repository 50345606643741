import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useTheme
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Autocomplete from "../../../components/autocomplete";
import Header from "../../../components/header";
import Status from "../../../components/status";
import Table from "../../../components/table";
import UrlPaths from "../../../constants/UrlPaths";
import { get, post } from "../../../services/HttpClient";
import { branchInfo } from "../../../signals";
import { tokens } from "../../../theme";
import { DEFAULT_DATE_FORMAT, formatDate, getDayOfWeek } from "../../../utils/TimeUtil";
import { getStudentDetailLabel } from "../../../utils/UserUtil";
import ProgressCircle from "../../../components/progress/ProgressCircle";
import CheckinListDialog from "./CheckinListDialog";

const DEFAULT_SEARCH_CRITERIA = {
  studentUuid: "",
  teacherUuid: "",
  status: "",
  from: moment().subtract(1, "weeks"),
  to: moment()
};

const AttendanceHistories = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();

  const [data, setData] = useState([]);
  const [searchCriteria, setSearchCriteria] = useState(DEFAULT_SEARCH_CRITERIA);
  const [total, setTotal] = useState(0);
  const [checkinList, setCheckinList] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [pagination, setPagination] = useState({
    pageNumber: 0,
    pageSize: 30,
    order: "desc",
    orderBy: "scheduleDate"
  });

  useEffect(() => {
    if (branchInfo.value.uuid) {
      refreshData();
    }
  }, [pagination]);

  useEffect(() => {
    setPagination({
      pageNumber: 0,
      pageSize: 30,
      order: "desc",
      orderBy: "scheduleDate"
    });
  }, [searchCriteria, branchInfo.value]);

  const columns = [
    { field: "id", label: "attendanceHistory.table.field.id.label" },
    {
      field: "studentName",
      label: "attendanceHistory.table.field.studentName.label"
    },
    {
      field: "teacherName",
      label: "attendanceHistory.table.field.teacherName.label"
    },
    {
      field: "className",
      label: "attendanceHistory.table.field.className.label"
    },
    {
      field: "classroomName",
      label: "attendanceHistory.table.field.classroomName.label"
    },
    {
      field: "scheduleDate",
      label: "attendanceHistory.table.field.scheduleDate.label",
      valueGetter: ({ scheduleDate, weekDay }) =>
        `${t(`timetable.weekDay.${getDayOfWeek(weekDay)}.label`)} - ${formatDate(scheduleDate)}`
    },
    {
      field: "timeSlot",
      label: "attendanceHistory.table.field.timeSlot.label"
    },
    {
      field: "status",
      label: "attendanceHistory.table.field.status.label",
      align: "center",
      component: (rowData) => (
        <Status prefix="attendance.historyStatus" status={rowData.status} type="HISTORY" />
      )
    }
  ];

  const refreshData = () => {
    const { studentValue, teacherValue, classValue, ...criteria } = searchCriteria;
    get(`${UrlPaths.STUDENTS}/attendance/histories`, {
      ...pagination,
      ...criteria,
      branchUuid: branchInfo.value.uuid
    })
      .then((res) => {
        const { pageSize, pageNumber } = pagination;
        const histories = res.results.map((x, index) => ({
          id: pageNumber * pageSize + index + 1,
          ...x,
          hideCheckbox: !x.isClassScheduleActive || x.status === "ABORTED"
        }));
        setTotal(res.total);
        setData(histories);
      })
      .catch(console.debug);
  };

  const handleClose = () => {
    setSelectedRow(null);
    setCheckinList([]);
  };

  const handleCheckin = ({ checkedInList, absenceList, loaList }) => {
    Promise.all([
      post(`${UrlPaths.STUDENTS}/attendance`, {
        attendanceUpdates: checkedInList.map(({ historyUuid, note }) => ({
          historyUuid: historyUuid,
          note
        })),
        status: "CHECKED_IN"
      }),
      post(`${UrlPaths.STUDENTS}/attendance`, {
        attendanceUpdates: absenceList.map(({ historyUuid, note }) => ({
          historyUuid: historyUuid,
          note
        })),
        status: "ABSENCE"
      }),
      post(`${UrlPaths.STUDENTS}/attendance`, {
        attendanceUpdates: loaList.map(({ historyUuid, note }) => ({
          historyUuid: historyUuid,
          note
        })),
        status: "LEAVE_OF_ABSENCE"
      })
    ])
      .then(() => {
        refreshData();
        handleClose();
      })
      .catch(console.debug);
  };

  const handleCriteriaChange = (field, value) => {
    setSearchCriteria({ ...searchCriteria, [field]: value || undefined });
  };
  const getCount = (attendanceStatus) => {
    const total = data?.length || 0;
    const count = data.filter(({ status }) => attendanceStatus === status)?.length || 0;
    return { total, count, percentage: count / (total || 1) };
  };
  return (
    <Box>
      {/* SEARCH & ACTIONS BAR */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={t("header.title.label")} />
      </Box>

      <Box
        backgroundColor={colors.primary[400]}
        mb="0.5rem"
        p="0.5rem"
        justifyContent="space-between">
        <Grid container mb="1rem" columnSpacing={1}>
          <Grid item xs={4}>
            <FormControl sx={{ width: "100%", paddingX: "0.5rem" }} size="small">
              <Autocomplete
                placeholder="attendanceHistory.placeholder.student"
                value={
                  searchCriteria.studentValue || {
                    uuid: "",
                    displayName: getStudentDetailLabel(null)
                  }
                }
                onChange={(e, value) => {
                  setSearchCriteria({
                    ...searchCriteria,
                    studentUuid: value?.uuid || "",
                    studentValue: value || {}
                  });
                }}
                requestConfig={{
                  url: `${UrlPaths.STUDENTS}?branchUuid=${branchInfo.value.uuid}`,
                  responseField: "results",
                  label: (student) => getStudentDetailLabel(student),
                  value: "uuid",
                  onQueryChange: (value) => (value ? `&any=${value}` : "")
                }}></Autocomplete>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl sx={{ width: "100%", paddingX: "0.5rem" }} size="small">
              <Autocomplete
                placeholder="attendanceHistory.placeholder.teacher"
                value={
                  searchCriteria.teacherValue || {
                    uuid: "",
                    niceName: ""
                  }
                }
                onChange={(e, value) => {
                  setSearchCriteria({
                    ...searchCriteria,
                    teacherUuid: value.uuid,
                    teacherValue: value
                  });
                }}
                requestConfig={{
                  url: `${UrlPaths.TEACHERS}?status=ACTIVE&branchUuid=${branchInfo.value.uuid}`,
                  responseField: "results",
                  label: "niceName",
                  value: "uuid",
                  onQueryChange: (value) => (value ? `&any=${value}` : "")
                }}></Autocomplete>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl sx={{ width: "100%", paddingX: "0.5rem" }} size="small">
              <InputLabel id="studentlist-status-label" color="neutral">
                {t("attendanceHistory.placeholder.status")}
              </InputLabel>
              <Select
                labelId="student-status-label"
                id="student-status"
                label={t("attendanceHistory.placeholder.status.label")}
                value={searchCriteria.status || ""}
                onChange={(e) => handleCriteriaChange("status", e.target.value)}>
                <MenuItem value="">{t("attendance.historyStatus.ALL")}</MenuItem>
                <MenuItem value="ACTIVE">{t("attendance.historyStatus.ACTIVE")}</MenuItem>
                <MenuItem value="LEARNING">{t("attendance.historyStatus.LEARNING")}</MenuItem>
                <MenuItem value="CHECKED_IN">{t("attendance.historyStatus.CHECKED_IN")}</MenuItem>
                <MenuItem value="ABSENCE">{t("attendance.historyStatus.ABSENCE")}</MenuItem>
                <MenuItem value="LEAVE_OF_ABSENCE">
                  {t("attendance.historyStatus.LEAVE_OF_ABSENCE")}
                </MenuItem>
                <MenuItem value="MAKE_UP_UNCOMPLETED">
                  {t("attendance.historyStatus.MAKE_UP_UNCOMPLETED")}
                </MenuItem>
                <MenuItem value="MAKE_UP_COMPLETED">
                  {t("attendance.historyStatus.MAKE_UP_COMPLETED")}
                </MenuItem>
                <MenuItem value="ABORTED">{t("attendance.historyStatus.ABORTED")}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container mb="1rem" columnSpacing={1}>
          {" "}
          <Grid item xs={4}>
            <FormControl sx={{ width: "100%", paddingX: "0.5rem" }} size="small">
              <Autocomplete
                placeholder="attendanceHistory.placeholder.class"
                value={
                  searchCriteria.classValue || {
                    uuid: "",
                    className: ""
                  }
                }
                onChange={(e, value) => {
                  setSearchCriteria({
                    ...searchCriteria,
                    classUuid: value?.uuid,
                    classValue: value
                  });
                }}
                requestConfig={{
                  url: `${UrlPaths.CLASSES}?branchUuid=${branchInfo.value.uuid}`,
                  label: "className",
                  value: "uuid",
                  responseField: (res) => res.results.map(({ classDetails }) => classDetails),
                  onQueryChange: (value) => (value ? `&any=${value}` : "")
                }}></Autocomplete>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                id="start-date"
                format={DEFAULT_DATE_FORMAT}
                label={t("attendanceHistory.placeholder.from")}
                value={searchCriteria.from}
                onChange={(e) => setSearchCriteria({ ...searchCriteria, from: e })}
                sx={{ width: "100%", paddingX: "0.5rem" }}
                slotProps={{
                  textField: { size: "small" },
                  popper: {
                    style: {
                      zIndex: 15003
                    }
                  }
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={4}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                id="start-date"
                format={DEFAULT_DATE_FORMAT}
                label={t("attendanceHistory.placeholder.to")}
                value={searchCriteria.to}
                onChange={(e) => setSearchCriteria({ ...searchCriteria, to: e })}
                sx={{ width: "100%", paddingX: "0.5rem" }}
                slotProps={{
                  textField: { size: "small" },
                  popper: {
                    style: {
                      zIndex: 15003
                    }
                  }
                }}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
      </Box>
      <Box
        backgroundColor={colors.primary[400]}
        display="flex"
        mb="0.5rem"
        p="0.5rem"
        justifyContent="space-between"
        alignItems="center">
        <Grid item xs={3} sx={{ display: "flex", alignItems: "center" }}>
          <Typography pr="0.5rem" color="#0e8416" fontWeight="bold">
            {t("attendance.checkinReport.onTime.label", {
              count: getCount("CHECKED_IN").count,
              total: getCount("CHECKED_IN").total
            })}
          </Typography>
          <ProgressCircle
            progress={getCount("CHECKED_IN").percentage}
            circleColor="#dbd7d7"
            textColor="#0e8416"
            progressingColor="green"></ProgressCircle>
        </Grid>
        <Grid item xs={3} sx={{ display: "flex", alignItems: "center" }}>
          <Typography pr="0.5rem" color="#d32f2f" fontWeight="bold">
            {t("attendance.checkinReport.absence.label", {
              count: getCount("ABSENCE").count,
              total: getCount("ABSENCE").total
            })}
          </Typography>
          <ProgressCircle
            progress={getCount("ABSENCE").percentage}
            circleColor="#dbd7d7"
            textColor="#d32f2f"
            progressingColor="#d32f2f"></ProgressCircle>
        </Grid>
        <Grid item xs={3} sx={{ display: "flex", alignItems: "center" }}>
          <Typography pr="0.5rem" color="#06389d" fontWeight="bold">
            {t("attendance.checkinReport.makeupCompleted.label", {
              count: getCount("MAKE_UP_COMPLETED").count,
              total: getCount("MAKE_UP_COMPLETED").total
            })}
          </Typography>
          <ProgressCircle
            progress={getCount("MAKE_UP_COMPLETED").percentage}
            circleColor="#dbd7d7"
            textColor="#06389d"
            progressingColor="#0288d1"></ProgressCircle>
        </Grid>
        <Grid item xs={3} sx={{ display: "flex", alignItems: "center" }}>
          <Typography pr="0.5rem" color="#ed6c02" fontWeight="bold">
            {t("attendance.checkinReport.makeupUnCompleted.label", {
              count: getCount("MAKE_UP_UNCOMPLETED").count,
              total: getCount("MAKE_UP_UNCOMPLETED").total
            })}
          </Typography>
          <ProgressCircle
            progress={getCount("MAKE_UP_UNCOMPLETED").percentage}
            circleColor="#dbd7d7"
            textColor="#ed6c02"
            progressingColor="#ed6c02"></ProgressCircle>
        </Grid>
        <Grid item xs={3} sx={{ display: "flex", alignItems: "center" }}>
          <Typography pr="0.5rem" color="#101010" fontWeight="bold">
            {t("attendance.checkinReport.loa.label", {
              count: getCount("LEAVE_OF_ABSENCE").count,
              total: getCount("LEAVE_OF_ABSENCE").total
            })}
          </Typography>
          <ProgressCircle
            progress={getCount("LEAVE_OF_ABSENCE").percentage}
            circleColor="#dbd7d7"
            textColor="#101010"
            progressingColor="#3e3e41"></ProgressCircle>
        </Grid>
      </Box>
      <Box
        backgroundColor={colors.primary[400]}
        display="flex"
        mb="0.5rem"
        p="0.5rem"
        justifyContent="space-between">
        <Table
          checkboxSelection
          checkBoxSelectLabel="attendance.table.button.checkin.label"
          confirmSelections={setCheckinList}
          columnConfig={columns}
          data={data}
          totalResults={total}
          serverPaging={true}
          defaultPagination={pagination}
          paginationChanged={(paging) => setPagination(paging)}
        />
      </Box>
      <CheckinListDialog
        checkinList={checkinList}
        closeCheckinDialog={handleClose}
        confirmCheckinDialog={handleCheckin}
      />
    </Box>
  );
};

export default AttendanceHistories;

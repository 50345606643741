import AssessmentIcon from "@mui/icons-material/Assessment";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import AssignmentLateIcon from "@mui/icons-material/AssignmentLate";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CategoryIcon from "@mui/icons-material/Category";
import CelebrationIcon from "@mui/icons-material/Celebration";
import ChecklistRtlIcon from "@mui/icons-material/ChecklistRtl";
import ClassIcon from "@mui/icons-material/Class";
import GroupsIcon from "@mui/icons-material/Groups";
import HomeIcon from "@mui/icons-material/Home";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import PostAddIcon from "@mui/icons-material/PostAdd";
import ReceiptIcon from "@mui/icons-material/Receipt";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import SchoolIcon from "@mui/icons-material/School";
import SettingsIcon from "@mui/icons-material/Settings";
import SummarizeIcon from "@mui/icons-material/Summarize";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import BlockIcon from "@mui/icons-material/Block";

import DisabledByDefaultIcon from "@mui/icons-material/DisabledByDefault";

const MENU_ITEMS = [
  {
    title: "home",
    to: "/",
    icon: <HomeIcon />,
    rolesAllowed: ["ALL"]
  },
  {
    title: "academic",
    icon: <SchoolIcon />,
    rolesAllowed: ["ALL"],
    submenu: [
      {
        title: "academic.class",
        to: "/academic/classes",
        icon: <ClassIcon />,
        // Only classes belong to the teacher
        rolesAllowed: [
          "ADMINISTRATOR",
          "OW_FINANCE",
          "OW_CUSTOMER_SERVICE",
          "T_NATIVE",
          "T_FOREIGNER",
          "T_ASSISTANT"
        ]
      },
      {
        title: "academic.timetable",
        to: "/academic/timetable",
        icon: <CalendarMonthIcon />,
        // Only classes belong to the teacher
        rolesAllowed: [
          "ADMINISTRATOR",
          "OW_FINANCE",
          "OW_CUSTOMER_SERVICE",
          "T_NATIVE",
          "T_FOREIGNER",
          "T_ASSISTANT"
        ]
      },
      {
        title: "academic.holidays",
        to: "/academic/holidays",
        icon: <CelebrationIcon />,
        rolesAllowed: ["ADMINISTRATOR", "OW_FINANCE", "OW_CUSTOMER_SERVICE"]
      },
      {
        title: "academic.attendance",
        to: "/academic/attendance",
        icon: <ChecklistRtlIcon />,
        // Only classes belong to the teacher
        rolesAllowed: [
          "ADMINISTRATOR",
          "OW_FINANCE",
          "OW_CUSTOMER_SERVICE",
          "T_NATIVE",
          "T_FOREIGNER",
          "T_ASSISTANT"
        ]
      },
      {
        title: "academic.attendanceHistories",
        to: "/academic/attendance-histories",
        icon: <HistoryEduIcon />,
        // Only classes belong to the teacher
        rolesAllowed: [
          "ADMINISTRATOR",
          "OW_FINANCE",
          "OW_CUSTOMER_SERVICE",
          "T_NATIVE",
          "T_FOREIGNER",
          "T_ASSISTANT"
        ]
      },
      {
        title: "academic.students",
        to: "/academic/students",
        icon: <GroupsIcon />,
        rolesAllowed: ["ADMINISTRATOR", "OW_FINANCE", "OW_CUSTOMER_SERVICE"]
      },
      {
        title: "academic.makeupClass",
        to: "/academic/makeup-class",
        icon: <AutoStoriesIcon />,
        // Only classes belong to the teacher
        rolesAllowed: [
          "ADMINISTRATOR",
          "OW_FINANCE",
          "OW_CUSTOMER_SERVICE",
          "T_NATIVE",
          "T_FOREIGNER",
          "T_ASSISTANT"
        ]
      },
      {
        title: "academic.loaStudents",
        to: "/academic/loa-students",
        icon: <BlockIcon />,
        rolesAllowed: ["ADMINISTRATOR", "OW_FINANCE", "OW_CUSTOMER_SERVICE"]
      },
      {
        title: "academic.disabledStudents",
        to: "/academic/disabled-students",
        icon: <DisabledByDefaultIcon />,
        rolesAllowed: ["ADMINISTRATOR", "OW_FINANCE", "OW_CUSTOMER_SERVICE"]
      }
    ]
  },
  {
    title: "finance",
    icon: <MonetizationOnIcon />,
    rolesAllowed: ["ADMINISTRATOR", "OW_FINANCE", "OW_CUSTOMER_SERVICE"],
    submenu: [
      {
        title: "finance.newPaymentContract",
        to: "/finance/payment-contract/new",
        icon: <PostAddIcon />,
        rolesAllowed: ["ADMINISTRATOR", "OW_FINANCE", "OW_CUSTOMER_SERVICE"]
      },
      {
        title: "finance.invoices",
        to: "/finance/invoices",
        icon: <ReceiptLongIcon />,
        rolesAllowed: ["ADMINISTRATOR", "OW_FINANCE", "OW_CUSTOMER_SERVICE"]
      },
      {
        title: "finance.feeReport",
        to: "/finance/fee-report",
        icon: <AssignmentLateIcon />,
        rolesAllowed: ["ADMINISTRATOR", "OW_FINANCE", "OW_CUSTOMER_SERVICE"]
      },
      {
        title: "finance.contractHistories",
        to: "/finance/contract-histories",
        icon: <ReceiptIcon />,
        rolesAllowed: ["ADMINISTRATOR", "OW_FINANCE", "OW_CUSTOMER_SERVICE"]
      }
    ]
  },
  {
    title: "reports",
    disabled: true,
    icon: <AssessmentIcon />,
    rolesAllowed: ["ADMINISTRATOR", "OW_FINANCE", "OW_CUSTOMER_SERVICE"],
    submenu: [
      {
        title: "reports.classReports",
        to: "/reports/class-reports",
        icon: <SummarizeIcon />,
        // TODO rework later: Only reports belong to the teacher
        rolesAllowed: ["ADMINISTRATOR", "OW_FINANCE", "OW_CUSTOMER_SERVICE"]
      },
      {
        title: "reports.financialReports",
        to: "/reports/financial-reports",
        icon: <ReceiptIcon />,
        rolesAllowed: ["ADMINISTRATOR", "OW_FINANCE", "OW_CUSTOMER_SERVICE"]
      }
    ]
  },
  {
    title: "settings",
    icon: <SettingsIcon />,
    rolesAllowed: ["ADMINISTRATOR", "OW_FINANCE", "OW_CUSTOMER_SERVICE"],
    submenu: [
      {
        title: "settings.staffs",
        to: "/settings/staffs",
        icon: <GroupsIcon />,
        rolesAllowed: ["ADMINISTRATOR", "OW_FINANCE", "OW_CUSTOMER_SERVICE"]
      },
      {
        title: "settings.products",
        to: "/settings/products",
        icon: <CategoryIcon />,
        rolesAllowed: ["ADMINISTRATOR", "OW_FINANCE", "OW_CUSTOMER_SERVICE"]
      },
      {
        title: "settings.teachers",
        to: "/settings/teachers",
        icon: <AssignmentIndIcon />,
        rolesAllowed: ["ADMINISTRATOR"]
      },
      {
        title: "settings.classrooms",
        to: "/settings/classrooms",
        icon: <MeetingRoomIcon />,
        rolesAllowed: ["ADMINISTRATOR", "OW_FINANCE", "OW_CUSTOMER_SERVICE"]
      }
    ]
  }
];

export default MENU_ITEMS;

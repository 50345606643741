import { Grid, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Dialog from "../../../components/dialog";

const INITIAL_DATA = {
  studentName: "",
  numberOfSessions: 0,
  note: ""
};

const UpdateSessionsDialog = ({ data, onConfirm, ...props }) => {
  const { t } = useTranslation();

  const [updateSessions, setUpdateSessions] = useState(INITIAL_DATA);

  useEffect(() => {
    setUpdateSessions(data || INITIAL_DATA);
  }, [data]);

  const updateFieldValue = (fieldName, fieldValue) => {
    setUpdateSessions({ ...updateSessions, [fieldName]: fieldValue });
  };

  const confirmSaveClass = () => {
    onConfirm(updateSessions);
  };

  return (
    <Dialog {...props} onConfirm={confirmSaveClass}>
      <Grid
        container
        flexDirection="column"
        justifyContent="center"
        sx={{
          width: "100%"
        }}
        rowSpacing={4}>
        <Grid item>
          <TextField
            margin="dense"
            id="name"
            label={t("updateSessions.placeholder.studentName")}
            value={updateSessions.studentName || ""}
            disabled
            InputLabelProps={{ shrink: true }}
            onChange={(e) => updateFieldValue("studentName", e.target.value)}
            fullWidth
            size="small"
            variant="outlined"
            sx={{ paddingX: "0.5rem", margin: 0 }}
          />
        </Grid>
        <Grid item>
          <TextField
            margin="dense"
            id="name"
            autoFocus
            label={t("updateSessions.placeholder.sessions", {
              currentQuantity: updateSessions.registeredQuantity
            })}
            onChange={(e) => updateFieldValue("numberOfSessions", e.target.value)}
            fullWidth
            size="small"
            variant="outlined"
            sx={{ paddingX: "0.5rem", margin: 0 }}
          />
        </Grid>
        <Grid item>
          <TextField
            margin="dense"
            id="name"
            label={t("updateSessions.placeholder.note")}
            value={updateSessions.note || ""}
            onChange={(e) => updateFieldValue("note", e.target.value)}
            fullWidth
            size="small"
            variant="outlined"
            sx={{ paddingX: "0.5rem", margin: 0 }}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default UpdateSessionsDialog;

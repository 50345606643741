import { FormControl, TextField } from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Autocomplete from "../../../components/autocomplete";
import Dialog, { DialogButtons } from "../../../components/dialog";
import UrlPaths from "../../../constants/UrlPaths";
import { branchInfo } from "../../../signals";

const MakeupClassDialog = ({ open, data, handleClose, confirmMakeUpClass }) => {
  const { t } = useTranslation();

  const [makeUpRequest, setMakeUpRequest] = useState({ ...data, teacherUuid: "" });

  useEffect(() => {
    setMakeUpRequest({ ...data, teacherUuid: "" });
  }, [data]);

  return (
    <Dialog
      id="makeup-class"
      open={open}
      data={data}
      onClose={handleClose}
      onConfirm={() => confirmMakeUpClass(makeUpRequest)}
      buttons={DialogButtons.CANCEL_CONFIRM}
      title={t("attendance.makeupClass.title")}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DateTimePicker
          id="makeup-date"
          format="DD/MM/YYYY HH:mm"
          ampm={false}
          label={t("attendance.makeupClass.placeholder.makeupTime")}
          sx={{
            width: "100%"
          }}
          onChange={(e) => {
            setMakeUpRequest({ ...makeUpRequest, scheduleDate: e });
          }}
          slotProps={{
            textField: { size: "small" },
            popper: {
              style: {
                zIndex: 15003
              }
            }
          }}
        />
      </LocalizationProvider>
      <FormControl sx={{ width: "100%", marginY: "1rem" }} size="small">
        <Autocomplete
          placeholder="attendance.makeupClass.placeholder.makeupTeacher"
          onChange={(e, value) => {
            setMakeUpRequest({ ...makeUpRequest, teacherUuid: value?.uuid || "" });
          }}
          requestConfig={{
            url: `${UrlPaths.TEACHERS}?status=ACTIVE&branchUuid=${branchInfo.value.uuid}&orderBy=displayName&order=asc`,
            label: "displayName",
            value: "uuid",
            responseField: "results"
          }}></Autocomplete>
      </FormControl>
      <TextField
        id="outlined-basic"
        label={t("attendance.makeupClass.placeholder.note")}
        onChange={(e) => {
          setMakeUpRequest({ ...makeUpRequest, note: e.target.value || "" });
        }}
        variant="outlined"
        sx={{ width: "100%" }}
        size="small"
      />
    </Dialog>
  );
};

export default MakeupClassDialog;

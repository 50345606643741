import { Checkbox, FormControl, FormControlLabel, FormLabel, Grid, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { useTranslation } from "react-i18next";
import Dialog, { DialogButtons } from "../../../components/dialog";
import { DEFAULT_DATE_FORMAT, formatDate } from "../../../utils/TimeUtil";
import { get, getWithoutLoading } from "../../../services/HttpClient";
import UrlPaths from "../../../constants/UrlPaths";
import Autocomplete from "../../../components/autocomplete";
import { branchInfo } from "../../../signals";
import { useEffect, useState } from "react";

export const INITIAL_REGISTERED_CLASS = {
  classUuid: "",
  classValue: {
    uuid: "",
    className: ""
  },
  registeredSessions: 0,
  startDate: moment(),
  endDate: moment(),
  description: ""
};

const ClassSummaryDialog = ({ onClose, paymentInfo, onConfirm, rowData, invoiceUuid }) => {
  const { t } = useTranslation();

  const [selectedRow, setSelectedRow] = useState(INITIAL_REGISTERED_CLASS);
  const [potentialTimeline, setPotentialTimeline] = useState([]);
  const [selectedTimelineIndexes, setSelectedTimelineIndexes] = useState([]);

  useEffect(() => {
    setSelectedRow(rowData);
  }, [rowData.index]);

  const handleRowChange = (updatedData) => {
    const updatedRow = { ...selectedRow, ...updatedData };
    setSelectedRow({ ...updatedRow });
  };

  const resetDialogForm = () => {
    setSelectedRow(INITIAL_REGISTERED_CLASS);
    setPotentialTimeline([]);
    setSelectedTimelineIndexes([]);
  };

  return (
    <Dialog
      open={selectedRow.index != null && selectedRow.index !== undefined}
      onClose={() => {
        onClose();
        resetDialogForm();
      }}
      onConfirm={() => {
        onConfirm({
          paymentRequest: {
            ...paymentInfo,
            sessionsAddedUuids:
              paymentInfo.contractType === "VIRTUAL_CONTRACT"
                ? potentialTimeline
                    .filter((x, index) => selectedTimelineIndexes.includes(index))
                    .map(({ uuid }) => uuid)
                : null
          },
          rowUpdated: selectedRow
        });
        resetDialogForm();
      }}
      buttons={DialogButtons.CANCEL_CONFIRM}
      title={t("paymentContract.dialog.addClass.title")}>
      <Grid
        container
        p="1rem"
        flexDirection="column"
        justifyContent="center"
        sx={{
          width: "100%"
        }}
        rowSpacing={4}>
        <Grid item>
          <FormControl sx={{ width: "100%", minWidth: "150px", paddingX: "0.5rem" }} size="small">
            <Autocomplete
              placeholder="paymentContract.placeholder.classSearch"
              value={selectedRow.classValue}
              disabled={invoiceUuid && invoiceUuid !== "new"}
              onChange={(e, value) => {
                if (value) {
                  get(
                    `${UrlPaths.CLASSES}/${value.uuid}/potentialTimeline?studentUuid=${paymentInfo.studentUuid}&registeredSessions=${selectedRow.registeredSessions}&contractType=${paymentInfo.contractType}`
                  )
                    .then((res) => {
                      if (paymentInfo.contractType === "VIRTUAL_CONTRACT") {
                        setPotentialTimeline(res);
                        setSelectedTimelineIndexes([0]);
                      }
                      const { startDate, endDate } = res[0];
                      const updatedRow = {
                        ...selectedRow,
                        startDate: moment(startDate),
                        endDate: moment(endDate),
                        classUuid: value.uuid,
                        classValue: value
                      };
                      handleRowChange(updatedRow);
                    })
                    .catch(console.debug);
                } else {
                  const updatedRow = {
                    ...selectedRow,
                    classUuid: "",
                    classValue: { uuid: "", className: "" }
                  };
                  handleRowChange(updatedRow);
                }
              }}
              requestConfig={
                paymentInfo.contractType === "VIRTUAL_CONTRACT"
                  ? {
                      url: `${UrlPaths.STUDENTS}/${paymentInfo.studentUuid}/learningClasses?contractType=VIRTUAL_CONTRACT`,
                      label: "className",
                      value: "uuid",
                      responseField: (res) => res
                    }
                  : {
                      url: `${UrlPaths.CLASSES}?branchUuid=${branchInfo.value.uuid}`,
                      label: "className",
                      value: "uuid",
                      responseField: (res) => res.results.map(({ classDetails }) => classDetails),
                      onQueryChange: (value) => (value ? `&any=${value}` : "")
                    }
              }></Autocomplete>
          </FormControl>
        </Grid>
        <Grid item>
          <TextField
            disabled
            type="number"
            margin="dense"
            id="name"
            label={t("paymentContract.placeholder.registeredSessions")}
            value={selectedRow.registeredSessions || 0}
            fullWidth
            size="small"
            variant="outlined"
            sx={{ paddingX: "0.5rem" }}
          />
        </Grid>
        {paymentInfo.contractType === "VIRTUAL_CONTRACT" && (
          <Grid item>
            <FormControl
              sx={{
                paddingX: "0.5rem",
                display: "inline-block",
                flexDirection: "row",
                alignItems: "center"
              }}>
              <FormLabel id="timeline-label" sx={{ paddingRight: "0.5rem" }}>
                {t("paymentContract.placeholder.timeline")}
              </FormLabel>
              {potentialTimeline.map(({ creationDate, addedSessions }, i) => (
                <FormControlLabel
                  key={i}
                  value={i}
                  checked={selectedTimelineIndexes.includes(i)}
                  control={<Checkbox />}
                  label={t("paymentContract.timeline.label", {
                    creationDate: formatDate(creationDate),
                    addedSessions
                  })}
                  onChange={(e) => {
                    const value = Number(e.target.value);
                    const newSelectedIndexes = e.target.checked
                      ? [...selectedTimelineIndexes, value]
                      : selectedTimelineIndexes.filter((x) => x !== value);
                    setSelectedTimelineIndexes(newSelectedIndexes);
                    if (newSelectedIndexes.length > 0) {
                      const earliestTimeLine = potentialTimeline
                        .filter((x, i) => newSelectedIndexes.includes(i))
                        .sort(
                          (a, b) =>
                            moment(a.startDate).format("YYYYMMDD") -
                            moment(b.startDate).format("YYYYMMDD")
                        )[0];
                      const { startDate, endDate } = earliestTimeLine;
                      const updatedRow = {
                        ...selectedRow,
                        startDate: moment(startDate),
                        endDate: moment(endDate)
                      };
                      handleRowChange(updatedRow);
                    }
                  }}
                />
              ))}
            </FormControl>
          </Grid>
        )}
        <Grid item>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              id="start-date"
              format={DEFAULT_DATE_FORMAT}
              disabled={!selectedRow.classUuid}
              label={t("paymentContract.placeholder.startDate")}
              sx={{ width: "100%", paddingX: "0.5rem" }}
              value={selectedRow.startDate || moment()}
              onChange={(e) => {
                if (e.isValid()) {
                  getWithoutLoading(
                    `${UrlPaths.CLASSES}/${selectedRow.classUuid}/potentialEndDate`,
                    {
                      startDate: e,
                      sessionsCount: selectedRow.registeredSessions
                    }
                  )
                    .then((res) => {
                      const updatedRow = {
                        ...selectedRow,
                        startDate: e,
                        endDate: moment(res)
                      };
                      handleRowChange(updatedRow);
                    })
                    .catch(console.debug);
                }
              }}
              slotProps={{
                textField: { size: "small" },
                popper: {
                  style: {
                    zIndex: 15003
                  }
                }
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              disabled
              id="end-date"
              format={DEFAULT_DATE_FORMAT}
              label={t("paymentContract.placeholder.endDate")}
              sx={{ width: "100%", paddingX: "0.5rem" }}
              value={selectedRow.endDate || moment()}
              slotProps={{
                textField: { size: "small" },
                popper: {
                  style: {
                    zIndex: 15003
                  }
                }
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item>
          <TextField
            margin="dense"
            id="name"
            label={t("paymentContract.placeholder.description")}
            value={selectedRow.description}
            onChange={(e) => {
              const updatedRow = { ...selectedRow, description: e.target.value || "" };
              handleRowChange(updatedRow);
            }}
            fullWidth
            size="small"
            variant="outlined"
            sx={{ paddingX: "0.5rem" }}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default ClassSummaryDialog;

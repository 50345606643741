import { useTheme } from "@emotion/react";
import PreviewIcon from "@mui/icons-material/Preview";
import { Box, CardContent, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Dialog, { DialogButtons } from "../../../components/dialog";
import Status from "../../../components/status";
import UrlPaths from "../../../constants/UrlPaths";
import { get } from "../../../services/HttpClient";
import { tokens } from "../../../theme";
import { formatDate, getDayOfWeek } from "../../../utils/TimeUtil";

const HistoryRowContent = ({ history, studentId, studentName }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();

  const [showAttendance, setShowAttendance] = useState(false);
  const [studentAttendanceData, setStudentAttendanceData] = useState([]);
  const { invoiceId, historyInformation } = history;
  const invoiceDetails =
    historyInformation.details.find(
      ({ name }) =>
        !name.includes("AddingSessions") &&
        !name.includes("SubtractSessions") &&
        !name.includes("TransferFromSessions") &&
        !name.includes("TransferToSessions") &&
        !name.includes("LOASessions") &&
        !name.includes("SkipClassSessions")
    ) || {};
  const addingSessions = historyInformation.details.filter(({ name }) =>
    name.includes("AddingSessions")
  );
  const subtractSessions = historyInformation.details.filter(({ name }) =>
    name.includes("SubtractSessions")
  );
  const transferFromSessions = historyInformation.details.filter(({ name }) =>
    name.includes("TransferFromSessions")
  );
  const transferToSessions = historyInformation.details.filter(({ name }) =>
    name.includes("TransferToSessions")
  );
  const loaSessions = historyInformation.details.filter(({ name }) => name.includes("LOASessions"));

  const skipClassSessions = historyInformation.details.filter(({ name }) =>
    name.includes("SkipClassSessions")
  );

  const getInvoiceLabel = (invoiceId) => {
    const virtualInvoice = "ADDING_VIRTUAL_INVOICE";
    const addingNoneInvoice = "ADDING_NONE_INVOICE";
    const transferNoneInvoice = "TRANSFER_NONE_INVOICE";
    if (invoiceId.includes(virtualInvoice)) {
      return t(virtualInvoice, { invoiceId: invoiceId.replace(virtualInvoice, "") });
    } else if (invoiceId.includes(addingNoneInvoice)) {
      return t(addingNoneInvoice, { className: invoiceId.replace(addingNoneInvoice, "") });
    } else if (invoiceId.includes(transferNoneInvoice)) {
      return t(transferNoneInvoice, { className: invoiceId.replace(transferNoneInvoice, "") });
    }
    return t(invoiceId);
  };

  return (
    <>
      <TableRow>
        <TableCell
          align="center"
          rowSpan={
            historyInformation.details.length +
            (invoiceId.includes("NONE_INVOICE") ||
            (invoiceId.includes("VIRTUAL_INVOICE") && !invoiceDetails.name)
              ? 1
              : 0)
          }
          sx={{ fontWeight: "bold" }}>
          <span style={{ whiteSpace: "pre-wrap" }}>{getInvoiceLabel(invoiceId)}</span>
        </TableCell>
        <TableCell align="left">{invoiceDetails.name}</TableCell>
        <TableCell align="center">{invoiceDetails.totalSessions}</TableCell>
        <TableCell align="center">{invoiceDetails.className}</TableCell>
        <TableCell align="center">{formatDate(invoiceDetails.startDate)}</TableCell>
        <TableCell align="center">{formatDate(invoiceDetails.endDate)}</TableCell>
        <TableCell align="center">{historyInformation.completedQuantity}</TableCell>
        <TableCell align="center">
          {(historyInformation.absenceQuantity || 0) +
            (historyInformation.madeUpQuantity || 0) +
            (historyInformation.makeUpUncompletedQuantity || 0) || ""}
        </TableCell>
        <TableCell align="center">{historyInformation.madeUpQuantity}</TableCell>
        <TableCell align="center">{historyInformation.makeUpUncompletedQuantity}</TableCell>
        <TableCell align="center">
          <Tooltip title={t("studentClassHistories.table.field.scheduleHistories")}>
            <IconButton
              color="secondary"
              onClick={() =>
                get(`${UrlPaths.STUDENTS}/${studentId}/histories`, {
                  classUuid: historyInformation.classUuid
                })
                  .then((res) => {
                    setStudentAttendanceData(res);
                    setShowAttendance(true);
                  })
                  .catch(console.debug)
              }>
              <PreviewIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
      {addingSessions.map((addingSession, index) => (
        <TableRow key={index}>
          <TableCell align="left">
            <div
              dangerouslySetInnerHTML={{
                __html: t("studentClassHistories.addSessions.label", {
                  creationDate: addingSession.name.replace("[AddingSessions]", "")
                })
              }}
            />
          </TableCell>
          <TableCell align="center">{addingSession.totalSessions}</TableCell>
          <TableCell align="center">{addingSession.className}</TableCell>
          <TableCell align="center">
            {addingSession.endDate ? formatDate(addingSession.startDate) : ""}
          </TableCell>
          <TableCell align="center">{formatDate(addingSession.endDate)}</TableCell>
          <TableCell align="center" colSpan={5}>
            <b>{addingSession.note}</b>
          </TableCell>
        </TableRow>
      ))}
      {subtractSessions.map((subtractSession, index) => (
        <TableRow key={index}>
          <TableCell align="left">
            <div
              dangerouslySetInnerHTML={{
                __html: t("studentClassHistories.subtractSessions.label", {
                  creationDate: subtractSession.name.replace("[SubtractSessions]", "")
                })
              }}
            />
          </TableCell>
          <TableCell align="center">{subtractSession.totalSessions}</TableCell>
          <TableCell align="center">{subtractSession.className}</TableCell>
          <TableCell align="center"></TableCell>
          <TableCell align="center"></TableCell>
          <TableCell align="center" colSpan={5}>
            <b>{subtractSession.note}</b>
          </TableCell>
        </TableRow>
      ))}
      {transferFromSessions.map((transferFromSession, index) => (
        <TableRow key={index}>
          <TableCell align="left">
            <div
              dangerouslySetInnerHTML={{
                __html: t("studentClassHistories.transferFromSessions.label", {
                  creationDate: transferFromSession.name.replace("[TransferFromSessions]", "")
                })
              }}
            />
          </TableCell>
          <TableCell align="center">{transferFromSession.totalSessions}</TableCell>
          <TableCell align="center">{transferFromSession.className}</TableCell>
          <TableCell align="center">{formatDate(transferFromSession.startDate)}</TableCell>
          <TableCell align="center">{formatDate(transferFromSession.endDate)}</TableCell>
          <TableCell align="center" colSpan={5}>
            <b>{transferFromSession.note}</b>
          </TableCell>
        </TableRow>
      ))}
      {transferToSessions.map((transferToSession, index) => (
        <TableRow key={index}>
          <TableCell align="left">
            <div
              dangerouslySetInnerHTML={{
                __html: t("studentClassHistories.transferToSessions.label", {
                  creationDate: transferToSession.name.replace("[TransferToSessions]", "")
                })
              }}
            />
          </TableCell>
          <TableCell align="center">{transferToSession.totalSessions}</TableCell>
          <TableCell align="center">{transferToSession.className}</TableCell>
          <TableCell align="center">{formatDate(transferToSession.startDate)}</TableCell>
          <TableCell align="center">{formatDate(transferToSession.endDate)}</TableCell>
          <TableCell align="center" colSpan={5}>
            <b>{transferToSession.note}</b>
          </TableCell>
        </TableRow>
      ))}
      {loaSessions.map((loaSession, index) => (
        <TableRow key={index}>
          <TableCell align="left">
            <div
              dangerouslySetInnerHTML={{
                __html: t("studentClassHistories.loaSessions.label", {
                  creationDate: loaSession.name.replace("[LOASessions]", "")
                })
              }}
            />
          </TableCell>
          <TableCell align="center">{loaSession.totalSessions}</TableCell>
          <TableCell align="center">{loaSession.className}</TableCell>
          <TableCell align="center"></TableCell>
          <TableCell align="center"></TableCell>
          <TableCell align="center" colSpan={5}>
            <b>{loaSession.note}</b>
          </TableCell>
        </TableRow>
      ))}
      {skipClassSessions.map((skipClassSession, index) => (
        <TableRow key={index}>
          <TableCell align="left">
            <div
              dangerouslySetInnerHTML={{
                __html: t("studentClassHistories.skipClassSessions.label", {
                  creationDate: skipClassSession.name.replace("[SkipClassSessions]", "")
                })
              }}
            />
          </TableCell>
          <TableCell align="center">{skipClassSession.totalSessions}</TableCell>
          <TableCell align="center">{skipClassSession.className}</TableCell>
          <TableCell align="center"></TableCell>
          <TableCell align="center"></TableCell>
          <TableCell align="center" colSpan={5}>
            <b>{skipClassSession.note}</b>
          </TableCell>
        </TableRow>
      ))}
      <Dialog
        open={!!showAttendance}
        onClose={() => setShowAttendance(false)}
        onConfirm={() => setShowAttendance(false)}
        width="60%"
        buttons={DialogButtons.OK}
        title={t("studentClassHistories.attendanceDetails.label", { studentName })}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
            {studentAttendanceData.map((history, index) => (
              <Grid item xs={2} sm={4} md={4} key={index}>
                <CardContent
                  key={index}
                  sx={{ background: colors.grey[900], margin: "0.5rem", borderRadius: "5px" }}>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    {t(`timetable.weekDay.${getDayOfWeek(history.weekDay)}.label`)}
                  </Typography>
                  <Typography variant="h5" component="div">
                    {formatDate(history.scheduleDate)}
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    {history.timeSlot}
                  </Typography>
                  <Status
                    prefix="attendance.historyStatus"
                    status={history.historyStatus}
                    type="HISTORY"
                  />
                </CardContent>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Dialog>
    </>
  );
};

const StudentClassHistories = ({ studentId, studentName }) => {
  const { t } = useTranslation();

  const [historyDetails, setHistoryDetails] = useState([]);

  useEffect(() => {
    if (studentId) {
      refreshData();
    }
  }, [studentId]);

  const refreshData = () => {
    get(`${UrlPaths.STUDENTS}/${studentId}/classHistories`)
      .then((res) => {
        if (res.classHistoryDetails) {
          const histories = [];
          for (const [key, value] of Object.entries(res.classHistoryDetails)) {
            histories.push({
              invoiceId: key,
              historyInformation: value
            });
          }
          setHistoryDetails(histories);
        }
      })
      .catch(console.debug);
  };

  return (
    <TableContainer component={Paper}>
      <Table aria-label="spanning table" size="small">
        <TableHead>
          <TableRow>
            <TableCell align="center" rowSpan={2}>
              {t("studentClassHistories.table.field.paymentContract")}
            </TableCell>
            <TableCell align="left" rowSpan={2}>
              <div
                dangerouslySetInnerHTML={{
                  __html: t("studentClassHistories.table.field.courseCreatedDate")
                }}
              />
            </TableCell>
            <TableCell align="center" rowSpan={2}>
              {t("studentClassHistories.table.field.numberOfSessions")}
            </TableCell>
            <TableCell align="center" rowSpan={2}>
              {t("studentClassHistories.table.field.className")}
            </TableCell>
            <TableCell align="center" rowSpan={2}>
              {t("studentClassHistories.table.field.startDate")}
            </TableCell>
            <TableCell align="center" rowSpan={2}>
              {t("studentClassHistories.table.field.endDate")}
            </TableCell>
            <TableCell align="center" colSpan={4}>
              <div
                dangerouslySetInnerHTML={{
                  __html: t("studentClassHistories.table.field.attendanceAndNote")
                }}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center">
              {t("studentClassHistories.table.field.completedQuantity")}
            </TableCell>
            <TableCell align="center">
              {t("studentClassHistories.table.field.absenceQuantity")}
            </TableCell>
            <TableCell align="center">
              {t("studentClassHistories.table.field.madeupQuantity")}
            </TableCell>
            <TableCell align="center">
              {t("studentClassHistories.table.field.makeUpUncompletedQuantity")}
            </TableCell>
            <TableCell align="center"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {historyDetails.map((history, index) => (
            <HistoryRowContent
              key={index}
              history={history}
              studentId={studentId}
              studentName={studentName}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default StudentClassHistories;

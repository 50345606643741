import { Backdrop } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useTheme } from "@mui/material/styles";
import { currentRequests, globalLoading } from "../../signals";
import { tokens } from "../../theme";

const Loading = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Backdrop
      open={globalLoading.value || currentRequests.value.length > 0}
      sx={{
        backgroundColor: colors.grey[900],
        zIndex: 15005,
        opacity: "0.5 !important"
      }}>
      <CircularProgress color="secondary" />
    </Backdrop>
  );
};

export default Loading;

export const SCHEDULE_TIMES = [
  {
    weekDay: 1,
    weekDayLabel: "timetable.weekDay.mon.label",
    timeSlots: [
      "17:00-18:30",
      "17:30-19:00",
      "18:00-19:30",
      "18:30-20:00",
      "19:00-20:30",
      "19:30-21:00"
    ]
  },
  {
    weekDay: 2,
    weekDayLabel: "timetable.weekDay.tue.label",
    timeSlots: [
      "17:00-18:30",
      "17:30-19:00",
      "18:00-19:30",
      "18:30-20:00",
      "19:00-20:30",
      "19:30-21:00"
    ]
  },
  {
    weekDay: 3,
    weekDayLabel: "timetable.weekDay.wed.label",
    timeSlots: [
      "17:00-18:30",
      "17:30-19:00",
      "18:00-19:30",
      "18:30-20:00",
      "19:00-20:30",
      "19:30-21:00"
    ]
  },
  {
    weekDay: 4,
    weekDayLabel: "timetable.weekDay.thu.label",
    timeSlots: [
      "17:00-18:30",
      "17:30-19:00",
      "18:00-19:30",
      "18:30-20:00",
      "19:00-20:30",
      "19:30-21:00"
    ]
  },
  {
    weekDay: 5,
    weekDayLabel: "timetable.weekDay.fri.label",
    timeSlots: [
      "17:00-18:30",
      "17:30-19:00",
      "18:00-19:30",
      "18:30-20:00",
      "19:00-20:30",
      "19:30-21:00"
    ]
  },
  {
    weekDay: 6,
    weekDayLabel: "timetable.weekDay.sat.label",
    timeSlots: [
      "08:00-09:30",
      "08:30-10:30",
      "09:00-10:30",
      "09:30-11:00",
      "15:00-16:30",
      "16:00-17:30",
      "16:30-18:00",
      "17:00-18:30",
      "17:30-19:00",
      "18:00-19:30",
      "18:30-20:00",
      "19:00-20:30",
      "19:30-21:00"
    ]
  },
  {
    weekDay: 7,
    weekDayLabel: "timetable.weekDay.sun.label",
    timeSlots: [
      "08:00-09:30",
      "08:30-10:30",
      "09:00-10:30",
      "09:30-11:00",
      "15:00-16:30",
      "16:00-17:30",
      "16:30-18:00",
      "17:00-18:30",
      "17:30-19:00",
      "18:00-19:30",
      "18:30-20:00",
      "19:00-20:30",
      "19:30-21:00"
    ]
  }
];

import CookieConstants from "../constants/Cookies";
import { ACADEMIC_ROLES } from "../constants/Department";
import UrlPaths from "../constants/UrlPaths";
import { get } from "../services/HttpClient";
import { branches, branchInfo, userProfile } from "../signals";
import { getCookie, setCookie } from "./CookieUtil";
import { formatDate } from "./TimeUtil";

export const getDisplayName = (user) => {
  if (!user) {
    return "";
  }

  if (user.niceName) {
    return user.niceName;
  }

  const { firstName, lastName, displayName } = user;
  const fullName = ((firstName || "") + " " + (lastName || "")).trim();
  if (fullName && displayName) {
    return fullName + " - " + displayName;
  }
  return fullName || displayName;
};

export const getStudentDetailLabel = (student) => {
  if (!student) {
    return "";
  }

  const studentName = getDisplayName(student);
  const studentBirthday = student.birthday ? ` (${formatDate(student.birthday)})` : "";

  let parentName = "";
  if (student.parent) {
    parentName = getDisplayName(student.parent);
    if (student.parent.mobile && student.parent.mobile !== "-") {
      parentName = parentName + " - 0" + student.parent.mobile;
    }
  }

  return `${studentName}${studentBirthday}${parentName ? " (" + parentName + ")" : ""}`;
};

export const fetchCurrentInfo = (successCallback, errorHandler) =>
  get(`${UrlPaths.USER}/profile`)
    .then((res) => {
      userProfile.value = res;
      if (!getCookie(CookieConstants.CURRENT_BRANCH_UUID) && res.branchUuids.length > 0) {
        setCookie(CookieConstants.CURRENT_BRANCH_UUID, res.branchUuids[0]);
      }
      return get(`${UrlPaths.ADMIN}/branches`);
    })
    .then((res) => {
      const { branchUuids } = userProfile.value;
      const currentBranchUuid = getCookie(CookieConstants.CURRENT_BRANCH_UUID);
      branches.value = res.filter(({ uuid }) => branchUuids.includes(uuid));
      branchInfo.value =
        branches.value.find(({ uuid }) => uuid === currentBranchUuid) || branches.value[0];
      setCookie(CookieConstants.CURRENT_BRANCH_UUID, branchInfo.value.uuid);
      successCallback();
    })
    .catch((err) => {
      errorHandler(err);
    });

export const getStaffDetailLabel = (staff) => {
  if (!staff) {
    return "";
  }

  const staffName = getDisplayName(staff);
  const staffBirthday = staff.birthday ? ` (${formatDate(staff.birthday)})` : "";

  return `${staffName} ${staffBirthday}`;
};

export const isAdmin = (user) => user?.roles?.includes("ADMINISTRATOR");

export const isTeacher = (user) => user?.roles?.some((role) => ACADEMIC_ROLES.includes(role));

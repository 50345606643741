import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import HistoryIcon from "@mui/icons-material/History";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  TextField,
  Typography,
  useTheme
} from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import Dialog, { DialogButtons } from "../../../components/dialog";
import Status from "../../../components/status";
import Table from "../../../components/table";
import UrlPaths from "../../../constants/UrlPaths";
import { get, post } from "../../../services/HttpClient";
import { branchInfo, renewInvoice } from "../../../signals";
import { tokens } from "../../../theme";
import { formatDate } from "../../../utils/TimeUtil";
import { getStudentDetailLabel } from "../../../utils/UserUtil";
import LeaveOfAbsenceDialog from "../../academic/classes/LeaveOfAbsenceDialog";

const ComingDebDetails = ({ defaultExpanded }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [comingDebts, setComingDebts] = useState([]);
  const [notes, setNotes] = useState([]);
  const [disableRow, setDisableRow] = useState(null);
  const [disableNote, setDisableNote] = useState("");
  const [loaRow, setLoaRow] = useState(null);

  const comingDebtColumns = [
    { field: "id", label: "comingDebts.table.field.id.label", sortable: false },
    {
      field: "studentName",
      width: "200px",
      label: "comingDebts.table.field.studentName.label",
      valueGetter: ({ studentValue }) => (
        <Link to={`../../academic/students/details/${studentValue.uuid}`}>
          <Typography sx={{ fontWeight: "bold", color: colors.greenAccent[400] }}>
            {getStudentDetailLabel(studentValue)}
          </Typography>
        </Link>
      )
    },
    {
      field: "className",
      label: "comingDebts.table.field.className.label",
      valueGetter: ({ classValue }) => classValue.className
    },
    {
      field: "status",
      label: "comingDebts.table.field.status.label",
      component: ({ status }) => (
        <Status
          prefix="classDetails.status"
          postfix="label"
          status={status}
          type="STUDENT_SCHEDULE"
        />
      )
    },
    {
      field: "startDate",
      width: "150px",
      label: "comingDebts.table.field.startDate.label",
      valueGetter: ({ startDate }) => formatDate(startDate)
    },
    {
      field: "endDate",
      width: "150px",
      label: "comingDebts.table.field.endDate.label",
      valueGetter: ({ endDate }) => formatDate(endDate)
    },
    {
      field: "debtQuantity",
      width: "100px",
      label: "comingDebts.table.field.debtQuantity.label"
    },
    {
      field: "debtNote",
      width: "300px",
      label: "comingDebts.table.field.note.label",
      sortable: false,
      component: ({ notes, id, noteChange, ...rowData }) => (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <TextField
            id="outlined-basic"
            value={notes[id - 1]}
            onChange={(e) => {
              noteChange(e, id);
            }}
            multiline
            rows={3}
            variant="outlined"
            sx={{ width: "100%" }}
            size="small"
          />
          <IconButton
            variant="contained"
            color="success"
            onClick={() => {
              const { classValue, studentValue, debtNoteUuid } = rowData;
              post(`${UrlPaths.STUDENTS}/debt`, {
                uuid: debtNoteUuid,
                branchUuid: branchInfo.value.uuid,
                studentUuid: studentValue.uuid,
                classUuid: classValue.uuid,
                status: "ACTIVE",
                debtType: "COMING_DEBT",
                note: notes[id - 1]
              })
                .then(() => {
                  refreshData();
                })
                .catch(console.debug);
            }}>
            <SaveAsIcon />
          </IconButton>
        </Box>
      )
    }
  ];
  const rowActions = [
    {
      icon: <PriceCheckIcon />,
      tooltip: t("comingDebts.table.action.pay.label"),
      action: (rowData) => {
        renewInvoice.value = {
          ...rowData,
          registeredSessions: rowData.debtQuantity,
          isSettlement: true
        };
        navigate("../payment-contract/new");
      }
    },
    {
      icon: <HistoryIcon />,
      tooltip: t("comingDebts.table.action.loa.label"),
      action: (rowData) => {
        const { studentValue, classValue } = rowData;
        setLoaRow({
          studentUuid: studentValue.uuid,
          studentName: studentValue.niceName,
          classUuid: classValue.uuid,
          className: classValue.className
        });
      }
    },
    {
      icon: <HighlightOffIcon />,
      tooltip: t("comingDebts.table.action.disable.label"),
      action: (rowData) => {
        const { studentValue, classValue } = rowData;
        setDisableRow({
          studentUuid: studentValue.uuid,
          studentName: studentValue.niceName,
          classUuid: classValue.uuid,
          className: classValue.className
        });
      }
    }
  ];

  useEffect(() => {
    if (branchInfo.value.uuid) {
      refreshData();
    }
  }, [branchInfo.value]);

  const refreshData = () => {
    get(
      `${UrlPaths.STUDENTS}/comingDebt?branchUuid=${branchInfo.value.uuid}&month=${
        moment().month() + 1
      }`
    )
      .then((res) => {
        setComingDebts(res.map((x, index) => ({ id: index + 1, ...x })));
        setNotes(res.map(({ debtNote }) => debtNote || ""));
      })
      .catch(console.debug);
  };

  const handleClose = () => {
    setLoaRow(null);
    setDisableRow(null);
  };

  return (
    <Accordion defaultExpanded={defaultExpanded}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          backgroundColor: "#ff8a00"
        }}>
        <Typography variant="h4" fontWeight="bolder">
          {t("feeReport.comingDebts.title")}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Table
          columnConfig={comingDebtColumns}
          rowActions={rowActions}
          showHeaderToolbar={false}
          data={comingDebts.map((x) => ({
            ...x,
            notes,
            noteChange: (e, id) => {
              const noteList = [...notes];
              noteList.splice(id - 1, 1, e.target.value);
              setNotes(noteList);
            }
          }))}
        />
        <LeaveOfAbsenceDialog
          open={!!loaRow}
          data={loaRow}
          onClose={handleClose}
          onConfirm={(res) => {
            const request = {
              effectiveDate: res.loaDate,
              note: res.note,
              loaSessions: res.loaSessions
            };
            post(
              `${UrlPaths.CLASSES}/${res.classUuid}/schedule/students/${res.studentUuid}/loa`,
              request
            )
              .then(() => {
                refreshData();
                handleClose();
              })
              .catch(console.debug);
          }}
          buttons={DialogButtons.CANCEL_SAVE}
          title={t("classDetails.dialog.loa.title")}
        />
        <Dialog
          open={!!disableRow}
          data={disableRow}
          onClose={handleClose}
          onConfirm={() => {
            post(`${UrlPaths.STUDENTS}/${disableRow.studentUuid}/schedule/disable`, {
              note: disableNote
            })
              .then(() => {
                refreshData();
                handleClose();
              })
              .catch(console.debug);
          }}
          buttons={[
            {
              type: "discard",
              id: "cancel",
              label: "common.button.cancel",
              variant: "outlined"
            },
            {
              type: "confirm",
              id: "confirm",
              label: "common.button.confirm",
              color: "error"
            }
          ]}
          headerBgColor="#d32f2f"
          headerTitleColor="white"
          title={t("classDetails.dialog.disableStudent.title")}>
          <Typography variant="h5" color={colors.grey[100]} sx={{ m: "0 0 5px 0" }}>
            <div
              style={{ marginBottom: "1rem" }}
              dangerouslySetInnerHTML={{
                __html: t("classDetails.dialog.student.confirmTitle", {
                  studentName: disableRow?.studentName
                })
              }}></div>
            <div
              dangerouslySetInnerHTML={{
                __html: t("classDetails.dialog.disableStudent.description")
              }}></div>
          </Typography>
          <TextField
            margin="dense"
            id="name"
            label={t("addStudent.placeholder.note")}
            value={disableNote || ""}
            onChange={(e) => setDisableNote(e.target.value)}
            fullWidth
            size="small"
            variant="outlined"
          />
        </Dialog>
      </AccordionDetails>
    </Accordion>
  );
};

export default ComingDebDetails;

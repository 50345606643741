const defaultNumbers = " hai ba bốn năm sáu bảy tám chín";

const chuHangDonVi = ("1 một" + defaultNumbers).split(" ");
const chuHangChuc = ("lẻ mười" + defaultNumbers).split(" ");
const chuHangTram = ("không một" + defaultNumbers).split(" ");

const convert3rdBlock = (number) => {
  if (number === "000") {
    return "";
  }
  //Convert biến 'number' thành kiểu string
  var _a = number + "";
  //Kiểm tra độ dài của khối
  switch (_a.length) {
    case 0:
    default:
      return "";
    case 1:
      return chuHangDonVi[_a];
    case 2:
      return convert2ndBlock(_a);
    case 3:
      var chuc_dv = "";
      if (_a.slice(1, 3) !== "00") {
        chuc_dv = convert2ndBlock(_a.slice(1, 3));
      }
      var tram = chuHangTram[_a[0]] + " trăm";
      return tram + " " + chuc_dv;
  }
};

const convert2ndBlock = (number) => {
  var dv = chuHangDonVi[number[1]];
  var chuc = chuHangChuc[number[0]];
  var append = "";

  // Nếu chữ số hàng đơn vị là 5
  if (Number(number[0]) > 0 && Number(number[1]) === 5) {
    dv = "lăm";
  }

  // Nếu số hàng chục lớn hơn 1
  if (Number(number[0]) > 1) {
    append = " mươi";

    if (Number(number[1]) === 1) {
      dv = " mốt";
    }
  }

  return chuc + "" + append + " " + dv;
};

const dvBlock = "1 nghìn triệu tỷ".split(" ");

export const toVietnamesePricingLabel = (number) => {
  var str = parseInt(number) + "";
  var i = 0;
  var arr = [];
  var index = str.length;
  var result = [];
  var rsString = "";

  if (index === 0 || str === "NaN") {
    return "";
  }

  // split into blocks array, each block includes 3 numbers
  while (index >= 0) {
    arr.push(str.substring(index, Math.max(index - 3, 0)));
    index -= 3;
  }

  // loop blocks and convert into Vietnamese
  for (i = arr.length - 1; i >= 0; i--) {
    if (arr[i] !== "" && arr[i] !== "000") {
      result.push(convert3rdBlock(arr[i]));

      // add ended letter
      if (dvBlock[i]) {
        result.push(dvBlock[i]);
      }
    }
  }

  rsString = result.join(" ");

  // remove redundant letter and return the price in Vietnamese
  rsString = rsString.replace(/[0-9]/g, "").replace(/ /g, " ").replace(/ $/, "");
  return rsString.length > 0 ? rsString.charAt(0).toUpperCase() + rsString.slice(1) + " đồng" : "";
};

export const toVNDCurrency = (amount, displayType) =>
  (amount && Number(amount).toFixed() > 0 ? amount : 0).toLocaleString("en-US", {
    style: "currency",
    currency: "VND",
    currencyDisplay: displayType || "symbol"
  }) || "";

import {
  Box,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useTheme
} from "@mui/material";
import moment from "moment";
import { useEffect, useMemo, useRef, useState } from "react";
import TableCellStyled from "./TableCellStyled";
import { useTranslation } from "react-i18next";
import { tokens } from "../../theme";

const INITIAL_COLUMNS = [
  { id: "sunday", label: "sun" },
  { id: "monday", label: "mon" },
  { id: "tuesday", label: "tue" },
  { id: "wednesday", label: "wed" },
  { id: "thursday", label: "thu" },
  { id: "friday", label: "fri" },
  { id: "saturday", label: "sat" }
];

const MonthView = ({ rows, from, to }) => {
  const [columnWidth, setColumnWidth] = useState(0);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();
  const currentDay = moment().format("YYYY-MM-DD");
  const daysOfMonth = useMemo(() => {
    const days = [];
    const startDate = moment(from).startOf("week");
    const endDate = moment(to).endOf("week");
    while (startDate.isSameOrBefore(endDate)) {
      days.push({
        id: startDate.format("YYYY-MM-DD"),
        date: startDate.format("DD"),
        inMonth: startDate.isSameOrAfter(from) && startDate.isSameOrBefore(to),
        data: rows.find((row) => {
          if (row.dayOfWeek.toLowerCase() === startDate.format("dddd").toLowerCase()) {
            return row.classes.filter((data) => {
              return moment(data.startDate).isSameOrBefore(startDate);
            });
          }
        })
      });
      startDate.add(1, "day");
    }
    return days;
  }, [rows, from, to]);
  const tableRef = useRef(null);
  useEffect(() => {
    if (tableRef.current) {
      const tableWidth = tableRef.current.offsetWidth;
      setColumnWidth(tableWidth / 7);
    }
  }, [tableRef]);
  const renderEventDetails = (event) => {
    return (
      <Typography aria-describedby="test" variant="contained">
        <span>{t("timetable.classroomName.label")}: </span>
        <b>{event?.classroomName}</b>
        <br />
        <span>{t("timetable.teacherName.label")}: </span>
        <b>
          {event?.teacherName} <i>{event?.teacherTimeSlot}</i>
        </b>
        <br />

        <span>{t("timetable.foreignTeacherName.label")}: </span>
        <b>
          {event?.foreignTeacherName} <i>{event?.foreignTeacherTimeSlot}</i>
        </b>
        <br />

        <span>{t("timetable.teachingAssistantName.label")}: </span>
        <b>
          {event?.teachingAssistantName} <i>{event?.teachingAssistantTimeSlot}</i>
        </b>
      </Typography>
    );
  };
  const renderEventContent = (event) => {
    return (
      <Typography
        aria-describedby="test"
        variant="contained"
        sx={{ color: event.inMonth ? "" : "rgba(128, 128, 128, 0.6)" }}>
        <Box sx={{ textAlign: "right" }}>{event.date}</Box>
        <Box sx={{ display: "flex", gap: "2px", flexDirection: "column" }}>
          {event.inMonth &&
            event.data.classes?.map((data) => (
              <Tooltip
                key={data.classID}
                title={renderEventDetails(data)}
                placement="right"
                followCursor={true}>
                <Box
                  sx={{
                    background: data.color,
                    borderRadius: "5px",
                    padding: "0px 5px",
                    cursor: "pointer"
                  }}>
                  <span>{data.timeSlot} </span>
                  <b>{data.className}</b>
                  <br />
                </Box>
              </Tooltip>
            ))}
        </Box>
      </Typography>
    );
  };

  return (
    <Table ref={tableRef}>
      <TableHead>
        <TableRow>
          {INITIAL_COLUMNS.map((column) => (
            <TableCellStyled
              key={column.id}
              sx={{ textAlign: "center", background: colors.primary[900] }}>
              <b>{t(`timetable.weekDay.${column.label}.label`)}</b>
            </TableCellStyled>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {Array.from({ length: Math.ceil(daysOfMonth.length / 7) }, (_, index) => (
          <TableRow key={index}>
            {daysOfMonth.slice(index * 7, (index + 1) * 7).map((day) => (
              <TableCellStyled
                key={day.id}
                sx={{
                  height: "150px",
                  width: columnWidth,
                  verticalAlign: "top",
                  backgroundColor:
                    currentDay === day.id
                      ? "#fffadf"
                      : day.inMonth
                        ? "white"
                        : "rgba(128, 128, 128, 0.1)"
                }}>
                {renderEventContent(day)}
              </TableCellStyled>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default MonthView;

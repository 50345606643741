import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Box, Chip, TextField, useTheme } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Dialog, { DialogButtons } from "../../../components/dialog";
import Table from "../../../components/table";
import { tokens } from "../../../theme";

const CheckinListDialog = ({ checkinList, closeCheckinDialog, confirmCheckinDialog }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();

  const [notes, setNotes] = useState([]);
  const [selected, setSelected] = useState([]);

  const columns = [
    { field: "id", label: "checkinList.table.field.id.label", sortable: false },
    {
      field: "studentName",
      label: "checkinList.table.field.studentName.label",
      sortable: false
    },
    {
      field: "onTime",
      label: "checkinList.table.field.onTime.label",
      checkbox: true,
      checkboxId: "checkin",
      textColor: "#0e8416",
      sortable: false
    },
    {
      field: "absence",
      label: "checkinList.table.field.absence.label",
      checkbox: true,
      checkboxId: "checkin",
      textColor: "#d6420a",
      sortable: false
    },
    {
      field: "loa",
      label: "checkinList.table.field.loa.label",
      checkbox: true,
      checkboxId: "checkin",
      textColor: "#06389d",
      sortable: false
    },
    {
      field: "note",
      label: "checkinList.table.field.note.label",
      sortable: false,
      component: ({ uuid, noteChange }) => (
        <TextField
          id="outlined-basic"
          onChange={(e) => noteChange(e, uuid)}
          variant="outlined"
          sx={{ width: "100%" }}
          size="small"
        />
      )
    }
  ];

  const confirmCheckin = () => {
    const checkedInIndexes = selected
      .filter(({ colIndex }) => colIndex === 2)
      .map(({ rowIndex }) => rowIndex);
    const absenceIndexes = selected
      .filter(({ colIndex }) => colIndex === 3)
      .map(({ rowIndex }) => rowIndex);
    const loaIndexes = selected
      .filter(({ colIndex }) => colIndex === 4)
      .map(({ rowIndex }) => rowIndex);
    confirmCheckinDialog({
      checkedInList: checkinList
        .filter((_, index) => checkedInIndexes.includes(index))
        .map((x) => ({
          ...x,
          note: notes.find(({ uuid }) => x.uuid === uuid)?.note
        })),
      absenceList: checkinList
        .filter((_, index) => absenceIndexes.includes(index))
        .map((x) => ({
          ...x,
          note: notes.find(({ uuid }) => x.uuid === uuid)?.note
        })),
      loaList: checkinList
        .filter((_, index) => loaIndexes.includes(index))
        .map((x) => ({
          ...x,
          note: notes.find(({ uuid }) => x.uuid === uuid)?.note
        }))
    });
  };

  return (
    <Dialog
      id="checkin"
      open={!!checkinList.length > 0}
      data={checkinList}
      onClose={closeCheckinDialog}
      onConfirm={() => confirmCheckin()}
      buttons={DialogButtons.CANCEL_SAVE}
      width="1000px"
      title={t("attendance.checkinList.title")}>
      <Chip
        icon={<WarningAmberIcon />}
        label={t("attendance.checkinList.warning.label")}
        sx={{
          marginBottom: "0.5rem",
          backgroundColor: "#f2d349",
          fontWeight: "bold",
          fontSize: "14px"
        }}
      />
      <Box
        backgroundColor={colors.primary[400]}
        display="flex"
        mb="0.5rem"
        p="0.5rem"
        justifyContent="space-between">
        <Table
          columnConfig={columns}
          data={checkinList.map((x) => ({
            ...x,
            noteChange: (e, uuid) => {
              const noteIndex = notes.findIndex((x) => x.uuid === uuid);
              if (noteIndex === -1) {
                setNotes([
                  ...notes,
                  {
                    uuid,
                    note: e.target.value
                  }
                ]);
              } else {
                const results = [...notes];
                results.splice(noteIndex, 1, {
                  uuid,
                  note: e.target.value
                });
                setNotes(results);
              }
            }
          }))}
          showHeaderToolbar={false}
          showFooterToolbar={false}
          rowColumnSelectedChanged={setSelected}
        />
      </Box>
    </Dialog>
  );
};

export default CheckinListDialog;

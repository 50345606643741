const UrlPaths = {
  ADMIN: "v1/admin",
  AUTH: "v1/auth",
  CLASSES: "v1/classes",
  STUDENTS: "v1/students",
  TEACHERS: "v1/teachers",
  USER: "v1/user"
};

export default UrlPaths;

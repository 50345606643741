import { Visibility, VisibilityOff } from "@mui/icons-material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import {
  Box,
  Chip,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  TextField
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ColorPicker from "../../../components/colorpicker";
import Dialog from "../../../components/dialog";
import { ACADEMIC_ROLES, DEPARTMENTS, OFFICE_ROLES } from "../../../constants/Department";
import { branchInfo, userProfile } from "../../../signals";
import { DEFAULT_DATE_FORMAT } from "../../../utils/TimeUtil";
import { isAdmin } from "../../../utils/UserUtil";

const INITIAL_DATA = {
  password: "",
  firstName: "",
  lastName: "",
  birthday: null,
  startDate: null,
  mobile: "",
  username: "",
  departments: [],
  roles: [],
  contractLink: "",
  status: "ACTIVE"
};
const REQUIRED_FIELDS = {
  firstName: false,
  lastName: false,
  mobile: false,
  departments: false,
  roles: false,
  password: false
};
const StaffDetailsDialog = ({ data, onConfirm, onClose, action, ...props }) => {
  const { t } = useTranslation();

  const [staffDetails, setStaffDetails] = useState(INITIAL_DATA);
  const [isError, setIsError] = useState(REQUIRED_FIELDS);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const updateFieldValue = (fieldName, fieldValue) => {
    setStaffDetails({ ...staffDetails, [fieldName]: fieldValue });
  };

  useEffect(() => {
    if (data) {
      setStaffDetails({
        ...data,
        birthday: data.birthday ? moment(new Date(data.birthday)) : null,
        startDate: data.startDate ? moment(new Date(data.startDate)) : null
      });
    } else {
      setStaffDetails({
        ...INITIAL_DATA
      });
    }
  }, [data]);

  const confirmSaveStaff = () => {
    const errors = {
      firstName: !staffDetails.firstName,
      lastName: !staffDetails.lastName,
      mobile: !staffDetails.mobile,
      departments: !staffDetails.departments.length,
      roles: !staffDetails.roles.length,
      password: !staffDetails.password
    };
    setIsError(errors);
    if (Object.values(errors).some((error) => error)) {
      return;
    }
    const formData = {
      ...staffDetails,
      username: staffDetails.username || staffDetails.mobile
    };

    onConfirm(formData);
    setStaffDetails(INITIAL_DATA);
  };

  const ROLE_OPTIONS = useMemo(() => {
    if (staffDetails.departments.includes(DEPARTMENTS.OFFICE)) {
      return OFFICE_ROLES;
    } else if (staffDetails.departments.includes(DEPARTMENTS.ACADEMIC)) {
      return ACADEMIC_ROLES;
    }
    return [];
  }, [staffDetails.departments]);

  const handleDepartmentChange = (e) => {
    setStaffDetails({ ...staffDetails, departments: [e.target.value], roles: [] });
  };

  return (
    <Dialog
      {...props}
      buttons={props.buttons}
      onConfirm={confirmSaveStaff}
      onClose={() => {
        onClose();
        setShowPassword(false);
        setStaffDetails(INITIAL_DATA);
      }}
      width="1000px">
      <Grid
        container
        p="1rem"
        flexDirection="column"
        justifyContent="center"
        sx={{
          width: "100%"
        }}
        rowSpacing={4}>
        <Grid item container alignItems="start">
          <Grid item xs={6}>
            <TextField
              error={isError.firstName}
              margin="dense"
              label={t("staffDetails.placeholder.firstName")}
              value={staffDetails.firstName || ""}
              onChange={(e) => updateFieldValue("firstName", e.target.value)}
              fullWidth
              size="small"
              variant="outlined"
              sx={{ paddingX: "0.5rem" }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              error={isError.lastName}
              margin="dense"
              label={t("staffDetails.placeholder.lastName")}
              value={staffDetails.lastName || ""}
              onChange={(e) => updateFieldValue("lastName", e.target.value)}
              fullWidth
              size="small"
              variant="outlined"
              sx={{ paddingX: "0.5rem" }}
            />
          </Grid>
        </Grid>
        <Grid item container alignItems="start">
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                id="birthday"
                format={DEFAULT_DATE_FORMAT}
                label={t("staffDetails.placeholder.birthday")}
                sx={{ width: "100%", paddingX: "0.5rem", marginTop: "8px", marginBottom: "4px" }}
                value={staffDetails.birthday}
                onChange={(e) => updateFieldValue("birthday", e)}
                slotProps={{
                  textField: { size: "small" },
                  popper: {
                    style: {
                      zIndex: 15003
                    }
                  }
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              display: staffDetails.departments.includes(DEPARTMENTS.ACADEMIC) ? "flex" : "none",
              flexDirection: "row",
              alignItems: "start"
            }}>
            <ColorPicker
              margin="dense"
              label={t("staffDetails.placeholder.teacherScheduleColor")}
              value={staffDetails.teacherScheduleColor || ""}
              onChange={(e) => updateFieldValue("teacherScheduleColor", e.target.value)}
              onPick={(color) => updateFieldValue("teacherScheduleColor", color)}
              fullWidth
              size="small"
              variant="outlined"
              sx={{ paddingX: "0.5rem" }}
            />
          </Grid>
        </Grid>
        <Grid item container alignItems="center">
          <Grid item xs={6}>
            <FormControl
              error={isError.departments}
              sx={{
                paddingX: "0.5rem"
              }}>
              <FormLabel>{t("staffDetails.placeholder.departments")}</FormLabel>
              <RadioGroup
                row
                value={staffDetails.departments || []}
                onChange={handleDepartmentChange}>
                {Object.values(DEPARTMENTS).map((department) => (
                  <FormControlLabel
                    disabled={action === "edit"}
                    key={department}
                    value={department}
                    control={<Radio />}
                    label={t(`department.${department}.label`)}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <Select
              labelId="staff-detail-roles"
              id="staff-detail-roles"
              multiple
              disabled={!isAdmin(userProfile.value)}
              size="small"
              value={staffDetails.roles || []}
              onChange={(event) => {
                const {
                  target: { value }
                } = event;
                updateFieldValue(
                  "roles",
                  // On autofill we get a stringified value.
                  typeof value === "string" ? value.split(",") : value
                );
              }}
              fullWidth
              sx={{ paddingX: "0.5rem" }}
              input={<OutlinedInput id="staff-detail-chip" />}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((role, index) => (
                    <Chip key={index} size="small" label={t(`role.${role}.label`)} />
                  ))}
                </Box>
              )}
              MenuProps={{
                style: { zIndex: 15002 }
              }}>
              {ROLE_OPTIONS.map((role, index) => (
                <MenuItem key={index} value={role} dense>
                  {t(`role.${role}.label`)}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
        <Grid item container alignItems="start">
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                id="startDate"
                format={DEFAULT_DATE_FORMAT}
                label={t("staffDetails.placeholder.startDate")}
                sx={{ width: "100%", paddingX: "0.5rem", marginTop: "8px", marginBottom: "4px" }}
                value={staffDetails.startDate}
                onChange={(e) => updateFieldValue("startDate", e)}
                slotProps={{
                  textField: { size: "small" },
                  popper: {
                    style: {
                      zIndex: 15003
                    }
                  }
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6}>
            <TextField
              margin="dense"
              label={t("staffDetails.placeholder.contractLink")}
              value={staffDetails.contractLink || ""}
              onChange={(e) => updateFieldValue("contractLink", e.target.value)}
              fullWidth
              size="small"
              variant="outlined"
              sx={{ paddingX: "0.5rem" }}
            />
          </Grid>
        </Grid>
        <Grid item container alignItems="start">
          <Grid item xs={6}>
            <TextField
              margin="dense"
              disabled={!isAdmin(userProfile.value) || userProfile.value.uuid === staffDetails.uuid}
              label={t("staffDetails.placeholder.username")}
              value={staffDetails?.username || ""}
              onChange={(e) => updateFieldValue("username", e.target.value)}
              fullWidth
              size="small"
              variant="outlined"
              sx={{ paddingX: "0.5rem" }}
            />
          </Grid>
          <Grid
            item
            xs={6}
            display={
              isAdmin(userProfile.value) || staffDetails.uuid === userProfile.value.uuid
                ? ""
                : "none"
            }>
            <TextField
              error={isError.password}
              type={showPassword ? "text" : "password"}
              margin="dense"
              label={t("staffDetails.placeholder.password")}
              value={staffDetails.password || ""}
              onChange={(e) => updateFieldValue("password", e.target.value)}
              fullWidth
              size="small"
              variant="outlined"
              sx={{ paddingX: "0.5rem" }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}>
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <Chip
              icon={<WarningAmberIcon />}
              label={t("staffDetails.placeholder.password.warn")}
              sx={{
                marginLeft: "0.5rem",
                backgroundColor: "#f2d349",
                fontWeight: "bold",
                fontSize: "12px"
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              error={isError.mobile}
              margin="dense"
              label={t("staffDetails.placeholder.mobile")}
              value={staffDetails.mobile || ""}
              onChange={(e) => updateFieldValue("mobile", e.target.value)}
              fullWidth
              size="small"
              variant="outlined"
              sx={{ paddingX: "0.5rem" }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default StaffDetailsDialog;

import { Edit } from "@mui/icons-material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";
import { Button } from "../../../components/button";
import { DialogButtons } from "../../../components/dialog";
import Header from "../../../components/header";
import Status from "../../../components/status";
import Table from "../../../components/table";
import UrlPaths from "../../../constants/UrlPaths";
import { get, post, put } from "../../../services/HttpClient";
import { branchInfo } from "../../../signals";
import { tokens } from "../../../theme";
import PackageDetailsDialog from "./PackageDetailsDialog";

const Products = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();

  const [courses, setCourses] = useState([]);
  const [items, setItems] = useState([]);
  const [discounts, setDiscounts] = useState([]);
  const [openPackageDialog, setOpenPackageDialog] = useState(false);
  const [packageType, setPackageType] = useState("COURSE");
  const [packageDetails, setPackageDetails] = useState(null);

  const courseColumns = [
    { field: "id", label: "products.table.field.id.label" },
    {
      field: "packageName",
      label: "products.table.field.courseName.label"
    },
    {
      field: "quantity",
      label: "products.table.field.courseQuantity.label"
    },
    {
      field: "singlePrice",
      label: "products.table.field.singlePrice.label",
      sortable: false,
      component: ({ singlePrice }) => (
        <NumericFormat
          disabled
          value={singlePrice}
          allowLeadingZeros
          thousandSeparator=","
          suffix=" đ"
          style={{
            border: "none",
            width: "100px"
          }}
        />
      )
    },
    {
      field: "totalPrice",
      label: "products.table.field.totalPrice.label",
      sortable: false,
      component: ({ totalPrice }) => (
        <NumericFormat
          disabled
          value={totalPrice}
          allowLeadingZeros
          thousandSeparator=","
          suffix=" đ"
          style={{
            border: "none",
            width: "100px"
          }}
        />
      )
    },
    {
      field: "status",
      label: "products.table.field.status.label",
      align: "center",
      component: (rowData) => <Status prefix="package.status" status={rowData.status} />
    }
  ];
  const itemColumns = [
    { field: "id", label: "products.table.field.id.label" },
    {
      field: "packageName",
      label: "products.table.field.itemName.label"
    },
    {
      field: "singlePrice",
      label: "products.table.field.itemPrice.label",
      component: ({ singlePrice }) => (
        <NumericFormat
          disabled
          value={singlePrice}
          allowLeadingZeros
          thousandSeparator=","
          suffix=" đ"
          style={{
            border: "none",
            width: "100px"
          }}
        />
      )
    },
    {
      field: "status",
      label: "products.table.field.status.label",
      align: "center",
      component: (rowData) => <Status prefix="package.status" status={rowData.status} />
    }
  ];
  const discountColumns = [
    { field: "id", label: "products.table.field.id.label" },
    {
      field: "packageName",
      label: "products.table.field.discountName.label"
    },
    {
      field: "singlePrice",
      label: "products.table.field.discountValue.label",
      component: ({ singlePrice, packageType }) => (
        <NumericFormat
          disabled
          value={singlePrice}
          allowLeadingZeros
          thousandSeparator=","
          suffix={packageType === "DISCOUNT_AMOUNT" ? " đ" : " %"}
          style={{
            border: "none",
            width: "100px"
          }}
        />
      )
    },
    {
      field: "status",
      label: "products.table.field.status.label",
      align: "center",
      component: (rowData) => <Status prefix="package.status" status={rowData.status} />
    }
  ];
  const rowActions = [
    {
      icon: <Edit />,
      tooltip: t("products.editPackage.label"),
      action: (rowData) => {
        setOpenPackageDialog(true);
        setPackageType(rowData.packageType);
        setPackageDetails(rowData);
      }
    }
  ];

  useEffect(() => {
    refreshData();
  }, [branchInfo.value]);

  const refreshData = () => {
    if (branchInfo.value.uuid) {
      get(`${UrlPaths.ADMIN}/packages?branchUuid=${branchInfo.value.uuid}`)
        .then((res) => {
          const courses = res
            .filter(({ packageType }) => packageType === "COURSE")
            .map((x, index) => ({ id: index + 1, ...x }));
          const items = res
            .filter(({ packageType }) => packageType === "ITEM")
            .map((x, index) => ({ id: index + 1, ...x }));
          const discounts = res
            .filter(({ packageType }) => packageType.includes("DISCOUNT"))
            .map((x, index) => ({
              id: index + 1,
              ...x,
              singlePrice:
                x.packageType === "DISCOUNT_PERCENTAGE" ? x.singlePrice * 100 : x.singlePrice,
              totalPrice:
                x.packageType === "DISCOUNT_PERCENTAGE" ? x.totalPrice * 100 : x.totalPrice
            }));
          setCourses(courses);
          setItems(items);
          setDiscounts(discounts);
        })
        .catch(console.debug);
    }
  };

  const handleClose = () => {
    setOpenPackageDialog(false);
    setPackageType(null);
  };

  return (
    <Box>
      {/* SEARCH & ACTIONS BAR */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={t("header.title.label")} />
      </Box>
      <Box
        backgroundColor={colors.primary[400]}
        display="flex"
        mb="0.5rem"
        p="0.5rem"
        justifyContent="space-between">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h3" fontWeight="bold" marginY="1rem">
              {t("products.courses.label")}
            </Typography>
            <Button
              startIcon={<AddCircleOutlineIcon />}
              variant="contained"
              color="success"
              sx={{
                marginBottom: "0.5rem"
              }}
              onClick={() => {
                setPackageType("COURSE");
                setOpenPackageDialog(true);
              }}>
              {t("products.button.addNewCourse")}
            </Button>
            <Table
              columnConfig={courseColumns}
              data={courses}
              showHeaderToolbar={false}
              rowActions={rowActions}
            />
          </Grid>
        </Grid>
      </Box>
      <Box
        backgroundColor={colors.primary[400]}
        display="flex"
        mb="0.5rem"
        p="0.5rem"
        justifyContent="space-between">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="h3" fontWeight="bold" marginY="1rem">
              {t("products.items.label")}
            </Typography>
            <Button
              startIcon={<AddCircleOutlineIcon />}
              variant="contained"
              color="success"
              sx={{
                marginBottom: "0.5rem"
              }}
              onClick={() => {
                setPackageType("ITEM");
                setOpenPackageDialog(true);
              }}>
              {t("products.button.addNewItem")}
            </Button>
            <Table
              columnConfig={itemColumns}
              data={items}
              showHeaderToolbar={false}
              rowActions={rowActions}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h3" fontWeight="bold" marginY="1rem">
              {t("products.discounts.label")}
            </Typography>
            <Button
              startIcon={<AddCircleOutlineIcon />}
              variant="contained"
              color="success"
              sx={{
                marginBottom: "0.5rem"
              }}
              onClick={() => {
                setPackageType("DISCOUNT_PERCENTAGE");
                setOpenPackageDialog(true);
              }}>
              {t("products.button.addNewDiscount")}
            </Button>
            <Table
              columnConfig={discountColumns}
              data={discounts}
              showHeaderToolbar={false}
              rowActions={rowActions}
            />
          </Grid>
        </Grid>
      </Box>
      <PackageDetailsDialog
        open={openPackageDialog}
        packageType={packageType}
        packageDetails={packageDetails}
        onClose={handleClose}
        onConfirm={(res) => {
          const totalPrice = res?.totalPrice?.toFixed(2) || 0;
          const singlePrice = res?.singlePrice?.toFixed(2) || 0;
          const requestBody = {
            ...res,
            branchUuid: branchInfo.value.uuid,
            packageType: res?.packageType || packageType,
            quantity: packageType === "COURSE" ? res.quantity : 1,
            totalPrice: packageType === "COURSE" ? totalPrice : singlePrice,
            singlePrice: singlePrice
          };
          if (!packageDetails?.uuid) {
            post(`${UrlPaths.ADMIN}/packages`, requestBody)
              .then(() => {
                refreshData();
                handleClose();
              })
              .catch(console.debug);
          } else {
            put(`${UrlPaths.ADMIN}/packages/${packageDetails?.uuid}`, requestBody)
              .then(() => {
                refreshData();
                handleClose();
              })
              .catch(console.debug);
          }
        }}
        buttons={DialogButtons.CANCEL_CONFIRM}
        title={t(`products.dialog.${packageType}.${packageDetails ? "update" : "new"}.title`, {
          packageName: packageDetails?.packageName
        })}
      />
    </Box>
  );
};

export default Products;

import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  IconButton,
  TextField,
  Typography,
  useTheme
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import Table from "../../../components/table";
import UrlPaths from "../../../constants/UrlPaths";
import { get, post } from "../../../services/HttpClient";
import { branchInfo } from "../../../signals";
import { tokens } from "../../../theme";
import { toVNDCurrency } from "../../../utils/CurencyUtil";

const PaymentDebtDetails = ({ defaultExpanded }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [paymentDebts, setPaymentDebts] = useState([]);
  const [notes, setNotes] = useState([]);
  const [total, setTotal] = useState(0);
  const [pagination, setPagination] = useState({
    pageNumber: 0,
    pageSize: 30
  });

  const paymentDebtColumns = [
    { field: "id", label: "paymentDebts.table.field.id.label", sortable: false },
    {
      field: "studentName",
      label: "paymentDebts.table.field.studentName.label",
      valueGetter: ({ studentName, studentUuid }) => (
        <Link to={`../../academic/students/details/${studentUuid}`}>
          <Typography sx={{ fontWeight: "bold", color: colors.greenAccent[400] }}>
            {studentName}
          </Typography>
        </Link>
      )
    },
    {
      field: "className",
      label: "paymentDebts.table.field.className.label",
      valueGetter: ({ className }) => className || t("paymentDebts.noneClass.label")
    },
    {
      field: "invoiceId",
      width: "150px",
      label: "paymentDebts.table.field.invoiceId.label"
    },
    {
      field: "paidAndTotal",
      width: "150px",
      label: "paymentDebts.table.field.paidAndTotal.label",
      valueGetter: ({ totalAmount, paidAmount, discountAmount }) =>
        `${toVNDCurrency(paidAmount)}/${toVNDCurrency(totalAmount - discountAmount)}`
    },
    {
      field: "debtAmount",
      width: "150px",
      label: "paymentDebts.table.field.debtAmount.label",
      valueGetter: ({ totalAmount, paidAmount, discountAmount }) =>
        toVNDCurrency(totalAmount - (paidAmount + discountAmount))
    },
    {
      field: "debNote",
      width: "300px",
      label: "paymentDebts.table.field.note.label",
      sortable: false,
      component: ({ notes, id, noteChange, ...rowData }) => (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <TextField
            id="outlined-basic"
            value={notes[id - 1]}
            onChange={(e) => {
              noteChange(e, id);
            }}
            multiline
            rows={3}
            variant="outlined"
            sx={{ width: "100%" }}
            size="small"
          />
          <IconButton
            variant="contained"
            color="success"
            onClick={() => {
              const { uuid, studentUuid, classUuid, debtNoteUuid } = rowData;
              post(`${UrlPaths.STUDENTS}/debt`, {
                uuid: debtNoteUuid,
                branchUuid: branchInfo.value.uuid,
                studentUuid: studentUuid,
                classUuid: classUuid,
                invoiceUuid: uuid,
                status: "ACTIVE",
                debtType: "PAYMENT_IN_DEBT",
                note: notes[id - 1]
              })
                .then(() => {
                  refreshData();
                })
                .catch(console.debug);
            }}>
            <SaveAsIcon />
          </IconButton>
        </Box>
      )
    }
  ];
  const rowActions = [
    {
      icon: <CurrencyExchangeIcon />,
      tooltip: t("paymentDebts.table.action.pay.label"),
      action: (rowData) => {
        navigate(`../payment-contract/${rowData.uuid}`);
      }
    }
  ];

  useEffect(() => {
    if (branchInfo.value.uuid) {
      refreshData();
    }
  }, [pagination]);

  useEffect(() => {
    setPagination({
      pageNumber: 0,
      pageSize: 30
    });
  }, [branchInfo.value]);

  const refreshData = () => {
    get(`${UrlPaths.ADMIN}/invoices`, {
      branchUuid: branchInfo.value.uuid,
      ...pagination,
      invoiceStatus: "IN_PROGRESS"
    })
      .then((res) => {
        const { pageSize, pageNumber } = pagination;
        setTotal(res.total);
        setPaymentDebts(
          res.results.map((x, index) => ({ id: pageNumber * pageSize + index + 1, ...x }))
        );
        setNotes(res.results.map(({ debtNote }) => debtNote || ""));
      })
      .catch(console.debug);
  };

  return (
    <Accordion defaultExpanded={defaultExpanded}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          backgroundColor: colors.greenAccent[600]
        }}>
        <Typography variant="h4" fontWeight="bolder">
          {t("feeReport.paymentContractDebts.title")}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box
          backgroundColor={colors.primary[400]}
          mb="0.5rem"
          p="0.5rem"
          justifyContent="space-between">
          <Grid container mb="1rem" columnSpacing={1}>
            <Grid item xs={6}>
              <Typography variant="h5">
                {t("feeReport.totalPaymentDebtAmount.title")}:&nbsp;
                <b>
                  {toVNDCurrency(
                    paymentDebts.reduce(
                      (totalDebt, { totalAmount, paidAmount, discountAmount }) => {
                        totalDebt += totalAmount - (paidAmount + discountAmount);
                        return totalDebt;
                      },
                      0
                    )
                  )}
                </b>
              </Typography>
            </Grid>
            <Grid item xs={6}></Grid>
          </Grid>
          <Grid container direction="row-reverse" columnSpacing={1}></Grid>
        </Box>
        <Table
          columnConfig={paymentDebtColumns}
          showHeaderToolbar={false}
          data={paymentDebts.map((x) => ({
            ...x,
            notes,
            noteChange: (e, id) => {
              const noteList = [...notes];
              noteList.splice(id - 1, 1, e.target.value);
              setNotes(noteList);
            }
          }))}
          rowActions={rowActions}
          totalResults={total}
          serverPaging={true}
          defaultPagination={pagination}
          paginationChanged={(paging) => setPagination(paging)}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default PaymentDebtDetails;

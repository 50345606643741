import { Box, colors, CssBaseline, ThemeProvider, Typography } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ProSidebarProvider } from "react-pro-sidebar";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import "./App.css";
import Bar from "./components/bar";
import Dialog from "./components/dialog";
import Line from "./components/line";
import Pie from "./components/pie";
import Loading from "./components/progress/Loading";
import Sidebar from "./components/sidebar";
import CookieConstants from "./constants/Cookies";
import { currentRoute, globalError, userProfile } from "./signals";
import { ColorModeContext, useMode } from "./theme";
import { removeCookie } from "./utils/CookieUtil";
import { fetchCurrentInfo } from "./utils/UserUtil";

/** Dashboard */
import Dashboard from "./views/dashboard";

/** Login page */
import Login from "./views/login";

/** Academic pages */
import Attendance from "./views/academic/attendance";
import AttendanceHistories from "./views/academic/attendance-histories";
import Classes from "./views/academic/classes";
import ClassDetails from "./views/academic/classes/ClassDetails";
import DisabledStudents from "./views/academic/disabled-students";
import Holidays from "./views/academic/holidays";
import LOAStudents from "./views/academic/loa-students";
import MakeupClass from "./views/academic/makeup-class";
import StudentList from "./views/academic/student-list";
import StudentDetails from "./views/academic/student-list/StudentDetails";
import Timetable from "./views/academic/timetable";

/** Payment pages */
import FeeReport from "./views/payments/fee-report";
import Invoices from "./views/payments/invoices";
import PaymentContract from "./views/payments/payment-contract";

/** Setting pages */
import Classrooms from "./views/settings/classrooms";
import Products from "./views/settings/products";
import Staffs from "./views/settings/staffs";
import Teachers from "./views/settings/teachers";

/** Report pages */
import ClassReports from "./views/reports/class-reports";
import ClassReportDetails from "./views/reports/class-reports/ClassReportDetails";
import FinanceReports from "./views/reports/finance-reports";
import ContractHistories from "./views/payments/contract-histories";

const App = () => {
  const [theme, colorMode] = useMode();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    currentRoute.value = location.pathname;
    fetchCurrentInfo(
      () => {
        navigate(currentRoute.value.includes("/login") ? "/" : currentRoute.value);
      },
      (err) => {
        const { response } = err;
        if (!response || response.status === 401) {
          removeCookie(CookieConstants.ACCESS_TOKEN);
          userProfile.value = {};
          navigate("/login");
        }
      }
    );
  }, []);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <ProSidebarProvider>
            <Sidebar />
          </ProSidebarProvider>
          <main className="content">
            {/* Temporary disable topbar
            <Topbar /> */}
            <Box m="0.5rem">
              <Loading />
              <div>
                <Routes>
                  <Route path="/login" element={<Login />} />
                  <Route path="/" element={<Dashboard />} />
                  <Route path="/academic">
                    <Route path="classes">
                      <Route path="" element={<Classes />} />
                      <Route path="details/:classId" element={<ClassDetails />} />
                    </Route>
                    <Route path="timetable" element={<Timetable />} />
                    <Route path="holidays" element={<Holidays />} />
                    <Route path="attendance" element={<Attendance />} />
                    <Route path="attendance-histories" element={<AttendanceHistories />} />
                    <Route path="students">
                      <Route path="" element={<StudentList />} />
                      <Route path="details/:studentId" element={<StudentDetails />} />
                    </Route>
                    <Route path="makeup-class" element={<MakeupClass />} />
                    <Route path="loa-students" element={<LOAStudents />} />
                    <Route path="disabled-students" element={<DisabledStudents />} />
                  </Route>
                  <Route path="/finance">
                    <Route path="payment-contract">
                      <Route path="new" element={<PaymentContract />} />
                      <Route path=":invoiceUuid" element={<PaymentContract />} />
                    </Route>
                    <Route path="invoices" element={<Invoices />} />
                    <Route path="fee-report" element={<FeeReport />} />
                    <Route path="contract-histories" element={<ContractHistories />} />
                  </Route>
                  <Route path="/reports">
                    <Route path="class-reports">
                      <Route path="" element={<ClassReports />} />
                      <Route path="details/:classId" element={<ClassReportDetails />} />
                    </Route>
                    <Route path="financial-reports">
                      <Route path="" element={<FinanceReports />} />
                    </Route>
                  </Route>
                  <Route path="/settings">
                    <Route path="staffs" element={<Staffs />} />
                    <Route path="products" element={<Products />} />
                    <Route path="teachers" element={<Teachers />} />
                    <Route path="classrooms" element={<Classrooms />} />
                  </Route>
                  <Route path="/bar" element={<Bar />} />
                  <Route path="/pie" element={<Pie />} />
                  <Route path="/line" element={<Line />} />
                </Routes>
              </div>
            </Box>
          </main>
          <Dialog
            open={!!globalError.value}
            onClose={() => (globalError.value = undefined)}
            onConfirm={() => (globalError.value = undefined)}
            buttons={[
              {
                type: "confirm",
                id: "ok",
                label: "common.button.ok",
                color: "error"
              }
            ]}
            headerBgColor="#f92222"
            headerTitleColor="white"
            title={t("commonMsg.error.title")}
            textContent={t(globalError.value?.message)}>
            <Typography color={colors.grey[900]} sx={{ padding: "0.5rem" }}>
              {t(globalError.value?.detailsMsg || "")}
            </Typography>
          </Dialog>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default App;

import { Chip } from "@mui/material";
import { useTranslation } from "react-i18next";

const Status = ({ size = "small", prefix, postfix, status, type, color }) => {
  const { t } = useTranslation();

  const getStatusColor = (status, type) => {
    switch (type) {
      case "HISTORY":
        return getCheckInColor(status);
      case "STUDENT":
        return getStudentColor(status);
      case "STUDENT_SCHEDULE":
        return getStudentScheduleColor(status);
      case "HOLIDAY":
        return getHolidayColor(status);
      case "INVOICE":
        return getInvoiceColor(status);
      case "CLASS":
        return getClassColor(status);
      default:
        return getGeneralColor(status);
    }
  };

  const getCheckInColor = (status) => {
    switch (status) {
      case "ACTIVE":
        return "secondary";
      case "MAKE_UP_UNCOMPLETED":
        return "warning";
      case "CHECKED_IN":
        return "success";
      case "ABSENCE":
        return "error";
      case "MAKE_UP_COMPLETED":
        return "info";
      case "LEAVE_OF_ABSENCE":
      case "ABORTED":
      default:
        return "primary";
    }
  };

  const getStudentColor = (status) => {
    switch (status) {
      case "STD_NEW":
      case "STD_TRIAL_IN_DEBT":
        return "secondary";
      case "STD_ACTIVE":
        return "success";
      case "STD_TRIAL":
      case "STD_IN_DEBT":
        return "info";
      case "STD_LOA":
        return "warning";
      case "STD_INACTIVE":
      default:
        return "error";
    }
  };

  const getStudentScheduleColor = (status) => {
    switch (status) {
      case "STUDENT_LEARNING":
        return "success";
      case "STUDENT_LEAVE_OF_ABSENCE":
        return "info";
      case "STUDENT_IN_DEBT":
        return "error";
      case "STUDENT_TRIAL":
        return "secondary";
      default:
        return "warning";
    }
  };

  const getHolidayColor = (status) => {
    switch (status) {
      case "ACTIVE":
        return "success";
      case "OUTDATED":
        return "error";
      default:
        return "warning";
    }
  };

  const getInvoiceColor = (status) => {
    switch (status) {
      case "COMPLETED":
        return "success";
      case "IN_PROGRESS":
        return "warning";
      case "ACTIVE":
      default:
        return "info";
    }
  };

  const getClassColor = (status) => {
    switch (status) {
      case "ACTIVE":
        return "success";
      case "ON_HOLD":
        return "warning";
      default:
        return "primary";
    }
  };

  const getGeneralColor = (status) => {
    switch (status) {
      case "ACTIVE":
        return "success";
      default:
        return "warning";
    }
  };

  return (
    <Chip
      size={size}
      label={t(`${prefix ? prefix + "." : ""}${status}${postfix ? "." + postfix : ""}`)}
      color={color || getStatusColor(status, type)}
      sx={{ fontWeight: "bold" }}
    />
  );
};

export default Status;

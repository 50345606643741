import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Autocomplete from "../../../components/autocomplete";
import Dialog from "../../../components/dialog";
import UrlPaths from "../../../constants/UrlPaths";
import { get } from "../../../services/HttpClient";
import { DEFAULT_DATE_FORMAT } from "../../../utils/TimeUtil";

const INITIAL_DATA = {
  applyHoliday: "",
  holidayFrom: "",
  holidayTo: "",
  status: "ACTIVE"
};

const UpdateHolidayDialog = ({ data, editable, onConfirm, ...props }) => {
  const { t } = useTranslation();

  const [updateHoliday, setUpdateHoliday] = useState(INITIAL_DATA);
  const [excludedClasses, setExcludedClasses] = useState([]);

  useEffect(() => {
    if (data?.excludedClassUuids?.length > 0) {
      get(UrlPaths.CLASSES)
        .then((res) =>
          setExcludedClasses(
            res.results
              .map(({ classDetails }) => classDetails)
              .filter((x) => data.excludedClassUuids.includes(x.uuid))
          )
        )
        .catch(console.debug);
    }
    setUpdateHoliday(
      {
        applyHoliday: "",
        holidayFrom: moment(new Date(data?.startDate)),
        holidayTo: moment(new Date(data?.endDate)),
        status: data?.status || "ACTIVE"
      } || INITIAL_DATA
    );
  }, [data]);

  const updateFieldValue = (fieldName, fieldValue) => {
    setUpdateHoliday({ ...updateHoliday, [fieldName]: fieldValue });
  };

  const confirmSaveClass = () => {
    onConfirm({ ...updateHoliday, excludedClasses });
  };

  return (
    <Dialog {...props} onConfirm={confirmSaveClass}>
      <Grid
        container
        flexDirection="column"
        justifyContent="center"
        sx={{
          width: "100%"
        }}
        rowSpacing={4}>
        <Grid item>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              id="start-date"
              format={DEFAULT_DATE_FORMAT}
              label={t("updateHoliday.placeholder.holidayFrom")}
              value={updateHoliday.holidayFrom}
              onChange={(e) => updateFieldValue("holidayFrom", e)}
              sx={{ width: "100%", paddingX: "0.5rem" }}
              slotProps={{
                textField: { size: "small" },
                popper: {
                  style: {
                    zIndex: 15003
                  }
                }
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              id="start-date"
              format={DEFAULT_DATE_FORMAT}
              label={t("updateHoliday.placeholder.holidayTo")}
              value={updateHoliday.holidayTo}
              onChange={(e) => updateFieldValue("holidayTo", e)}
              sx={{ width: "100%", paddingX: "0.5rem" }}
              slotProps={{
                textField: { size: "small" },
                popper: {
                  style: {
                    zIndex: 15003
                  }
                }
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item>
          <FormControl
            sx={{
              paddingX: "0.5rem",
              display: "flex",
              flexDirection: "row",
              alignItems: "center"
            }}>
            <FormLabel id="status-label" sx={{ paddingRight: "0.5rem" }}>
              {t("addHoliday.placeholder.status")}
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="status-label"
              value={updateHoliday.status}
              name="row-radio-buttons-group"
              onChange={(e) => updateFieldValue("status", e.target.value)}>
              <FormControlLabel
                value="ACTIVE"
                control={<Radio />}
                label={t("addHoliday.status.ACTIVE.label")}
              />
              <FormControlLabel
                value="INACTIVE"
                control={<Radio />}
                label={t("addHoliday.status.INACTIVE.label")}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl sx={{ width: "100%", padding: "0.5rem" }} size="small">
            <Autocomplete
              multiple
              value={excludedClasses || []}
              placeholder="addHoliday.placeholder.excludedClasses"
              onChange={(e, value) => {
                const classes = value?.length > 0 ? value.map((x) => x) : [];
                setExcludedClasses(classes);
              }}
              requestConfig={{
                url: UrlPaths.CLASSES,
                label: "className",
                value: "uuid",
                responseField: (res) => res.results.map(({ classDetails }) => classDetails)
              }}></Autocomplete>
          </FormControl>
        </Grid>
        <Typography variant="h4" fontWeight="bold" marginTop="1rem">
          {t("updateHoliday.placeholder.note")}
        </Typography>
        <Typography component="div">
          <div
            dangerouslySetInnerHTML={{ __html: t("updateHoliday.placeholder.note.description") }}
          />
        </Typography>
      </Grid>
    </Dialog>
  );
};

export default UpdateHolidayDialog;

import { FormControl, Grid, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Autocomplete from "../../../components/autocomplete";
import Dialog from "../../../components/dialog";
import UrlPaths from "../../../constants/UrlPaths";
import { branchInfo } from "../../../signals";
import { DEFAULT_DATE_FORMAT } from "../../../utils/TimeUtil";

const INITIAL_DATA = {
  branchName: "",
  studentName: "",
  className: "",
  classTransferDate: moment(),
  transferSessions: "",
  transferDate: moment(),
  note: "",
  classValue: {
    className: "",
    uuid: ""
  }
};

const TransferClassDialog = ({ data, editable, onConfirm, ...props }) => {
  const { t } = useTranslation();

  const [transferClass, setTransferClass] = useState(INITIAL_DATA);

  useEffect(() => {
    if (data) {
      const { registeredQuantity, completedQuantity, transferQuantity } = data;
      setTransferClass({
        ...transferClass,
        ...data,
        transferSessions:
          (registeredQuantity || 0) - ((completedQuantity || 0) + (transferQuantity || 0))
      });
    }
  }, [data]);

  const updateFieldValue = (fieldName, fieldValue) => {
    setTransferClass({ ...transferClass, [fieldName]: fieldValue });
  };

  const confirmSaveClass = () => {
    onConfirm(transferClass);
  };

  return (
    <Dialog {...props} onConfirm={confirmSaveClass}>
      <Grid container spacing={2} columns={16}>
        <Grid item xs={8}>
          <Grid
            container
            flexDirection="column"
            justifyContent="center"
            sx={{
              width: "100%"
            }}
            rowSpacing={4}>
            <Grid item>
              <TextField
                margin="dense"
                id="name"
                disabled
                label={t("transferClass.placeholder.studentName")}
                value={transferClass.studentName}
                onChange={(e) => updateFieldValue("studentName", e.target.value)}
                fullWidth
                size="small"
                variant="outlined"
                sx={{ paddingX: "0.5rem", margin: 0 }}
              />
            </Grid>
            <Grid item>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  id="class-transfer-date"
                  format={DEFAULT_DATE_FORMAT}
                  value={transferClass.classTransferDate}
                  onChange={(e) => {
                    // TODO update max transferSessions
                    updateFieldValue("classTransferDate", e);
                  }}
                  label={t("transferClass.placeholder.classTransferDate")}
                  sx={{ width: "100%", paddingX: "0.5rem" }}
                  slotProps={{
                    textField: { size: "small" },
                    popper: {
                      style: {
                        zIndex: 15003
                      }
                    }
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item>
              <TextField
                margin="dense"
                id="name"
                type="number"
                label={t("transferClass.placeholder.transferSessions")}
                InputProps={{ inputProps: { min: 0, max: transferClass.transferSessions } }}
                value={transferClass.transferSessions}
                onChange={(e) => updateFieldValue("transferSessions", e.target.value)}
                fullWidth
                size="small"
                variant="outlined"
                sx={{ paddingX: "0.5rem", margin: 0 }}
              />
            </Grid>
            <Grid item>
              <TextField
                margin="dense"
                id="name"
                label={t("transferClass.placeholder.note")}
                value={transferClass.note}
                onChange={(e) => updateFieldValue("note", e.target.value)}
                fullWidth
                size="small"
                variant="outlined"
                sx={{ paddingX: "0.5rem", margin: 0 }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={8}>
          <Grid
            container
            flexDirection="column"
            justifyContent="center"
            sx={{
              width: "100%"
            }}
            rowSpacing={4}>
            <Grid item>
              <FormControl sx={{ width: "100%", paddingX: "0.5rem" }} size="small">
                <Autocomplete
                  onChange={(e, value) => {
                    setTransferClass({
                      ...transferClass,
                      branchUuid: branchInfo.value.uuid || "",
                      branchName: branchInfo.value.branchName || ""
                    });
                  }}
                  disabled
                  value={{
                    uuid: branchInfo.value.uuid,
                    branchName: branchInfo.value.branchName
                  }}
                  placeholder="classDetails.placeholder.branchName"
                  requestConfig={{
                    url: `${UrlPaths.ADMIN}/branches`,
                    label: "branchName",
                    value: "uuid"
                  }}></Autocomplete>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl sx={{ width: "100%", paddingX: "0.5rem" }} size="small">
                <Autocomplete
                  value={transferClass.classValue}
                  placeholder="transferClass.placeholder.className"
                  onChange={(e, value) => {
                    if (value) {
                      setTransferClass({
                        ...transferClass,
                        classUuid: value.uuid,
                        classValue: value
                      });
                    } else {
                      setTransferClass({
                        ...transferClass,
                        classUuid: "",
                        classValue: {
                          className: "",
                          uuid: ""
                        }
                      });
                    }
                  }}
                  requestConfig={{
                    url: `${UrlPaths.CLASSES}?branchUuid=${branchInfo.value.uuid}`,
                    label: "className",
                    value: "uuid",
                    responseField: (res) => res.results.map(({ classDetails }) => classDetails),
                    onQueryChange: (value) => (value ? `&any=${value}` : "")
                  }}></Autocomplete>
              </FormControl>
            </Grid>
            <Grid item>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  id="transfer-date"
                  format={DEFAULT_DATE_FORMAT}
                  label={t("transferClass.placeholder.transferDate")}
                  value={transferClass.transferDate}
                  minDate={transferClass.classTransferDate}
                  onChange={(e) => updateFieldValue("transferDate", e)}
                  sx={{ width: "100%", paddingX: "0.5rem" }}
                  slotProps={{
                    textField: { size: "small" },
                    popper: {
                      style: {
                        zIndex: 15003
                      }
                    }
                  }}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default TransferClassDialog;
